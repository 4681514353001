import $ from "./cashwrapper";
;(function ($, SURPLEX) {
    window.requestAnimationFrame = window.requestAnimationFrame
        || window.mozRequestAnimationFrame
        || window.webkitRequestAnimationFrame
        || window.msRequestAnimationFrame
        || function (f) {
            return setTimeout(f, 1000)
        };

    window.cancelAnimationFrame = window.cancelAnimationFrame
        || window.mozCancelAnimationFrame
        || function (requestID) {
            clearTimeout(requestID)
        };

    SURPLEX.Timer = $.extend({}, {
        perfAvail: (window.performance && typeof window.performance.now === 'function'),
        animationStartTime: document.getElementById('currentTime').getAttribute('data-current-time') * 1 - 500,
        requestingUpdate : false,
        requestingSync : false,
        settings: {
            currentTime: document.getElementById('currentTime').getAttribute('data-current-time') * 1 - 500,
            translations: {
                            in: document.getElementById('currentTime').getAttribute('data-translation-in'),
                            h: document.getElementById('currentTime').getAttribute('data-translation-h'),
                            m: document.getElementById('currentTime').getAttribute('data-translation-m'),
                            s: document.getElementById('currentTime').getAttribute('data-translation-s'),
                            double_point: document.getElementById('currentTime').getAttribute('data-translation-double-point'),
                        },
            timersSelector: '[data-surplex-closing]',
            intervalId: '',
            status: {success: 'success', warning: 'warning', danger: 'error'},
            bidstatus: {success: 'bidding-is-success', warning: 'bidding-is-neutral', danger: 'bidding-is-error'},
            progressBarStart: 120,
            urgency: { timeLeft: 7200, urgentClass: 'countdown-urgent'},
            urgencyClassnames : {success: 'last2Mins', warning: 'last1Min', danger: 'last30Seks', ended: 'ended'}
        },
        timerElems: [],
        startTime: Date.now(),
        initialize: function () {
            this.timerElems = [];
            let $countDowns = $(this.settings.timersSelector);
            if($countDowns.length > 0 && SURPLEX.Timer.perfAvail){
                $countDowns.each(function () {
                    $(this).data('closingTime', $(this).attr('data-surplex-closing') * 1);
                    if(!$(this).data('origDateStr')){
                        $(this).data('origDateStr',
                            ((typeof $(this).attr('data-orig-date-str') !== 'undefined' && $(this).attr('data-orig-date-str') !== '')
                                ? $(this).attr('data-orig-date-str')
                                : $(this).html()
                            ));
                    }
                    SURPLEX.Timer.addElem($(this));
                });
                if(SURPLEX.Timer.requestingUpdate === false){
                    SURPLEX.Timer.updateTimer(0);
                    window.requestAnimationFrame(SURPLEX.Timer.timerCallBack);
                }
                document.removeEventListener('visibilitychange', SURPLEX.Timer.checkTime, true);
                document.addEventListener('visibilitychange', SURPLEX.Timer.checkTime , true);
            }
        },

        getCurrentTime: function(){
            return  SURPLEX.Timer.getTimeOnSite() + SURPLEX.Timer.settings.currentTime;
        },

        getTimeOnSite: function(){
            return Date.now() - this.startTime;
        },

        // Shared timer for all countdowns
        timerCallBack: function () {
            let drawStart = SURPLEX.Timer.getCurrentTime();
            if (drawStart - SURPLEX.Timer.animationStartTime >= 1000) {
                SURPLEX.Timer.animationStartTime = drawStart;
                SURPLEX.Timer.updateTimer(drawStart - SURPLEX.Timer.animationStartTime);
            }
            if (SURPLEX.Timer.checkElems()) {
                SURPLEX.Timer.settings.intervalId = window.requestAnimationFrame(SURPLEX.Timer.timerCallBack);
            }
        },

        checkTime: function (){
            if (!document.hidden) {
                let minutes = SURPLEX.Timer.getTimeOnSite() / 60000;
                if (minutes > 5) {
                    SURPLEX.Timer.syncTime();
                }
            }
        },

        syncTime : function (){
            if(SURPLEX.Timer.requestingSync === false){
                SURPLEX.Timer.requestingSync = true;
                SURPLEX.Ajax.fetch(
                    {
                        url: '/',
                        data: {
                            'r': 'esi/server-time'
                        },
                        callbackOnSuccess : {},
                        callbackOnFail : {},
                        callbackEnd : {
                            'correctTimer': function (target, result) {
                                if(Number.isInteger(result)){
                                    document.getElementById('currentTime').setAttribute('data-current-time', '' + (result * 1000));
                                    SURPLEX.Timer.animationStartTime = SURPLEX.Timer.settings.currentTime = result * 1000;
                                    SURPLEX.Timer.startTime = Date.now();
                                }
                                SURPLEX.Timer.requestingSync = false;
                            }
                        }
                    }
                );
            }
        },

        //  Update each active countdown
        updateTimer: function (lastUpdateTime) {
            for (let i = this.timerElems.length - 1; i >= 0; i--) {
                let remainingTime = this.timerElems[i].data('closingTime') - lastUpdateTime;
                this.timerElems[i].data('closingTime', (remainingTime < 0 ? 0 : remainingTime));
                this.updateCountdown(this.timerElems[i]);
            }
        },

        // See if an element is in the list of active ones.
        hasElem: function (elem) {
            return $.inArray(elem, this.timerElems) !== -1;
        },

        // Remove an element from the list of active ones.
        removeElem: function (elem) {
            elem.removeAttr('data-surplex-closing');
            this.timerElems = $.map(this.timerElems, function (value) {
                return (value.is(elem) ? null : value);
            });
            this.removeProgressBar(elem);
        },

        // on after update elements, see if there are still some active
        checkElems: function () {
            if (this.timerElems.length === 0) {
                window.cancelAnimationFrame(this.settings.intervalId);
                document.removeEventListener('visibilitychange', SURPLEX.Timer.checkTime, true);
                return false;
            }
            return true;
        },

        // add an element to the list of active counters.
        addElem: function (elem) {
            if (!this.hasElem(elem)) {
                this.timerElems.push(elem);
                elem.attr('data-initialized', true);
            }
        },

        // update countdown element
        updateCountdown: function (elem) {
            if (elem.data('updating') === 1) {
                // stop updating if ajax still requesting for an update
                return;
            }
            elem.data('updating', 1);
            let remainingTime = this.getRemainingTime(elem);
            let isDetailView = SURPLEX.Timer.isDetailViewCounter(elem);
            let isTeaser = SURPLEX.Timer.isNextAuctionCounter(elem);
            let isAuction = SURPLEX.Timer.isAuctionCounter(elem);

            if (isTeaser) {
                let $days = elem.find("[data-role='days']");
                let $hours = elem.find("[data-role='hours']");
                let $minutes = elem.find("[data-role='minutes']");
                let $seconds = elem.find("[data-role='seconds']");

                if (remainingTime.timeInSec >= 0) {
                    if ($days.text() !== remainingTime.days) {
                        $days.text(remainingTime.days);
                    }
                    if ($hours.text() !== remainingTime.hours) {
                        $hours.text(remainingTime.hours);
                    }
                    if ($minutes.text() !== remainingTime.minutes) {
                        $minutes.text(remainingTime.minutes);
                    }
                    if ($seconds.text() !== remainingTime.seconds) {
                        $seconds.text(remainingTime.seconds);
                    }
                    elem.data('updating', 0);
                } else {
                    $days.text('00');
                    $hours.text('00');
                    $minutes.text('00');
                    $seconds.text('00');
                    SURPLEX.Timer.removeElem(elem);
                    let $updateElm = elem.closest('.js-next-auctions');
                    if ($updateElm.length > 0) {
                        SURPLEX.Ajax.fetch({
                            url: '/',
                            data: {'r': 'esi/home-teaser-banner-redesign', 'language': SURPLEX.App.pageLanguage},
                            targetObj: $updateElm
                        })
                    }
                }
            } else {
                if (remainingTime.timeInSec >= 0 && remainingTime.timeInSec < 86400) {
                    if (!isDetailView && !isAuction) {
                        let $articleListItem = elem.closest('.js-article-watch--item');
                        if (Math.floor(remainingTime.timeInSec) === 55 &&
                            $articleListItem.data('updating-less-one-minute') !== 1) {
                            $articleListItem.data('updating-less-one-minute', 1);
                            $articleListItem.trigger('refresh');
                            return;
                        } else if ($articleListItem.data('updating-less-one-minute') === 1) {
                            $articleListItem.removeAttr('data-updating-less-one-minute');
                        }
                    }

                    if (remainingTime.timeInSec < 4 && isDetailView) {
                        elem.html('<b>' + elem.data('states')[Math.floor(remainingTime.timeInSec)] + '</b>');
                    } else {
                        let $timeStr = '';
                        if (remainingTime.timeInSec < 60 * 60) {
                            $timeStr = parseInt(remainingTime.minutes) + this.settings.translations.m + ' ' + remainingTime.seconds + this.settings.translations.s;
                        } else {
                            $timeStr = parseInt(remainingTime.hours) + this.settings.translations.h + ' ' + parseInt(remainingTime.minutes) + this.settings.translations.m + ' ' + remainingTime.seconds + this.settings.translations.s;
                        }
                        if(isAuction){
                            elem.html('<b>' + $timeStr + '</b>');
                        } else {
                            elem.html('<span class="cardProduct__closingDateLabel me-1">' + this.settings.translations.in + this.settings.translations.double_point + '</span><b>' + $timeStr + '</b>');
                        }
                    }
                    if (remainingTime.timeInSec <= this.settings.progressBarStart) {
                        let valuenow = this.settings.progressBarStart - remainingTime.timeInSec;
                        let percent = Math.floor((valuenow / this.settings.progressBarStart) * 100);
                        let status = (percent < 50) ?
                            this.settings.status.success : (percent < 75) ?
                                this.settings.status.warning : this.settings.status.danger;
                        let urgency = (percent < 50) ?
                            this.settings.urgencyClassnames.success : (percent < 75) ?
                                this.settings.urgencyClassnames.warning : this.settings.urgencyClassnames.danger;
                        if (!elem.hasClass(urgency)) {
                            for (let [, urgencyColor] of Object.entries(this.settings.urgencyClassnames)) {
                                urgencyColor === urgency ? elem.addClass(urgencyColor) : elem.removeClass(urgencyColor);
                            }
                        }
                        this.updateProgressBar(elem, status, percent);
                    } else {
                        for (let [, urgencyColor] of Object.entries(SURPLEX.Timer.settings.urgencyClassnames)) {
                            if (elem.hasClass(urgencyColor)) {
                                elem.removeClass(urgencyColor)
                            }
                        }
                        this.removeProgressBar(elem);
                    }
                    if (isDetailView && elem.closest('#js-bbs').length === 0) {
                        SURPLEX.Timer.triggerBidBoxUpdate(elem, remainingTime);
                    }
                    elem.data('updating', 0);
                } else {
                    if (isAuction) {
                        SURPLEX.Timer.resetElemHard(elem);
                    } else if (isDetailView) {
                        if (elem.closest('#js-bbs').length === 0) {
                            elem.closest('.js-article--item').trigger('refresh');
                        }
                    } else {
                        elem.closest('.js-article-watch--item').trigger('refresh');
                    }
                }
            }
        },

        isDetailViewCounter: function (elem) {
            return typeof elem.data('states') !== 'undefined';
        },

        isNextAuctionCounter: function (elem) {
            return typeof elem.data('next-auction') !== 'undefined';
        },

        isAuctionCounter: function (elem) {
            return elem.closest('.js-aucCalendar--item').length > 0
        },

        triggerBidBoxUpdate : function(elem, remainingTime){
            if(SURPLEX.Timer.requestingUpdate === false){
                let secondsOnPage =  Math.floor(SURPLEX.Timer.getTimeOnSite()/1000);
                if(secondsOnPage > 0){
                    if(
                        (Math.floor(remainingTime.timeInSec) === 55) ||
                        (secondsOnPage % 15 === 0 && remainingTime.timeInSec < 120) ||
                        (secondsOnPage % 30 === 0 && remainingTime.timeInSec < 600) ||
                        (secondsOnPage % 60 === 0 && remainingTime.timeInSec < 1800) ||
                        (secondsOnPage % 90 === 0 && remainingTime.timeInSec < 3600)
                    ){
                        SURPLEX.Timer.requestingUpdate = true;
                        elem.closest('.js-article--item').trigger('refresh');
                    }
                }
            }
        },

        resetElem: function (elem){
            SURPLEX.Timer.removeElem(elem);
            elem.data('updating', 0);
            for (let [, urgencyColor] of Object.entries(SURPLEX.Timer.settings.urgencyClassnames)) {
                if(elem.hasClass(urgencyColor)) {elem.removeClass(urgencyColor)}
            }
            elem.addClass(SURPLEX.Timer.settings.urgencyClassnames.ended);
            elem.closest('.js-article--item').find('.js-list-bidBtn').remove();
            elem.closest('.js-auction-ics-download').addClass('d-none d-sm-flex');
            elem.closest('.calendarAdd--btn').addClass('disabled');
        },

        resetElemHard : function(elem){
            SURPLEX.Timer.resetElem(elem);
            elem.html(elem.data('origDateStr'));
        },

        // pad value with zero
        pad: function (value) {
            return ('0' + Math.floor(value)).slice(-2);
        },

        // show time repeatedly
        getRemainingTime: function (timer) {
            let timeInMS = timer.data('closingTime') - SURPLEX.Timer.animationStartTime;
            let seconds = this.pad((timeInMS / 1000) % 60);
            let minutes = this.pad((timeInMS / (60 * 1000)) % 60);
            let hours = this.pad((timeInMS / (60 * 60 * 1000)) % 24);
            let days = this.pad(timeInMS / (24 * 60 * 60 * 1000));
            return {
                timeInSec: timeInMS / 1000,
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            };
        },

        // remove progressbar
        removeProgressBar: function (elem) {
            let $articleListItem = elem.closest('.js-article-watch--item');
            if ($articleListItem.length < 1) {
                $articleListItem = elem.closest('.js-aucCalendar--item');
            }
            if($articleListItem.length > 0){
                let $progressBarPreviousElement = $articleListItem.find('.js_progress-bar-prev');
                if($progressBarPreviousElement.length > 0 ){
                    let $progressBar = $progressBarPreviousElement.next();
                    if ($progressBar.is('.card--progress')) {
                        $progressBar.remove();
                    }
                }
            }
        },

        // add progressbar after counter
        addProgressBar: function (elem, status, width) {
            $('<div />')
                .attr('class', 'card--progress ' + status)
                .append ( $('<div />')
                    .attr('class', 'card--progressBar')
                    .attr('style', 'width: ' + width + '%')
                ).insertAfter( elem )
        },

        // update progressbar with background and status
        updateProgressBar: function (elem, status, width) {
            let $articleListItem = elem.closest('.js-article-watch--item');
            if ($articleListItem.length < 1) {
                $articleListItem = elem.closest('.js-aucCalendar--item');
            }
            if($articleListItem.length > 0 && $articleListItem.find('.js_progress-bar-prev').length > 0){
                let $progressBarPreviousElement = $articleListItem.find('.js_progress-bar-prev');
                let $progressBar = $progressBarPreviousElement.next();
                if ($progressBar.length === 0 || !$progressBar.is('.card--progress')) {
                    this.addProgressBar($progressBarPreviousElement, status, width);
                    this.updateProgressBar(elem, status, width);
                }
                let $progressBarBg = $progressBar.children().first();
                $progressBarBg.css("width", width + "%");

                if (!$progressBar.hasClass(status)) {
                    for (let [, color] of Object.entries(this.settings.status)) {
                        if(color === status){
                            $progressBarPreviousElement.addClass(color)
                            $progressBar.addClass(color);
                        } else {
                            $progressBarPreviousElement.removeClass(color)
                            $progressBar.removeClass(color);
                        }
                    }
                }
            }
        }

    });
    // code if optimize test was successful
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.Timer);
    }
})($, SURPLEX, undefined);