import $ from "./cashwrapper";
;(function ($, SURPLEX) {
    SURPLEX.Track = $.extend({}, {

        initializeLoadEvents: function () {
            this.initTrackGeneric();
            this.initTrackLanguageSwitcher();
            this.initTrackToastNotifications();
            this.initTrackFaq();
            this.initTrackItemClicks();
            this.initTrackUserMenu();
        },

        initTrackGeneric: function () {
            $(document.body).on('click', '.js-track-generic', function (e) {
                let $clickedElem = $(this);
                if (typeof $clickedElem.data('track-category') !== "undefined") {
                    // Check condition and exit early if necessary
                    if (typeof $clickedElem.data('track-condition') !== "undefined") {
                        switch ($clickedElem.data('track-condition')) {
                            case "class_not_present":
                                if (typeof $clickedElem.data('track-condition-param') !== "undefined"
                                    && $clickedElem.hasClass($clickedElem.data('track-condition-param'))) {
                                    return;
                                }
                                break;
                            case "class_present":
                                if (typeof $clickedElem.data('track-condition-param') !== "undefined"
                                    && !$clickedElem.hasClass($clickedElem.data('track-condition-param'))) {
                                    return;
                                }
                                break;
                            default:
                                break;
                        }
                    }
                    if (typeof $clickedElem.data('track-use-pagetype') !== "undefined") {
                        SURPLEX.Track.trackGA(
                            $clickedElem.data('track-category'),
                            $('meta[name=srxGooglePageType]').attr('content'),
                            $clickedElem.data('track-label'),
                            $clickedElem.data('track-value')
                        );
                    } else {
                        SURPLEX.Track.trackGA(
                            $clickedElem.data('track-category'),
                            $clickedElem.data('track-action'),
                            $clickedElem.data('track-label'),
                            $clickedElem.data('track-value')
                        );
                    }
                }
                if (typeof $clickedElem.data('hj-event') !== "undefined") {
                    if (typeof hj === 'function') {
                        let userId = SURPLEX.Track.getDataFromLayer('dataLayer', 'userId');
                        if(typeof userId!=='number' || (userId%1)!==0) {
                            userId = null;
                        }
                        hj('identify', userId, {
                            [$clickedElem.data('hj-event')]: 'occurred'
                        });
                    }
                }
            });
        },

        initTrackLanguageSwitcher: function () {
            let language = SURPLEX.App.pageLanguage;

            SURPLEX.App.uniqueBindEvent('.js-switch-language', 'click', 'track', function (e) {
                SURPLEX.Track.trackGA(
                    'languageSelector',
                    $('meta[name=srxGooglePageType]').attr('content'),
                    'from_' + language.toUpperCase() + ' to_' + $(this).data('language').toUpperCase()
                )
            });
        },

        initTrackToastNotifications: function () {
            $(document.body).on('click', '#notifications .toast a', function () {
                SURPLEX.Track.trackGA(
                    $(this).closest('.toast').attr('data-track-category'),
                    'clicked',
                    'toast'
                );
            });
        },

        initTrackFaq: function () {
            SURPLEX.App.uniqueBindEvent('.accordion-button', 'click', 'track', function (e) {
                if($(this).hasClass('collapsed') === false && $(this).attr('aria-controls').toString().indexOf('faq__') > -1){
                    SURPLEX.Track.trackGA(
                        'customerCareTracking',
                        $('meta[name=srxGooglePageType]').attr('content') + '_' + SURPLEX.App.pageLanguage,
                        'selection_' + $(this).attr('aria-controls').toString()
                    )
                }
            });
        },

        initTrackUserMenu: function () {
            SURPLEX.App.uniqueBindEvent('#kontoMenu', 'click', 'track', function (e) {
                if($(this).hasClass('show')){
                    SURPLEX.Track.trackGA(
                        'headerMyaDropdownUsage',
                        $('meta[name=srxGooglePageType]').attr('content'),
                        'dropdown_opened'
                    )
                }
            });
        },

        initTrackItemClicks: function () {
            if ($('meta[name=srxGooglePageType]').attr('content') === 'category') {
                $(document.body).on('click', '.js-article-list .js-article-watch--item a, .js-article-list .js-article-watch--item *[data-redirect]', function (e) {
                    let $clickedElem = $(this);
                    if($clickedElem.parents('[data-redirect]').length < 1){
                        if (!('dataLayerArticleList' in window)) {
                            let articleList = SURPLEX.Utils.isJSON(SURPLEX.Track.getDataFromLayer('dataLayer', 'list'));
                            if (articleList) {
                                window['dataLayerArticleList'] = {'list': articleList};
                            }
                        }
                        if ('dataLayerArticleList' in window && typeof dataLayer !== "undefined") {
                            let index = $('.collection .js-article-watch--item').index($clickedElem.closest('.js-article-watch--item'));
                            dataLayer.push({
                                'event': 'productClick',
                                'article': [window['dataLayerArticleList'].list.articles[index]]
                            });
                        }
                        if ($clickedElem.is('a')) {
                            e.preventDefault();
                            window.setTimeout(function () {
                                window.location.href = $clickedElem.attr('href');
                            }, 50);
                        }
                }});
            }
        },

        /**
         * @param {string} src [wlist | article]
         * @param {object} data
         */
        trackBidDelta: function (src, data) {
            if (typeof data === 'object' && data.newBid < 500000 && data.oldBid < data.newBid && data.bidDelta > 0) {
                let event = "Gebot Delta";
                // data.returnStatus === 1 => user is highest bidder => no bid agent was responsible for the actual highest bid
                let type = (data.returnStatus === 1) ? 'normal' : 'bidagent';
                let action = src + ' - ' + type;
                let label = SURPLEX.Bid.usersLastBid + ' - ' + data.articleId;
                let value = data.bidDelta;
                SURPLEX.Track.trackGA(event, action, label, value);
            }
        },

        trackGTagBid: function (bidData) {
            if (typeof dataLayer !== "undefined") {
                dataLayer.push({
                    "event": "purchase",
                    "purchase": {
                        "transaction_id": bidData.bidId,
                        "affiliation": bidData.auctionId,
                        "value": bidData.bid,
                        "currency": "EUR",
                        "list_name": bidData.pageType,
                        "items": [
                        {
                            "id": bidData.articleId,
                            "auctionid": bidData.auctionId,
                            "name": bidData.articleName,
                            "brand": bidData.articleManufacturer,
                            "category": bidData.articleCategoryPath,
                            "price": bidData.bid,
                            "quantity": 1,
                            "list_position": 1,
                            "dimension4": bidData.language,
                            "dimension3": bidData.articleCountry
                        }
                    ]
                }});
            }
        },

        trackFormError: function (formtype, fieldname, errorname) {
            SURPLEX.Track.trackGA(
                'formTracking',
                formtype,
                'error_' + fieldname + '_' + errorname
            );
        },

        trackFormSuccess: function (formtype, numberOfErrors) {
            SURPLEX.Track.trackGA(
                'formTracking',
                formtype,
                'success_' + numberOfErrors
            );
        },

        trackGA: function (category, action, label, value) {
            if (typeof dataLayer !== "undefined") {
                dataLayer.push({
                    'event': 'analyticsEvent',
                    'eventCategory': category,
                    'eventAction': action,
                    'eventLabel': label,
                    'eventValue': value
                });
            }
        },

        trackVPV: function (action, pageUrl) {
            if (typeof dataLayer !== "undefined") {
                dataLayer.push({
                    'event': 'virtualPageview',
                    'eventAction': action,
                    'eventLabel': 'pageView',
                    'virtualPagePath': pageUrl
                });
            }
        },

        getDataFromLayer: function (layer, prop) {
            if (typeof window[layer] !== "undefined") {
                for (let i = 0; i < window[layer].length; i++) {
                    if (typeof window[layer][i] !== "undefined" && window[layer][i].hasOwnProperty(prop)) {
                        return window[layer][i][prop];
                    }
                }
            }
            return 'undefined';
        }
    });

    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.Track);
    }
})($, SURPLEX);