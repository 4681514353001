
import Modal from "bootstrap/js/dist/modal";
import $ from "./cashwrapper";
;(function ($, SURPLEX) {
    SURPLEX.Watch = $.extend({}, {
        refreshList: false,
        settings: {
            addBookmarkUrl: '/?r=Page/watch/add-bookmark',
            removeBookmarkUrl: '/?r=Page/watch/remove-bookmark'
        },
        watchListMachineIds: '',
        dropDownWatchList : $('#js-watchListData'),
        undoTimeoutId: null,
        initialize: function () {
            this.enableListeners();
            this.updateWatchlistBadge();
        },

        enableListeners: function () {
            SURPLEX.App.uniqueBindEvent('.js-article--watchtool', 'click', 'watch', function (e) {
                try {
                    const articleId = $(this).closest('.js-article-watch--item').data('article-id');
                    if (typeof articleId === 'undefined') {
                        throw "class kachel not found";
                    }
                    e.stopPropagation();
                    SURPLEX.Watch.toggleWatchlistItem(articleId, $(this));
                } catch (err) {
                    SURPLEX.App.logError(err);
                }
                return false;
            });
            let $wlDropDown = SURPLEX.Watch.dropDownWatchList;
            if ($wlDropDown.data('initialized') !== 'true') {
                $wlDropDown.get(0).addEventListener('show.bs.dropdown', function(){
                    SURPLEX.Track.trackGA(
                        'watchlistUsage',
                        $('meta[name=srxGooglePageType]').attr('content'),
                        'openSidebarStickyIcon'
                    )
                    clearTimeout(SURPLEX.Watch.undoTimeoutId);
                    SURPLEX.Watch.updateWatchList();
                });
                $wlDropDown.data('initialized', 'true');
            }

            SURPLEX.App.uniqueBindEvent('#js-watchListData .undo .undo-link', 'click', 'watch', function (e) {
                e.preventDefault();
                clearTimeout(SURPLEX.Watch.undoTimeoutId);
                let $currentElm = $('#watchListDropDown').find('.deleted');
                $currentElm.addClass('undo-deleted').removeClass('is-active').removeClass('deleted undo-deleted');
                $(this).closest('.undo').removeClass('visible');
                SURPLEX.Watch.toggleWatchlistItem($currentElm.data('article-id'), $currentElm);
            });

        },

        removeProduct: function (product) {
            if (SURPLEX.App.isGuest) {
                SURPLEX.Watch.clearUndoTimeOut();
                SURPLEX.Watch.toggleWatchlistItem(product.data('article-id'), product);
                SURPLEX.Watch.startUndoTimeOut(product.data('article-id'));
            } else {
                SURPLEX.Watch.toggleUserWatchlistItem(product.data('article-id'), true, 0);
                SURPLEX.Track.trackGA('Beobachten',
                    SURPLEX.Watch.getSidebarPageType() + '-remove'
                );
            }
        },

        getSidebarPageType : function(){
            return $('meta[name=srxGooglePageType]').attr('content') + '-sidebar-watchlist';
        },

        clearUndoTimeOut : function()
        {
            if ($('#watchListDropDown').length) {
                if (SURPLEX.Watch.undoTimeoutId !== null) {
                    clearTimeout(SURPLEX.Watch.undoTimeoutId);
                }
                let $deletedArticles = $('.js-watchlist .deleted');
                if ($deletedArticles.length > 0) {
                    $deletedArticles.remove();
                }
            }
        },

        startUndoTimeOut : function(articleId)
        {
            let $product = $('.js-watchlist').find('[data-article-id="' + articleId + '"]');
            if($product.length > 0){
                let sideBar = $product.closest('#watchListDropDown').length;
                if(sideBar){
                    $('.js-watchlist .undo').addClass('visible');
                } else{
                    let $undo = $product.find('.undo');
                    if($undo.length === 0){
                        $undo = $('<div>').attr('class', 'undo');
                        let $undoClick = $('<span>')
                            .attr('class', 'undo-click')
                            .html($product.closest('.js-watchlist').attr('data-undo-hint'))
                            .click(function () {
                                clearTimeout(SURPLEX.Watch.undoTimeoutId);
                                $product.addClass('undo-deleted').removeClass('is-active deleted undo-deleted');
                                $undo.remove();
                                SURPLEX.Watch.toggleWatchlistItem(articleId, $product);
                            });
                        let $undoHint = $('<span>').attr('class', 'undo-hint')
                            .html($product.closest('.js-watchlist').attr('data-deleted-hint'));
                        let $icon = $('<span>').attr('class', 'icon__history');
                        $product.prepend($undo.append($undoHint, $undoClick.prepend($icon)));
                    }
                    $undo.addClass('visible');
                }
                $product.addClass('deleted').addClass('is-active');
                SURPLEX.Watch.undoTimeoutId = setTimeout(function () {
                    if(sideBar){
                        removeFromDropDown();
                    } else {
                        removeFromWatchlistPage($product);
                    }
                }, 8000);
            }

            function removeFromDropDown() {
                $('.js-watchlist .undo').removeClass('visible');
                $('.js-watchlist .deleted').remove();
                let $ddWatchList = SURPLEX.Watch.dropDownWatchList;
                const $badgeElement = $ddWatchList.find('.bookmark__statusBadge').first();
                let value = $badgeElement.text().length > 0 ? parseInt($badgeElement.text(), 10) : 0;
                if (value < 1 && $ddWatchList.find('.dropdown-menu').hasClass('show')) {
                    $badgeElement.closest('.icon__bookmark-fill')
                        .removeClass('icon__bookmark-fill').addClass('icon__bookmark');
                    SURPLEX.Watch.updateWatchList();
                }
            }

            function removeFromWatchlistPage($product){
                $product.find('.undo').removeClass('visible');
                $product.addClass("fadeout");
                setTimeout(function () {
                    $product.remove();
                }, 1500);

                let $counter = $('.js-counter--digit');
                if($counter.length){
                    let newCount = parseInt($counter.text()) - 1;
                    if (newCount <= 0) {
                        window.location.reload();
                    } else {
                        $counter.text(newCount.toString());
                    }
                }
            }
        },

        getBookmarkedItems: function () {
            if (SURPLEX.App.isGuest) {
                return SURPLEX.Utils.isJSON(SURPLEX.Utils.getCookie('surplex_notloggedin_watchlist')) || [];
            }
            return [];
        },

        toggleWatchlistItem: function (articleId, $articleElement) {
            try {
                let isActive = $articleElement.hasClass('is-active');
                let sidebar = $articleElement.closest('#watchListDropDown').length > 0;
                if (SURPLEX.App.isGuest) {
                    this.toggleGuestWatchlistItem(articleId);
                    this.updateWatchlistBadge();
                } else {
                    this.toggleUserWatchlistItem(articleId, isActive, 0);
                }

                let googlePageType = $('meta[name=srxGooglePageType]').attr('content');
                let recomSlider = $articleElement.closest('.js-recommended-for-you').length > 0;
                let trackingActioin = sidebar ? SURPLEX.Watch.getSidebarPageType() : googlePageType + (recomSlider ? '-recom' : '');
                SURPLEX.Track.trackGA('Beobachten', trackingActioin + (isActive ? '-remove' : '-add'));

                if (typeof hj === 'function') {
                    let userId = SURPLEX.Track.getDataFromLayer('dataLayer', 'userId');
                    if(typeof userId!=='number' || (userId%1)!==0) {
                        userId = null;
                    }
                    hj('identify', userId, {
                        ['watchlist-'  + (isActive ? 'remove' : 'add')]: 'occurred'
                    });
                }

                if (googlePageType === 'category' && $articleElement.closest('.collection').length > 0) {
                    if (!('dataLayerArticleList' in window)){
                        let articleList = SURPLEX.Utils.isJSON(SURPLEX.Track.getDataFromLayer('dataLayer', 'list'));
                        if(articleList){
                            window['dataLayerArticleList'] =  {'list' : articleList };
                        }
                    }
                    if ('dataLayerArticleList' in window && typeof dataLayer !== "undefined") {
                        let index = $('.collection .js-article-watch--item').index($articleElement.closest('.js-article-watch--item'));
                        dataLayer.push({
                            'event': isActive ? 'removeWatchlist' : 'addWatchlist',
                            'article': [window['dataLayerArticleList'].list.articles[index]]});
                    }
                } else if($.inArray( googlePageType, ['articleTreatySale', 'articleAuction']) !== -1){
                    dataLayer.push({
                        'event': isActive ? 'removeWatchlist' : 'addWatchlist',
                        'article': [SURPLEX.Track.getDataFromLayer('dataLayer', 'article')]});
                }
            } catch (err) {
                SURPLEX.App.logError(err);
            }
        },

        toggleUserWatchlistItem: function (articleId, isActive, force) {
            let url = isActive ? this.settings.removeBookmarkUrl : this.settings.addBookmarkUrl;
            $.Deferred().resolve(this.requestBookmarkUrl(url, articleId, force)
                .then(function (data) {
                    if (data.hasOwnProperty('success') && data.success === true) {
                        if (isActive) {
                            SURPLEX.Watch.removeBookmarkedFlag(articleId);
                            SURPLEX.Watch.clearUndoTimeOut();
                            SURPLEX.Watch.startUndoTimeOut(articleId);
                            SURPLEX.Watch.decrementBookmarkBadgeCount();
                        } else {
                            SURPLEX.Watch.addBookmarkedFlag(articleId);
                            SURPLEX.Watch.incrementBookmarkBadgeCount();
                        }
                    } else if(data.hasOwnProperty('lightbox') && data.hasOwnProperty('html')){
                        let $retHtml = $($.trim(data.html));
                        $retHtml.appendTo("body");
                        new Modal($retHtml.get(0)).show();
                        $retHtml.get(0).addEventListener('hide.bs.modal', function(){
                            $retHtml.remove();
                        });
                        if ($retHtml.find('.js-modal-confirm').length > 0) {
                            $retHtml.find('.js-modal-confirm').off('click').on('click', function (event) {
                                event.preventDefault();
                                let shownModal = Modal.getInstance($(this).closest('.modal').get(0));
                                if(shownModal !== null){
                                    shownModal.hide()
                                }
                                SURPLEX.Watch.toggleUserWatchlistItem(articleId, isActive, 1);
                            });
                        }
                    }
                    SURPLEX.Watch.refreshList = true;
                })
            );
        },

        requestBookmarkUrl: function (url, articleId, force) {
            return SURPLEX.Ajax.post(
                {
                    url: url + '&language=' + SURPLEX.App.pageLanguage,
                    data: {'articleId': articleId, 'force': force}
                }
            );
        },

        addBookmarkedFlag: function (articleId) {
            let $artWatch = $('.js-article-watch--item[data-article-id="' + articleId + '"] .js-article--watchtool');
            $artWatch.each(function () {
                let $watchIcon = $(this);
                $watchIcon.addClass('is-active');
                $watchIcon.find('.icon__bookmark').first()
                    .removeClass('icon__bookmark').addClass('icon__bookmark-fill');
                 $watchIcon.attr('title', $watchIcon.attr('data-text-add'));
            });
        },


        removeBookmarkedFlag: function (articleId) {
            let $artWatch = $('.js-article-watch--item[data-article-id="' + articleId + '"] .js-article--watchtool');
            $artWatch.each(function () {
                let $watchIcon = $(this);
                $watchIcon.removeClass('is-active');
                $watchIcon.find('.icon__bookmark-fill').first()
                    .removeClass('icon__bookmark-fill').addClass('icon__bookmark');
                $watchIcon.attr('title', $watchIcon.attr('data-text-remove'));
            });
        },

        toggleGuestWatchlistItem: function (articleId) {
            const articleIdAsString = String(articleId);
            let storedWatchlist = this.getBookmarkedItems();
            if (storedWatchlist.indexOf(articleIdAsString) >= 0) {
                storedWatchlist.splice(storedWatchlist.indexOf(articleIdAsString), 1);
                SURPLEX.Watch.removeBookmarkedFlag(articleId);
            } else {
                storedWatchlist.push(articleIdAsString);
                SURPLEX.Watch.addBookmarkedFlag(articleId);
            }
            SURPLEX.Utils.setCookie('surplex_notloggedin_watchlist', JSON.stringify(storedWatchlist));
        },

        incrementBookmarkBadgeCount: function () {
            const $badgeElement = SURPLEX.Watch.dropDownWatchList.find('.bookmark__statusBadge').first();
            if ($badgeElement) {
                try {
                    let value = parseInt($badgeElement.data('count'), 10) || 0;
                    let newValue = value + 1;
                    $badgeElement.data('count', newValue ).text(newValue > 9 ? '9+' : newValue).removeClass('d-none');
                    $badgeElement.closest('.icon__bookmark')
                        .removeClass('icon__bookmark').addClass('icon__bookmark-fill');
                    $badgeElement.addClass('update-count');
                } catch (err) {
                    SURPLEX.App.logError(err);
                    $badgeElement.closest('.icon__bookmark-fill')
                        .removeClass('icon__bookmark-fill').addClass('icon__bookmark');
                    $badgeElement.data('count', 0 ).text('0').addClass('d-none');
                }
                setTimeout(function () {
                    $badgeElement.removeClass('update-count');
                }, 200);
            }
        },

        decrementBookmarkBadgeCount: function () {
            const $badgeElement = SURPLEX.Watch.dropDownWatchList.find('.bookmark__statusBadge').first();
            if ($badgeElement) {
                try {
                    let value =  parseInt($badgeElement.data('count'), 10) || 0;
                    let newValue =  value > 0 ? value - 1 : 0;
                    $badgeElement.addClass('update-count');
                    $badgeElement.data('count', newValue ).text(newValue > 9 ? '9+' : newValue).removeClass('d-none');
                    if (newValue === 0) {
                        $badgeElement.addClass('d-none');
                        $badgeElement.closest('.icon__bookmark-fill')
                            .removeClass('icon__bookmark-fill').addClass('icon__bookmark');
                    }
                } catch (err) {
                    SURPLEX.App.logError(err);
                    $badgeElement.data('count', 0 ).text('0');
                    $badgeElement.addClass('d-none');
                    $badgeElement.closest('.icon__bookmark-fill')
                        .removeClass('icon__bookmark-fill').addClass('icon__bookmark');
                }
                setTimeout(function () {
                    $badgeElement.removeClass('update-count');
                }, 200);
            }
        },

        updateWatchlistBadge: function () {
            if (SURPLEX.App.getIsGuest() === false) {
                return;
            }
            const $badgeElement = SURPLEX.Watch.dropDownWatchList.find('.bookmark__statusBadge').first();
            if ($badgeElement) {
                const storedWatchlist = SURPLEX.Watch.getBookmarkedItems();
                if (storedWatchlist.length > 0) {
                    $badgeElement.addClass('update-count');
                    $badgeElement.data('count', storedWatchlist.length ).text(storedWatchlist.length > 9 ? '9+' : storedWatchlist.length.toString()).removeClass('d-none');
                    setTimeout(function () {
                        $badgeElement.removeClass('update-count');
                    }, 200);

                    $badgeElement.removeClass('d-none');
                    $badgeElement.closest('.icon__bookmark')
                        .removeClass('icon__bookmark').addClass('icon__bookmark-fill');
                    storedWatchlist.forEach(function (articleId) {
                        const articleTag = '.js-article-watch--item[data-article-id="' + articleId + '"]';
                        const $watchIcon = $(articleTag).find('.js-article--watchtool');
                        if ($watchIcon.length) {
                            $watchIcon.addClass('is-active');
                            $watchIcon.attr('title', $watchIcon.attr('data-text-add'));
                            $watchIcon.find('.icon__bookmark').first().removeClass('icon__bookmark').addClass('icon__bookmark-fill')
                        }
                    });
                } else {
                    $badgeElement.addClass('update-count');
                    $badgeElement.data('count', 0 ).text('0');
                    $badgeElement.closest('.icon__bookmark-fill')
                        .removeClass('icon__bookmark-fill').addClass('icon__bookmark');
                    setTimeout(function () {
                        $badgeElement.removeClass('update-count');
                    }, 200);
                    if (!$badgeElement.hasClass('d-none')) {
                        $badgeElement.addClass('d-none');
                    }
                }
            }
        },

        updateWatchList: function () {
            const $watchList = $('#js-watchListData');
            const $watchListBody = $('#watchListDropDown');
            const cookieWatchlist = SURPLEX.Utils.getCookie('surplex_notloggedin_watchlist');
            $('.js-watchlist .undo').removeClass('visible');

            if (!SURPLEX.Watch.refreshList && cookieWatchlist === this.watchListMachineIds && SURPLEX.App.getIsGuest()) {
                return true;
            }
            this.watchListMachineIds = cookieWatchlist;
            let fetchParams = cookieWatchlist === null ? {} : {watchlist: cookieWatchlist};
            SURPLEX.Ajax.fetch(
                {
                    url: SURPLEX.Utils.decodeBase($watchList.data('watchlist-url')),
                    data: fetchParams,
                    callbackStart : {},
                    callbackEnd: $.extend(SURPLEX.Ajax.getDefaultEndCallbacks(),
                        {
                            'showWatchlist': function (target, result) {
                                if (result.hasOwnProperty('html')) {
                                    $watchListBody.html(result.html);
                                    if (SURPLEX.App.isGuest) {
                                        const currentWatchList = SURPLEX.Utils.isJSON(SURPLEX.Utils.getCookie('surplex_notloggedin_watchlist')) || [];
                                        if (currentWatchList.sort().toString() !== result.articleIds.sort().toString()) {
                                            SURPLEX.Utils.setCookie('surplex_notloggedin_watchlist', JSON.stringify(result.articleIds));
                                            SURPLEX.Watch.updateWatchlistBadge();
                                        }
                                    }
                                    SURPLEX.App.enableImageLazyLoad();
                                    SURPLEX.App.enableDataRedirect();
                                    SURPLEX.Timer.initialize();
                                    SURPLEX.App.uniqueBindEvent('#watchlist_login', 'click', 'app', function () {
                                        SURPLEX.Track.trackGA(
                                            'loginModalTracking',
                                            SURPLEX.Watch.getSidebarPageType(),
                                            'modal_openedBy_sidebarShowWatchlistBtn'
                                        );
                                        let loginElem = document.getElementById('loginModal');
                                        let logInModal = Modal.getOrCreateInstance(loginElem);
                                        logInModal.show();
                                        let $urlRedirect = SURPLEX.Utils.decodeBase($(this).attr('data-redirect-url'));
                                        SURPLEX.App.loginRedirect = function () {
                                            SURPLEX.Track.trackGA(
                                                'watchlistUsage',
                                                $('meta[name=srxGooglePageType]').attr('content'),
                                                'openWatchlistBySidebar'
                                            )
                                            window.location.assign($urlRedirect);
                                        }
                                    });
                                    $watchListBody.off('click').on('click', '.js-article--watchtool', function (event) {
                                        event.preventDefault();
                                        SURPLEX.Watch.removeProduct($(event.target).parents('.cart--product'));
                                    });
                                }
                                SURPLEX.Watch.refreshList = false;
                            }
                        }
                    ),
                    loadingTarget : $watchList.find('.js-watchlist'),
                    callbackOnSuccess : {},
                    callbackOnFail : function () {
                        SURPLEX.Watch.watchListMachineIds = '';
                        $watchListBody.html("");
                    }
                }
            )
        }
    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.Watch);
    }
})($, SURPLEX);