import $ from "./cashwrapper";
;(function ($) {
    window.SURPLEX = {};
    SURPLEX.Bootstrap = $.extend({}, {
        bootstrappers: [],
        loaded: false,

        /**
         * @memberof SURPLEX.Bootstrap
         *
         */
        bootstrap: function () {
            SURPLEX.Bootstrap._invokeBootstrappers();
        },

        initializeLoadEvents: function () {
            $.each(SURPLEX.Bootstrap.bootstrappers, function (index, bootstrapper) {
                if (bootstrapper.hasOwnProperty('initializeLoadEvents')) {
                    bootstrapper.initializeLoadEvents();
                }
            });
            SURPLEX.Bootstrap.loaded = true;
        },

        /**
         * @memberof SURPLEX.Bootstrap
         *
         * @param bootstrap The bootstrap class to be registered.
         */
        registerBootstrap: function (bootstrap) {
            SURPLEX.Bootstrap.bootstrappers.push(bootstrap);
        },

        /**
         * Alle registrierten Bootstraps aufrufen.
         *
         * @memberof SURPLEX.Bootstrap
         */
        _invokeBootstrappers: function () {
            $.each(SURPLEX.Bootstrap.bootstrappers, function (index, bootstrapper) {
                if (bootstrapper.hasOwnProperty('initialize')) {
                    bootstrapper.initialize();
                }
            });
        }
    });
    window.addEventListener('DOMContentLoaded', function() {
        SURPLEX.Bootstrap.bootstrap();
    }, true);
    window.addEventListener('load', function() {
        SURPLEX.Bootstrap.initializeLoadEvents();
    }, true);
})($);