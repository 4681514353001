import $ from "./cashwrapper";
;(function ($, SURPLEX) {
    SURPLEX.Ajax = $.extend({}, {
        postSettings: {},
        initialize: function () {
            this.setupConfig();
        },
        setupConfig: function () {
            $.ajaxSetup({
                beforeSend: function (xhr, settings) {
                    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                    if (settings.type === 'POST') {
                        let csrf = SURPLEX.App.getCsrfToken();
                        if (csrf && csrf.length > 0) {
                            xhr.setRequestHeader("X-CSRF-Token", csrf);
                        }
                    }
                },
                complete: function (xhr) {
                    let url = xhr && xhr.getResponseHeader('X-Redirect');
                    if (url) {
                        window.location.assign(url);
                    }
                }
            });
        },

        loadCsrf(){
            return $.ajax({
                url: "/?r=esi/init-csrf-cookies",
                type: "HEAD"
            });
        },
        /* HELPER WRAPPER FUNCTIONS */
        showAjaxException: function (jqXHR, textStatus, errorThrown) {
            SURPLEX.App.showAjaxException(jqXHR, textStatus, errorThrown);
        },

        isDefined: function (subject) {
            return typeof subject !== 'undefined'
        },

        // check whether response contains an error message and logs them
        handleAjaxError: function (response) {
            if (SURPLEX.Ajax.isDefined(response.error) && SURPLEX.Ajax.isDefined(response.error_description)) {
                SURPLEX.App.logError(response.error_description);
            }
        },

        addLoadingToTarget: function (loadingTarget) {
            if(typeof loadingTarget !== 'undefined' && loadingTarget !== null){
                if(typeof loadingTarget === 'string'){
                    $(loadingTarget).addClass('is-loading');
                    if ($(loadingTarget).is("button,input")) {
                        $(loadingTarget).prop('disabled', true);
                    }
                } else if(typeof loadingTarget === 'object') {
                    try {
                        loadingTarget.addClass('is-loading');
                        if (loadingTarget.is("button,input")) {
                            loadingTarget.prop('disabled', true);
                        }
                    } catch (e){
                        SURPLEX.App.logError(e);
                    }
                }
            }
        },

        removeLoadingToTarget: function (loadingTarget) {
            if(typeof loadingTarget !== 'undefined'){
                $(loadingTarget).removeClass('is-loading');
                if ($(loadingTarget).is("button,input")) {
                    $(loadingTarget).prop('disabled', false);
                }
            }
        },


        getDefaultStartCallbacks: function () {
            return {
                logData: function (o) {
                }
            }
        },

        getDefaultSuccessCallbacks: function () {
            return {
                // nothing yet
            }
        },

        getDefaultEndCallbacks: function () {
            return {
                insertResult: function (o, result) {
                    if(o){
                        o.html(result);
                    }
                },
                defaultAppFunc: function (o, result) {
                    SURPLEX.App.initialize();
                    SURPLEX.Filter.initialize();
                    SURPLEX.Watch.enableListeners();
                    SURPLEX.Watch.updateWatchlistBadge();
                    SURPLEX.Bid.enableListeners();
                    SURPLEX.Register.enableListeners();
                    SURPLEX.Timer.initialize();
                    SURPLEX.SearchAgent.initialize();
                    SURPLEX.WebSocket.enableListeners();
                }
            }
        },


        runStartCallbacks: function (customCallback, targetObj, loadingTarget) {
            this.addLoadingToTarget(loadingTarget);
            if( typeof customCallback == 'function') {
                customCallback = {custom:customCallback};
            }
            var callbacksToRun = (SURPLEX.Ajax.isDefined(customCallback) ? customCallback : SURPLEX.Ajax.getDefaultStartCallbacks());
            for (var callback in callbacksToRun) {
                if (callbacksToRun.hasOwnProperty(callback) && typeof callbacksToRun[callback] === 'function') {
                    callbacksToRun[callback](targetObj);
                }
            }
        },

        runSuccessCallbacks: function (customCallback, targetObj, result) {
            if (typeof customCallback == 'function') {
                customCallback = {custom: customCallback};
            }
            var callbacksToRun = (SURPLEX.Ajax.isDefined(customCallback) ? customCallback : SURPLEX.Ajax.getDefaultSuccessCallbacks());
            for (var callback in callbacksToRun) {
                if (callbacksToRun.hasOwnProperty(callback) && typeof callbacksToRun[callback] === 'function') {
                    callbacksToRun[callback](targetObj, result);
                }
            }
        },

        runEndCallbacks: function (customCallback, targetObj, result, loadingTarget) {
           if( typeof customCallback == 'function') {
                customCallback = {custom:customCallback};
            }
            var callbacksToRun = (SURPLEX.Ajax.isDefined(customCallback) ? customCallback : SURPLEX.Ajax.getDefaultEndCallbacks());
            for (var callback in callbacksToRun) {
                if (callbacksToRun.hasOwnProperty(callback) && typeof callbacksToRun[callback] === 'function') {
                    callbacksToRun[callback](targetObj, result, loadingTarget);
                }
            }
            var resultIsJson = SURPLEX.Utils.isJSON(result);
            if(resultIsJson !== false && resultIsJson.hasOwnProperty('success') && resultIsJson.success === true && resultIsJson.hasOwnProperty('redirect')){
                // early exit for redirects
                return;
            }
            this.removeLoadingToTarget(loadingTarget);
        },

        runAfterFetchCallbacks: function (loadingTarget) {
            if(loadingTarget instanceof $ && loadingTarget.is('.js-article-list')){
                window.dispatchEvent( new Event('ab.trigger') );
            }
        },


        fetch: function (options) {

            let settings = $.extend({}, {
                url: '',
                data: {},
                dataType: 'json',
                targetObj : null,
                callbackStart : undefined,
                callbackEnd : undefined,
                loadingTarget : undefined,
                callbackOnSuccess : undefined,
                callbackOnFail : undefined
            }, options);

            this.runStartCallbacks(settings.callbackStart, settings.targetObj, settings.loadingTarget);
            return $
                .get({url: settings.url, data: settings.data})
                .done(function (result) {
                    SURPLEX.Ajax.handleAjaxError(result);
                    SURPLEX.Ajax.runSuccessCallbacks(settings.callbackOnSuccess, settings.targetObj, result);
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.hasOwnProperty('status') && (jqXHR.status === 301 || jqXHR.status === 302)) {
                        return;
                    }
                    if (typeof settings.callbackOnFail === 'function') {
                        settings.callbackOnFail(jqXHR, textStatus, errorThrown);
                    } else {
                        if (typeof settings.callbackOnFail === 'undefined') {
                            SURPLEX.Ajax.showAjaxException(jqXHR, textStatus, errorThrown);
                        }
                    }
                })
                .always(function (result) {
                    SURPLEX.Ajax.runEndCallbacks(settings.callbackEnd, settings.targetObj, result, settings.loadingTarget);
                    SURPLEX.Ajax.runAfterFetchCallbacks(settings.loadingTarget);
                })
        },

        post: function (options) {
            let settings = $.extend({}, {
                url: '',
                data: {},
                dataType: 'json',
                targetObj : undefined,
                callbackStart : undefined,
                callbackEnd : undefined,
                loadingTarget : undefined,
                callbackOnSuccess : undefined,
                callbackOnFail : undefined
            }, options, SURPLEX.Ajax.postSettings);

            this.runStartCallbacks(settings.callbackStart, settings.targetObj, settings.loadingTarget);
            let csrf = SURPLEX.App.getCsrfToken();
            if (csrf && csrf.length > 0 && SURPLEX.App.getIsGuest() === false) {
                return SURPLEX.Ajax.postAjax(settings);
            } else {
                return $.Deferred().resolve(SURPLEX.Ajax.loadCsrf().then(function () {
                    SURPLEX.Ajax.postAjax(settings);
                })).promise()
            }
        },

        setPostSettings: function (settings) {
            SURPLEX.Ajax.postSettings = settings;
        },

        postAjax: function (settings) {
            return $
                .post(settings)
                .done(function (result) {
                    SURPLEX.Ajax.handleAjaxError(result);
                    SURPLEX.Ajax.runSuccessCallbacks(settings.callbackOnSuccess, settings.targetObj, result);
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (typeof settings.callbackOnFail === 'function') {
                        settings.callbackOnFail(jqXHR, textStatus, errorThrown);
                    } else {
                        if (typeof settings.callbackOnFail === 'undefined') {
                            SURPLEX.Ajax.showAjaxException(jqXHR, textStatus, errorThrown);
                        }
                    }
                })
                .always(function (result) {
                    SURPLEX.Ajax.runEndCallbacks(settings.callbackEnd, settings.targetObj, result, settings.loadingTarget);
                    SURPLEX.Ajax.postSettings = {};
                })
        },

        isActive: function () {
            return $.active > 0;
        }
    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.Ajax);
    }
})($, SURPLEX);
