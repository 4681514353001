import $ from "./cashwrapper";

;(function ($, SURPLEX) {
    SURPLEX.Payment = $.extend({}, {
        CreditCardExpiry: {
            localstoragePrefix: 'surplex.payment.credit-card-expiry.',
            expiryRecheckIntervalSeconds: 1 * 60 * 60,
            initializeLoadEvents: function () {
                this.checkExpiryStatus();
            },
            checkExpiryStatus: function () {
                if (SURPLEX.App.isGuest) {
                    // Only logged in users
                    return;
                }
                if (this.isLastExpiryCheckOverdue()) {
                    SURPLEX.Ajax.fetch({
                        url: '/?r=Payment/credit-card-information/get-latest-credit-card',
                        callbackEnd: $.extend(
                            {
                                'expiryStatusFetched': function (target, data) {
                                    SURPLEX.Payment.CreditCardExpiry.saveLastExpiryCheck(new Date());
                                    if (data['id'] !== '' && data['month'] !== '' && data['year'] !== '') {
                                        let currentCardId = SURPLEX.Payment.CreditCardExpiry.getLatestCreditCardId();
                                        if (currentCardId != data['id']) {
                                            SURPLEX.Payment.CreditCardExpiry.resetCreditCardNoticeClosed();
                                        }
                                        SURPLEX.Payment.CreditCardExpiry.saveLatestCreditCard(data);
                                    }

                                    SURPLEX.Payment.CreditCardExpiry.showExpiryNotice();
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                    });
                }
                this.showExpiryNotice();
            },

            isLastExpiryCheckOverdue: function () {
                return (((new Date() - this.getLastExpiryCheck())) / 1000) > this.expiryRecheckIntervalSeconds;
            },

            getLastExpiryCheck: function () {
                return new Date(SURPLEX.Utils.storage.get(this.localstoragePrefix + 'lastCheck') || 0);
            },

            getLatestCreditCardId: function () {
                return SURPLEX.Utils.storage.get(this.localstoragePrefix + 'latest.id') || 0;
            },

            getLatestCreditCardMonth: function () {
                return SURPLEX.Utils.storage.get(this.localstoragePrefix + 'latest.month') || 0;
            },

            getLatestCreditCardYear: function () {
                return SURPLEX.Utils.storage.get(this.localstoragePrefix + 'latest.year') || 0;
            },

            /**
             * @param {Date} checkDate
             */
            saveLastExpiryCheck: function (checkDate) {
                SURPLEX.Utils.storage.set(this.localstoragePrefix + 'lastCheck', checkDate.toISOString());
            },

            saveLatestCreditCard: function (data) {
                SURPLEX.Utils.storage.set(this.localstoragePrefix + 'latest.id', data['id']);
                SURPLEX.Utils.storage.set(this.localstoragePrefix + 'latest.month', data['month']);
                SURPLEX.Utils.storage.set(this.localstoragePrefix + 'latest.year', data['year']);
            },

            showExpiryNotice: function () {
                if (this.isLatestCreditCardNearExpiration() && !this.isCreditCard30DayNoticeClosed()) {
                    $('#creditCardExpiryNotice')
                        .attr('data-is-30day-notice', '1')
                        .removeClass('is-hidden');
                }

                if (this.isLatestCreditCardExpired() && !this.isCreditCard0DayNoticeClosed()) {
                    $('#creditCardExpiryNotice')
                        .attr('data-is-0day-notice', '1')
                        .removeClass('is-hidden');
                }
            },

            isLatestCreditCardNearExpiration: function () {
                let today = new Date();
                if (parseInt(this.getLatestCreditCardYear()) <= 1970) { // Empty / no cc
                    return false;
                }
                return ((today.getMonth() + 1) === parseInt(this.getLatestCreditCardMonth(), 10)
                    && (today.getFullYear()) === parseInt(this.getLatestCreditCardYear(), 10));
            },

            isLatestCreditCardExpired: function () {
                let today = new Date();
                if (parseInt(this.getLatestCreditCardYear()) <= 1970) { // Empty / no cc
                    return false;
                }
                return ((today.getMonth() + 1) > parseInt(this.getLatestCreditCardMonth(), 10)
                        && (today.getFullYear()) === parseInt(this.getLatestCreditCardYear(), 10))
                    ||
                    ((today.getFullYear()) > parseInt(this.getLatestCreditCardYear(), 10));
            },

            setCreditCard30NoticeClosed: function () {
                SURPLEX.Utils.storage.set(this.localstoragePrefix + 'notice30', '1');
            },

            setCreditCard0NoticeClosed: function () {
                SURPLEX.Utils.storage.set(this.localstoragePrefix + 'notice0', '1');
            },

            resetCreditCardNoticeClosed: function () {
                SURPLEX.Utils.storage.remove(this.localstoragePrefix + 'notice30');
                SURPLEX.Utils.storage.remove(this.localstoragePrefix + 'notice0');
            },

            isCreditCard30DayNoticeClosed: function () {
                let isClosed = SURPLEX.Utils.storage.get(this.localstoragePrefix + 'notice30')
                return !!isClosed;
            },

            isCreditCard0DayNoticeClosed: function () {
                let isClosed = SURPLEX.Utils.storage.get(this.localstoragePrefix + 'notice0')
                return !!isClosed;
            }
        }
    });

    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.Payment.CreditCardExpiry);
    }
})($, SURPLEX);