import $ from "./cashwrapper";
import Modal from "bootstrap/js/dist/modal";
import Alert from "bootstrap/js/dist/alert";

;(function ($, SURPLEX) {
    SURPLEX.Register = $.extend({}, {
        /**
         * Welche Felder wir von Google Maps Response Objekt nutzen
         *
         * @type object
         */
        autocomplete: null,

        validator: [],

        /**
         * Welche Felder wir von Google Maps Response Objekt nutzen
         *
         * @type object
         */
        componentForm: {
            'route': 'long_name',
            'street_number': 'short_name',
            'locality': 'long_name',
            'country': 'short_name',
            'postal_code': 'short_name'
        },

        initialize: function () {
            const $form = $('.user-form');
            this.addValidatorRules();
            this.initializeFormValidator($form);
            this.enableListeners();
            this.notificationListener();
        },

        initializeLoadEvents: function () {
            this.trackFilledByStoredValues();
            this.enableNewsletterModal();
        },

        enableNewsletterModal: function () {
            window.addEventListener('acc_signup',SURPLEX.Register.showSignUpToast);
            window.addEventListener('nl_adjust', SURPLEX.Register.showNewsletterModal);
            window.addEventListener('nl_signup', SURPLEX.Register.showNewsletterSignUpModal);
        },

        showSignUpToast: function() {
            SURPLEX.Track.trackGA('registrationPromotion', $('meta[name=srxGooglePageType]').attr('content'),'campaignShown');
            SURPLEX.Ajax.fetch(
                {
                    url: '/',
                    data: {
                        r: "light-newsletter/show-sign-up",
                        language: SURPLEX.App.pageLanguage
                    },
                    callbackEnd : $.extend(SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                        {
                            'showResponseInToast': function (targetObject, result) {
                                if (result.success === true) {
                                    $.fn.surplexToast({
                                        id: 'toast_signup',
                                        status: '',
                                        title: result.title,
                                        content: result.msg
                                    });
                                }
                            }
                        }
                    ),
                    loadingTarget : $('#srplx-page')
                }
            )
        },

        showNewsletterModal: function () {
            SURPLEX.Track.trackGA('customizeNewsletterPromotion', $('meta[name=srxGooglePageType]').attr('content'),'campaignShown');
            SURPLEX.Ajax.fetch(
                {
                    url: '/',
                    data: {
                        'r': 'light-newsletter/show-newsletter-modal',
                        'language': SURPLEX.App.pageLanguage
                    },
                    callbackEnd : $.extend(
                        {
                            'showResponseModal': function (target, result) {
                                if (result.success === true) {
                                    if($('#bcNewsletterModal').length > 0){
                                        Modal.getInstance(document.getElementById('bcNewsletterModal')).hide();
                                        $('#bcNewsletterModal').remove();
                                    }
                                    $('#srplx-page').append(result.html);
                                    new Modal(document.getElementById('bcNewsletterModal'), {
                                        backdrop: 'static'
                                    }).show();
                                }

                            }
                        },
                        SURPLEX.Ajax.getDefaultEndCallbacks()
                    )
                }
            );
        },
        showNewsletterSignUpModal: function () {
            SURPLEX.Track.trackGA('newsletterPromotion', $('meta[name=srxGooglePageType]').attr('content'),'campaignShown');
            SURPLEX.Ajax.fetch(
                {
                    url: '/',
                    data: {
                        'r': 'light-newsletter/show-newsletter-modal-sign-up',
                        'language': SURPLEX.App.pageLanguage
                    },
                    callbackEnd : $.extend(
                        {
                            'showResponseModal': function (target, result) {
                                if (result.success === true) {
                                    if($('#bcNewsletterModal').length > 0){
                                        Modal.getInstance(document.getElementById('bcNewsletterModal')).hide();
                                        $('#bcNewsletterModal').remove();
                                    }
                                    $('#srplx-page').append(result.html);
                                    new Modal(document.getElementById('bcNewsletterModal'), {
                                        backdrop: 'static'
                                    }).show();

                                    SURPLEX.Register.initializeFormValidator($('#bcNewsletterModal').find('.user-form'));
                                }
                            }
                        },
                        SURPLEX.Ajax.getDefaultEndCallbacks()
                    )
                }
            );
        },
        notificationListener: function () {
            $('input.js-notification').on('change', function () {
                let $element = $(this);
                let isChecked = $element.is(':checked');
                let inputValue = isChecked? 1 : 0;
                let cascade = $element.data('cascade');
                let cascaded = $element.data('cascaded');
                $('#notifications-manager-' + $element.attr('id')).val(inputValue);
                if (cascade !== undefined) {
                    $('input' + cascade + '[type="hidden"]').each(function () {
                        let $cascadedInput = $(this);
                        let cascadedVal = $cascadedInput.val() === '1' ? 1 : 0;
                        if (cascadedVal !== inputValue) {
                            let $cascadedSwitch = $cascadedInput.closest('.js-form-switch');
                            $cascadedInput.val(inputValue);
                            $cascadedSwitch.find('input[type="checkbox"]').prop('checked', isChecked);
                        }
                    });
                }
                if (cascaded !== undefined) {
                    let cascadeInput = $('input' + cascaded + '[type="hidden"]');
                    let cascadeSwitch = cascadeInput.closest('.js-form-switch');
                    if (isChecked === false) {
                        let cascadedTotalValue = true;
                        $('input[data-cascaded="' + cascaded + '"]').each(function () {
                            if (isChecked !== $(this).is(':checked')) {
                                cascadedTotalValue = false;
                            }
                        });
                        if (cascadedTotalValue === true) {
                            if (cascadeInput.val() !== inputValue) {
                                cascadeSwitch.find('input[type="hidden"]').val(0);
                                cascadeSwitch.find('input[type="checkbox"]').prop('checked', false);
                            }
                        }
                    }
                    if (isChecked === true) {
                        if (cascadeInput.val() !== inputValue) {
                            cascadeSwitch.find('input[type="hidden"]').val(1);
                            cascadeSwitch.find('input[type="checkbox"]').prop('checked', true);
                        }
                    }
                }
                $(this).closest('form').trigger('submit', { cancelable: true});
            });

            $('.js-frequency-select').on('change', function () {
                let $frequency = $(this);
                let url = $frequency.parents('.js-frequency--options').first().data('url')+'&language='+SURPLEX.App.pageLanguage;
                SURPLEX.Ajax.post(
                    {
                        url: SURPLEX.Utils.decodeBase(url),
                        data: {
                            newsletter_frequency: $frequency.val(),
                        },
                        callbackEnd : $.extend(SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                            {
                                'showResponseInToast':
                                    function (targetObject, result) {
                                        if (result.success === true) {
                                            $.fn.surplexToast({
                                                id: 'toast_newsletter_frequency',
                                                status: 'success',
                                                title: result.title,
                                                content: result.msg,
                                                time: result.time,
                                                hide_in_ms: 5000
                                            });
                                        }
                                    }
                            }
                        ),
                        loadingTarget : $(this).closest('div')
                    }
                )
            });
        },

        trackFilledByStoredValues: function () {
            if(
                $('#user-register-form[data-formtype=signupStep2]').length > 0 &&
                SURPLEX.Utils.getCookie('signupStep2Changes') === 'stored')
            {
                SURPLEX.Utils.setCookie('signupStep2Changes', 'set', -1);
                SURPLEX.Track.trackGA("formTracking", $('meta[name=srxGooglePageType]').attr('content'), "filledByStoredValues");
            }
        },

        addValidatorRules: function () {
            $.validator.addMethod(
                "phone", function (value, element, regexp) {
                    return this.optional(element) || /^[0-9 \-.()]+$/.test(value);
                }
            );
            $.validator.addMethod(
                "emailnewsletter", function (value, element) {
                    const validFormat = this.optional(element) || /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test( value );
                    if (!validFormat) {
                        return false;
                    }
                    $.ajax({
                        url: SURPLEX.Utils.decodeBase($(element).data('validate-url')),
                        type: 'GET',
                        data: {
                            'User': {'email': value}
                        }
                    }).done(function (data, textStatus, jqXHR) {
                        const createUserTag = $(element).data('user-create');
                        const confirmUserTag = $(element).data('user-confirm');
                        if (data === true) {
                            $(createUserTag).removeClass('d-none');
                            $(confirmUserTag).addClass('d-none');
                        } else {
                            $(createUserTag).addClass('d-none');
                            $(confirmUserTag).removeClass('d-none');
                        }
                    });
                    return true;
                }
            );
            $.validator.addMethod("minonedigit", function (value, element) {
                return this.optional(element) || /[0-9]+/.test(value);
            });
            $.validator.addMethod("recaptcha", function () {
                return typeof grecaptcha === 'object' && grecaptcha.getResponse.length !== 0;
            });
            $.validator.addMethod(
                "minonechar", function (value, element) {
                    return this.optional(element) || value.toString().replace(new RegExp([
                        '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
                        '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
                        '\ud83d[\ude80-\udeff]', // U+1F680 to U+1F6FF
                        ' ' //Space
                    ].join('|'), 'g'), '').length > 0;
                });
            $.validator.addMethod(
                "zip", function (value, element, rule) {
                    if(rule.toString().split(',').indexOf($('#user-country').val()) >-1){
                        return !/\D/.test(value); // some countries zip code must contain only digits
                    }
                    return this.optional(element) || value.toString().length > 0;
                }
            );
            $.validator.addMethod(
                "sellerzip", function (value, element, rule) {
                    if(rule.toString().split(',').indexOf($('#seller-country').val()) >-1){
                        return !/\D/.test(value) && value.toString().length === 5;
                    }
                    return this.optional(element) || value.toString().length > 0;
                }
            );
            $.validator.addMethod(
                "ustid", function (value, element) {
                    let  $userCountry = $('#user-country');
                    let countryDataIso = $($userCountry.get(0).options[$userCountry.get(0).selectedIndex]).data('country-iso') || '';
                    let selectedCountry = countryDataIso.toString().toUpperCase();
                    return value === '' && (selectedCountry === 'RS' || selectedCountry === 'CH' || selectedCountry === 'GB') || checkVat(value.toString().toUpperCase()) === true;
                    function checkVat(vat) {
                        let regExRules = {
                            'BE'  : /^\d{10}$/,
                            'BG'  : /^\d{9,10}$/,
                            'DK'  : /^\d{8}$/,
                            'DE'  : /^\d{9}$/,
                            'EE'  : /^\d{9}$/,
                            'EU'  : /^\d{9}$/,
                            'FI'  : /^\d{8}$/,
                            'FR'  : /^[0-9a-z][0-9a-z]\d{9}$/i,
                            'EL'  : /^\d{9}$/,
                            'IE'  : /^(\d[0-9a-z]\d{5}[a-z]|\d{7}[a-w][a-i])$/i,
                            'IT'  : /^\d{11}$/,
                            'HR'  : /^\d{11}$/,
                            'LV'  : /^\d{11}$/,
                            'LT'  : /^(\d{9}|\d{12})$/,
                            'LU'  : /^\d{8}$/,
                            'MT'  : /^\d{8}$/,
                            'NL'  : /^\d{9}B\d\d$/i,
                            'AT'  : /^U\d{8}$/i,
                            'PL'  : /^\d{10}$/,
                            'PT'  : /^\d{9}$/,
                            'RO'  : /^\d{2,10}$/,
                            'SE'  : /^\d{10}01$/,
                            'SK'  : /^\d{10}$/,
                            'SI'  : /^\d{8}$/,
                            'ES'  : /^([a-z]\d{7}[0-9a-z]|[0-9a-z]\d{7}[a-z])$/i,
                            'CZ'  : /^\d{8,10}$/,
                            'HU'  : /^\d{8}$/,
                            'GB'  : /^(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/i,
                            'XI'  : /^(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/i,
                            'CY'  : /^\d{8}[a-z]$/i,
                            'CHE'  : /^\d{9}$/i,
                            'RS '  : /^\d{9}$/i
                        };
                        let $vatStart = $('#vatstart');
                        let selectedVatStart = $($vatStart.get(0).options[$vatStart.get(0).selectedIndex]).data('vat-start').toString().toUpperCase();

                        return regExRules.hasOwnProperty(selectedVatStart) ? regExRules[selectedVatStart].test(vat) : ((selectedCountry === 'RS ' || selectedCountry === 'CH' || selectedCountry === 'GB') && $.trim(selectedVatStart) !== '');
                    }
                },
                function() {
                    let $vatStart = $('#vatstart');
                    let vat_error = $($vatStart.get(0).options[$vatStart.get(0).selectedIndex]).data('vat-error');
                    $('#user-ustid').data('msg-vaterror', vat_error);
                    return vat_error;
                });

            $.validator.addMethod(
                "spain_company", function (value, element) {
                    let $userCountry = $('#user-country');
                    let countryDataIso = $($userCountry.get(0).options[$userCountry.get(0).selectedIndex]).data('country-iso') || '';
                    let selectedCountry = countryDataIso.toString().toUpperCase();
                    if (selectedCountry !== 'ES') {
                        return true;
                    }
                    let spainCompanyKeyword = /autonomo|autonoma|autónomo|particular|autónoma/i;
                    if (selectedCountry === 'ES') {
                        let companyValue = $('#user-company').val();
                        return spainCompanyKeyword.test(companyValue) === false;
                    }
                },
                function() {
                    let vat_error = $('#vatstart option[value="ES"]').data('country-vat-error');
                    $('#user-company').data('msg-vaterror', vat_error);
                    return vat_error;
                });
        },

        initializeGoogleMaps: function () {
            let $gInputField = $('#user-address');
            if ($gInputField.length > 0) {
                try {
                    SURPLEX.Register.autocomplete = new google.maps.places.Autocomplete(
                        /** @type {!HTMLInputElement} */($gInputField[0]),
                        {types: ['geocode']});
                    if (typeof SURPLEX.Register.autocomplete === 'object') {
                        SURPLEX.Register.autocomplete.addListener('place_changed', SURPLEX.Register.fillInAddress);
                        $gInputField.on('keydown', function (e) {
                            if (e.which === 13) {
                                return false;
                            }
                        });
                    }
                }
                catch (e) {
                    return null;
                }
            }
        },

        appendCaptchaEl: function ($form) {
            if ($form.find('.g-recaptcha-response').length) {
                return;
            }
            let $captchaEl = $form.find('.srx-recaptcha');
            if ($captchaEl.length) {
                $captchaEl.surplexGoogleCaptcha();
            }
            let inputName = typeof $form.attr('name') !== 'undefined' ? $form.attr('name') + '[g-recaptcha-response]' : 'g-recaptcha-response';
            $form.append($('<input type="hidden" class="g-recaptcha-response" name="' + inputName + '">'));
        },

        /**
         * Adresse aus dem Google Places Autocomplete in die jeweiligen Felder füllen
         */
        fillInAddress: function () {
            let val, place = SURPLEX.Register.autocomplete.getPlace();
            if (typeof place === 'object' && place.hasOwnProperty('address_components')) {
                for (var component in SURPLEX.Register.componentForm) {
                    var $element = $('[data-google-place*="' + component + '"]');
                    if ($element.length > 0) {
                        $element.val('').attr('disabled', null);
                    }
                }
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (typeof SURPLEX.Register.componentForm[addressType] === 'string') {
                        var $elem = $('[data-google-place*="' + addressType + '"]');
                        if ($elem.length > 0) {
                            val = place.address_components[i][SURPLEX.Register.componentForm[addressType]];
                            if (addressType === 'street_number' || addressType === 'route') {
                                if (addressType === 'street_number') {
                                    $elem.val($elem.val() + ' ' + val).trigger("focusout");
                                } else {
                                    $elem.val(val + '' + $elem.val()).trigger("focusout");
                                }
                            } else if (addressType === 'country') {
                                $elem.each(function () {
                                    $(this).val($(this).find("[data-country-iso*='" + val + "']").val());
                                    if(!$(this).is('.country-code')){
                                        $(this).trigger("focusout");
                                    }
                                });
                            } else {
                                $elem.val(val).trigger("focusout");
                            }
                        }
                    }
                }
            }
        },
        initializeFormValidator: function($form){
            $form
                .each(function(){
                    if ($.inArray($(this).data('formtype'), ['signupStep1', 'sellerForm']) !== -1) {
                        $(this).appendFingerprint();
                        $(this).find('input.securityHash').addClass('forcevalidate').attr('data-rule-fingerprint', "1");
                    }
                    $(this).data('serialized', $(this).serialize());
                    $(this).data('serializedArray', $(this).serializeArray());
                })
                .on('change input', function(){
                    let modified = $(this).serialize() === $(this).data('serialized');
                    let $profileHint = $(".js-profile-saved");
                    if ($profileHint.length > 0) {
                        $profileHint.each(function (index, el) {
                            let alertInstance = Alert.getOrCreateInstance(el);
                            if (alertInstance !== null) {
                                alertInstance.close();
                            }
                        });
                    }
                    $(this)
                        .find('input[type="submit"], button[type="submit"], a[data-type="cancel"]')
                        .not('.ignore-disable')
                        .attr('disabled', modified ? true : null)
                        .toggleClass('disabled', modified ? true : null);

                    if ($.inArray($(this).data('formtype'), ['signupStep1', 'signupStep2', 'sellerForm', 'ssaRegister', 'bcNewsletterCategoryModal', 'newsletterCategoryModal', 'inquiry', 'newsletterFooter']) !== -1) {
                        $(this).trigger('interacted');
                    }
                })
                .find('input[type="submit"], button[type="submit"], a[data-type="cancel"]')
                .not('.ignore-disable')
                .attr('disabled', true)
                .addClass('disabled');


            $form
                .each(function(){
                    let $currentForm = $(this);
                    SURPLEX.Register.validator[$currentForm.data('action')] = $currentForm.validate({
                        ignore: "input[type=hidden]:not(.forcevalidate)",
                        groups: {
                            vatId : "User[country_ustid] User[ustid]",
                            mobile: "User[country_mobile] User[mobile]",
                            sellermobile: "Seller[country_mobile] Seller[mobile]",
                            telephone: "User[country_telephone] User[telephone]",
                            sellertelephone: "Seller[country_telephone] Seller[telephone]",
                            fax: "User[country_fax] User[fax]"
                            },
                        errorElement: "div",
                        errorClass: "is-invalid",
                        validClass: "is-valid",
                        focusInvalid: true,
                        onfocusout: function( element ) {
                            if (!this.checkable( element ) && ( typeof $(element).attr('aria-invalid') === 'string' || element.name in this.submitted || !this.optional( element ) ) ) {
                                this.element( element );
                            }
                        },
                        onkeyup: function( element, event ) {
                            var excludedKeys = [
                                16, 17, 18, 20, 35, 36, 37,
                                38, 39, 40, 45, 144, 225, 91, 93
                            ];
                            if ( event.which === 9 && this.elementValue( element ) === "" || $.inArray( event.keyCode, excludedKeys ) !== -1) {

                            } else if ( element.name in this.submitted || element.name in this.invalid ) {
                                if((typeof $( element ).data('rule-email') == "string" ||
                                    typeof $( element ).data('rule-ustid') == "string")
                                    && $.inArray( event.keyCode, [46, 8] ) !== -1
                                ){
                                    if ( this.settings.unhighlight ) {
                                        this.settings.unhighlight.call( this, element, this.settings.errorClass, this.settings.validClass );
                                    }
                                    this.hideThese( this.errorsFor( element ) );
                                    delete this.invalid[element.name];
                                    delete this.submitted[element.name];
                                    this.resetElements( $( element ) );

                                } else if(typeof $( element ).data('dontrevalidateonfocusout') == "string"){

                                } else {
                                    this.element( element );
                                }
                            }
                        },
                        showErrors: function (errorMap, errorList) {
                            let i;
                            for (i = 0; errorList[i]; i++) {
                                let error = errorList[i];
                                if (error.message.hasOwnProperty('newvalue')) {
                                    this.findByName(error.element.name).val(error.message.newvalue);
                                    errorList[i].message = this.findByName(error.element.name).data('msg-remote');
                                }
                                if (error.message.hasOwnProperty('source')) {
                                    if (error.message.hasOwnProperty('track')) {
                                        SURPLEX.Track.trackGA("formTracking", (typeof $currentForm.data('trackingtype') !== 'undefined' ? $currentForm.data('trackingtype') : $('meta[name=srxGooglePageType]').attr('content'))  , error.message.track);
                                    }
                                    if (error.message.hasOwnProperty('errorText')) {
                                        errorList[i].message = error.message.errorText;
                                    } else {
                                        errorList[i].message = this.findByName(error.element.name).data('msg-remote');
                                    }
                                }
                                if ($(error.element).is('.securityHash') || error.method === 'fingerprint') {
                                    SURPLEX.Track.trackGA("formTracking", (typeof $currentForm.data('trackingtype') !== 'undefined' ? $currentForm.data('trackingtype') : $('meta[name=srxGooglePageType]').attr('content'))  , 'hash_browser');
                                    //$(error.element).closest('form').find('.srx-recaptcha').surplexGoogleCaptcha();
                                }
                            }
                            this.errorList = $.grep(errorList, function (error) {
                                return error.element !== undefined;
                            });
                            this.invalid = $.extend( {}, errorMap );

                            SURPLEX.FormValidationErrorTracking.parseShowErrorList(
                                this.errorList
                            );

                            this.defaultShowErrors();
                            SURPLEX.Register.enableListeners();
                        },
                        errorPlacement: function (error, element) {
                            try {
                                if (element.data("error-placement")) {
                                    error.appendTo(element.closest('form').find(element.data("error-placement")));
                                } else if (element.data("error-placement-before")) {
                                    error.insertBefore(element.closest('form').find(element.data("error-placement-before")));
                                } else if (element.is('.securityHash')) {
                                } else {
                                    error.insertAfter(element);
                                }
                            } catch (e){
                                SURPLEX.App.logError(e);
                            }

                        },
                        invalidHandler: function (event, validator) {
                            $(this).find('.js-form-status').addClass('is-hidden').html('');
                        },
                        submitHandler: function (form) {
                            if ($($(form).data('trigger')).length > 0) {
                                $($(form).data('trigger')).attr("disabled", "disabled");
                            }
                            if ($(form).data('bc-interests')) {
                                SURPLEX.Register.appendBlueConicInterests($(form));
                            }
                            let method = $(form).attr('method') === 'get' ? 'fetch' : "post";
                            if (SURPLEX.Ajax.hasOwnProperty(method)) {
                                if (SURPLEX.App.getIsGuest() && method === 'post' && $(form).data('allow-fp') !== 'no' ) {
                                    if($.inArray($(this).data('formtype'), ['signupStep1']) === -1){
                                        $(form).appendFingerprint()
                                    }
                                }
                                let $captchaPresentEl = $(form).find('.srx-recaptcha')
                                if($captchaPresentEl.length > 0 && typeof grecaptcha !== 'undefined'){
                                    try {
                                        grecaptcha.execute($captchaPresentEl.data('sitekey'), {
                                            action: $captchaPresentEl.data('webaction')
                                        }).then(function (token) {
                                            $(form).find('.g-recaptcha-response').val(token);
                                            submitForm();
                                        }, function (reason) {
                                            $(form).find('.g-recaptcha-response').val(reason);
                                        });
                                    } catch (e) {
                                        $(form).find('.g-recaptcha-response').val(e.message);
                                        submitForm();
                                    }
                                } else {
                                    submitForm();
                                }

                                function submitForm(){
                                    SURPLEX.Ajax[method](
                                        {
                                            url: SURPLEX.Utils.decodeBase($(form).data('action')),
                                            data: $(form).serialize(),
                                            targetObj : $(form),
                                            callbackEnd : $.extend(
                                                {'registrationSubmited': SURPLEX.Register.formSubmited},
                                                {'default': SURPLEX.Ajax.getDefaultEndCallbacks().defaultAppFunc}
                                            ),
                                            loadingTarget : ($($(form).data('trigger')).length > 0) ? $($(form).data('trigger')) : $('#create-account, #update-account, .js-send-invoice, #smstan, #bcNewsletterModal .btn[type="submit"]'),
                                            callbackOnSuccess : {},
                                            callbackOnFail : {}
                                        }
                                    );
                                }
                            }
                        },
                        highlight: function (element, errorClass, validClass) {
                            let $element;
                            if (element.type === "radio") {
                                $element = this.findByName(element.name);
                            } else {
                                $element = $(element);
                            }
                            $element.removeClass('is-invalid').addClass(errorClass).removeClass(validClass);
                            SURPLEX.FormValidationErrorTracking.parseHighlight(
                                $element[0]
                            );
                        },
                        unhighlight: function (element, errorClass, validClass) {
                            let $element;
                            if (element.type === "radio") {
                                $element = this.findByName(element.name);
                            } else {
                                $element = $(element);
                            }
                            $element.removeClass('is-invalid').removeClass(validClass).removeClass(errorClass);
                            if ($element.hasClass('required') === true || $element.val().length > 0) {
                                if ($element.data('highlight-only-errors') === undefined || $element.data('highlight-only-errors') === 'false') {
                                    $element.addClass(validClass);
                                }
                            }
                            SURPLEX.FormValidationErrorTracking.parseUnhighlight(
                                $element[0]
                            );
                        },
                    });
                    if($currentForm.find("select.country-code").length > 0){
                        SURPLEX.Register.initializeCountrySelector();
                    }
                    if($.inArray( $(this).data('formtype'), ['signupStep1','signupStep2', 'ssaRegister', 'bcNewsletterCategoryModal', 'newsletterCategoryModal', 'inquiry', 'newsletterFooter'] ) !== -1){
                        let interacted = false;
                        $(this)
                            .on('interacted', function(){
                                if(interacted === false){
                                    interacted = true;
                                    SURPLEX.Register.appendCaptchaEl($(this));
                                    $(this).off('interacted');
                                }
                            });
                        window.addEventListener('beforeunload', function(e) {
                            let pagePath = SURPLEX.Track.getDataFromLayer('dataLayer', 'virtualPagePath');
                            let pageType = SURPLEX.Track.getDataFromLayer('dataLayer', 'PageType');
                            SURPLEX.Track.trackVPV(
                                pageType,
                                pagePath + '/' + (interacted ? 'interacted' : 'no-interacted')
                            );
                        });
                    }

                    if($.inArray( $(this).data('formtype'), ['sellerForm'] ) !== -1){
                        let interacted = false;
                        $(this)
                            .on('interacted', function(){
                                if(interacted === false){
                                    SURPLEX.Register.appendCaptchaEl($(this));
                                    let $upload_widget = $('#upload_widget');
                                    if($upload_widget.length > 0){
                                        $upload_widget.removeAttr('disabled');
                                        $upload_widget.cloudinaryUpload($upload_widget.data('cloudinaryupload'));
                                    }
                                    interacted = true;
                                    $(this).off('interacted');
                                }
                            });
                        let $sellerCountrySelect = $('#seller-country');
                        let $sellerZip = $('#seller-zip');
                        if ($sellerCountrySelect.length > 0 && $sellerZip.length > 0) {
                            SURPLEX.App.uniqueBindEvent($sellerCountrySelect, 'change focusout', 'register', function (e) {
                                let currentCountry = $(this).get(0).value;
                                if (currentCountry === 'DEU' ) {
                                    $sellerZip.rules("add", { required: true, sellerzip: 'DEU'});
                                    $sellerZip.closest('.zip-div').removeClass('d-none').addClass('d-block');
                                } else {
                                    $sellerZip.removeClass('required').removeAttr('required');
                                    $sellerZip.rules("remove", 'required sellerzip');
                                    $sellerZip.closest('.zip-div').addClass('d-none').removeClass('d-block');
                                }
                            });
                        }
                    }
                    if(typeof $(this).data('show-required') !== 'undefined'){
                        let formValidator = SURPLEX.Register.validator[$(this).data('action')];
                        if (!formValidator.form()) {
                            formValidator.focusInvalid();
                            let $pane = $(this).closest('.tab-pane');
                            if ($pane.length) {
                                let $tabNav = $('#' + $pane.attr('aria-labelledby'));
                                if ($tabNav.length > 0) {
                                    let $badge = $tabNav.find('.user__statusBadge');
                                    if ($badge.length > 0) {
                                        $badge.text(formValidator.errorList.length.toString());
                                    } else {
                                        $tabNav.append(
                                            $('<span>')
                                                .attr({'class': 'user__statusBadge'})
                                                .text(formValidator.errorList.length.toString()));
                                    }
                                }
                            }
                        }
                    } else if($(this).is('.focus')){
                        $(this)
                            .find('input, select, textarea, button')
                            .filter(':enabled')
                            .filter(SURPLEX.Utils.jqueryVisibleSelector)
                            .first()
                            .focus();
                    }
                });
        },

        initializeCountrySelector: function () {
            let $countryCodeSelects = $("select.country-code");
            let $countrySelect = $('#user-country');
            let $vatstartInput = $('#vatstart');
            let $vatstartLabel = $('label[for=vatstart]');
            let $ustid = $('#user-ustid');
            let $ustidDiv = $('#user-ustidDiv');
            let $vatWarningSerbia = $('#vat-warning-serbia');
            let $vatInfoSpain = $('#vat-info-spain');
            let $vatInfoGeneral = $('#vat-info-general');
            let $countryAlertIran = $('#country-alert-iran');
            let $countryAlertPakistan = $('#country-alert-pakistan');


            SURPLEX.App.uniqueBindEvent('#vatstart', 'change', 'register', function (e) {
                $ustid
                    .attr('placeholder', $($(this).get(0).options[$(this).get(0).selectedIndex]).data('placeholder').toString())
                    .trigger('blur');
            });

            if ($countrySelect.length > 0) {
                SURPLEX.App.uniqueBindEvent($countrySelect, 'change focusout', 'register', function (e) {
                    let currentCountry = $($(this).get(0).options[$(this).get(0).selectedIndex]).data('country-iso');
                    let currentCountryVat = $($(this).get(0).options[$(this).get(0).selectedIndex]).data('vat-start');
                    let $companyEl = $('#user-company');
                    if ($companyEl.val() !== '') {
                        $companyEl.valid();
                    }

                    if (currentCountry === 'RS' ) {
                        $vatWarningSerbia.addClass('d-flex');
                        $vatWarningSerbia.removeClass('d-none');
                    } else {
                        $vatWarningSerbia.removeClass('d-flex');
                        $vatWarningSerbia.addClass('d-none');
                    }

                    if (currentCountry === 'ES' ) {
                        $("label[for='vatstart']").text($ustid.data('label-es'));
                        $vatInfoSpain.addClass('d-flex');
                        $vatInfoSpain.removeClass('d-none');
                        $vatInfoGeneral.removeClass('d-flex');
                        $vatInfoGeneral.addClass('d-none');
                    } else {
                        $("label[for='vatstart']").text($ustid.data('label-default'));
                        $vatInfoSpain.removeClass('d-flex');
                        $vatInfoSpain.addClass('d-none');
                        $vatInfoGeneral.addClass('d-flex');
                        $vatInfoGeneral.removeClass('d-none');
                    }

                    if (currentCountry === 'IR' ) {
                        $countryAlertIran.addClass('d-flex');
                        $countryAlertIran.removeClass('d-none');
                    } else {
                        $countryAlertIran.removeClass('d-flex');
                        $countryAlertIran.addClass('d-none');
                    }

                    if (currentCountry === 'PK' ) {
                        $countryAlertPakistan.addClass('d-flex');
                        $countryAlertPakistan.removeClass('d-none');
                    } else {
                        $countryAlertPakistan.removeClass('d-flex');
                        $countryAlertPakistan.addClass('d-none');
                    }

                    if ($countryCodeSelects.length > 0) {
                        if (typeof currentCountry === "string" && currentCountry !== '') {
                            $countryCodeSelects.each(function () {
                                let $correspondingInput = $("[name='" + $(this).prop('name').replace("country_", "") + "']");
                                if ($correspondingInput.length > 0 && $correspondingInput.val() === '') {
                                    $(this).val(
                                        $(this).find("[data-country-iso*='" + currentCountry + "']").val()
                                    ).trigger('change');
                                }
                            });
                        }
                    }
                    if (currentCountryVat && $vatstartInput.length > 0 && $ustid.length > 0) {
                        $ustid.removeAttr('disabled');
                        $vatstartInput.removeAttr('disabled');
                        $ustidDiv.removeClass('d-none');
                        if(currentCountryVat === ' ' || currentCountryVat === 'RS ' || currentCountryVat === 'CHE' || currentCountryVat === 'GB'){
                            $vatstartLabel.removeClass('required');
                            $vatstartInput.removeClass('required').removeAttr('required');
                            $vatstartInput.rules("remove", 'required');
                            $ustid.removeClass('required').removeAttr('required');
                            $ustid.rules("remove", 'required');
                            $ustid.trigger('blur');
                        } else {
                            $vatstartInput.addClass('required').attr('required', 'required');
                            $vatstartLabel.addClass('required');
                            $vatstartInput.rules("add", {required: true});
                            $ustid.addClass('required').attr('required', 'required');
                            $ustid.rules("add", {required: true});
                            $ustid.trigger('blur');
                        }
                    } else {
                        if ($ustid.length > 0) {
                            $ustid.removeClass('required').removeAttr('required');
                            $ustid.rules("remove", 'required');
                            $ustid.trigger('blur');
                            $ustid.attr('disabled', 'disabled');
                            $vatstartInput.attr('disabled', 'disabled');
                            $vatstartInput.removeAttr('required');
                            $ustidDiv.addClass('d-none');
                        }
                    }
                });
            }
        },

        enableListeners: function () {
            SURPLEX.App.uniqueBindEvent('#mobile-verification-code', 'keyup keypress', 'register', function (e) {
                let keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                    $('#smsTanSubmit').get(0).click();
                    return false;
                }
            });
            SURPLEX.App.uniqueBindEvent('#trigger-activation-mail', 'click', 'register', function (e) {
                SURPLEX.Track.trackGA("formTracking", $('meta[name=srxGooglePageType]').attr('content'), 'error_User[email]_alreadyRegisteredEmail_resendClicked');
                let $element = $(this);
                let id = $element.data('id');
                SURPLEX.Ajax.post(
                    {
                        url: $(this).data('action-url'),
                        data: {'id':id},
                        callbackStart : {},
                        callbackEnd : {},
                        loadingTarget : $('#user-register-form'),
                        callbackOnSuccess : $.extend(
                            SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                            {
                                'onSuccessHandler': function (targetObject, result, loadingTarget) {
                                    if (result.hasOwnProperty('success') && result.success === true) {
                                        if (result.hasOwnProperty('message')) {
                                            $('.ajax-error-toast').remove();
                                            $.fn.surplexToast({
                                                title: result.title || '',
                                                content: result.message,
                                                status: 'success'
                                            });
                                        }
                                    }
                                }
                            }
                        ),
                        callbackOnFail : function (jqXHR, textStatus, errorThrown) {
                            SURPLEX.Ajax.showAjaxException(jqXHR, textStatus, errorThrown);
                            SURPLEX.Track.trackGA("formTracking", $('meta[name=srxGooglePageType]').attr('content'), 'error_User[email]_alreadyRegisteredEmail_resendLimitReached');
                        }
                    }
                )
            });
            SURPLEX.App.uniqueBindEvent('body .form--link-resend', 'click', 'register', function (e) {
                e.preventDefault();
                $('#mobile-verification-code').val('');
                let $resendButton = $(this);
                let $form = $resendButton.closest('form');
                $form.find('input, button').not('.close').attr("disabled", "disabled");
                SURPLEX.Ajax.post(
                    {
                        url: $(this).data('href'),
                        callbackStart : {},
                        callbackEnd : {},
                        loadingTarget: $form,
                        callbackOnSuccess : $.extend(
                            SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                            {
                                'onSuccessHandler': function (targetObject, result, loadingTarget) {
                                    if (result.hasOwnProperty('success') && result.success === true) {
                                        if (result.hasOwnProperty('redirect')) {
                                            window.setTimeout(function () {
                                                window.location.href = result.redirect;
                                            }, 50);
                                        }
                                        else if (result.hasOwnProperty('html')) {
                                            if (result.hasOwnProperty('lightbox')) {
                                                let $newElem = $(result.html);
                                                $newElem.appendTo("body");
                                                new Modal($newElem.get(0)).show();
                                                $newElem.get(0).addEventListener('hidden.bs.modal', function(){
                                                    $newElem.remove();
                                                });
                                            }
                                        }
                                        else {
                                            if (result.hasOwnProperty('message')) {
                                                $('#smsTan-form-status')
                                                    .removeClass('is-hidden alert-danger')
                                                    .addClass('alert-success')
                                                    .html(result.message);
                                            }
                                            if(result.hasOwnProperty('type')){
                                                SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                                                    $form.data('formtype'),
                                                    'global',
                                                    '',
                                                    result.type
                                                );
                                            }
                                            $form.find('input, button').not('.btn-close').removeAttr('disabled');
                                        }
                                    }
                                }
                            }
                        ),
                        callbackOnFail : function (jqXHR) {
                            let res = jqXHR.hasOwnProperty('responseJSON') ? jqXHR.responseJSON :
                                jqXHR.hasOwnProperty('responseText') ? SURPLEX.Utils.isJSON(jqXHR.responseText) : jqXHR;
                            if (res.hasOwnProperty('message')) {
                                $('#smsTan-form-status')
                                    .removeClass('is-hidden alert-success')
                                    .addClass('alert-danger')
                                    .html(res.message);
                                if(res.hasOwnProperty('type')){
                                    SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                                        $form.data('formtype'),
                                        'global',
                                        '',
                                        res.type
                                    );
                                }
                            }
                        }
                    }
                )
            });
            SURPLEX.App.uniqueBindEvent('#modal-submit-confirm-cancel', 'click', 'register', function (e) {
               location.reload(true);
            });

            SURPLEX.App.uniqueBindEvent('#modal-submit-confirmDeletingAccountModal', 'click', 'register', function (e) {
                SURPLEX.Ajax.fetch(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        callbackEnd : $.extend(
                            {
                                'delete-account-requested': function (target, data) {
                                    let $modal = $('#confirmDeletingAccountModal');
                                    $modal.find('.modal-footer').remove();
                                    if (data.hasOwnProperty('success') && data.success === true) {
                                        $('#request_for_account_deletion_btn').addClass('btn--disabled')
                                    }
                                    if (data.hasOwnProperty('title')) {
                                        $modal.find('.modal-title').html(data.title);
                                    }
                                    if (data.hasOwnProperty('body')) {
                                        $modal.find('.modal-body').html(data.body);
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
            });


            SURPLEX.App.uniqueBindEvent('.js-address-edit-btn', 'click', 'register', function (e) {
                e.preventDefault();
                SURPLEX.Register.renderModalForm(this);
            });
            SURPLEX.App.uniqueBindEvent('#nps-form .btn-group .btn', 'click', 'register', function (e) {
                let btnGroup = $(this).closest('.btn-group');
                let scoreType = btnGroup.data('score-type');
                btnGroup.find('.btn').removeClass('btn--secondary').addClass('btn--tertiary');
                $(this).removeClass('btn--tertiary').addClass('btn--secondary');
                $('#score-' + scoreType).val($(this).text());
                $(this).closest('form').trigger('change');
            });
            SURPLEX.App.uniqueBindEvent('.js-address-default-btn', 'click', 'register', function () {
                let $btn = $(this);
                let addressId = $btn.data('address-id');
                SURPLEX.Ajax.post(
                    {
                        url: '/?r=Page/address/set-as-primary-billing&addressId='+addressId+'&language='+SURPLEX.App.pageLanguage,
                        callbackStart : {},
                        callbackEnd : $.extend(SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                            {'reloadIfSuccess': function (targetObject, result) {
                                    if (result.hasOwnProperty('success') && result.success === true) {
                                        window.location.reload(true);
                                    }}}
                        ),
                        loadingTarget : $btn.closest('.containerBox')
                    }
                )
            });

            SURPLEX.App.uniqueBindEvent('.js-inquiry-btn', 'click', 'register', function (e) {
                e.preventDefault();
                e.stopPropagation();
                SURPLEX.Track.trackGA("Anfrage", $('meta[name=srxGooglePageType]').attr('content'), 'open');
                SURPLEX.Register.renderInquiry(this);
                return false;
            });

            SURPLEX.App.uniqueBindEvent('#user-register-form[data-formtype=signupStep2] input,#user-register-form[data-formtype=signupStep2]  select', 'change', 'register', function (e) {
                if(SURPLEX.Utils.getCookie('signupStep2Changes') === null || SURPLEX.Utils.getCookie('signupStep2Changes') !== 'stored'){
                    let tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    tomorrow.setHours(0,0,0,0);
                    SURPLEX.Utils.setCookie('signupStep2Changes', 'stored', tomorrow - Date.now()); // next day midnight
                }
            });

            SURPLEX.App.uniqueBindEvent('#confirm-privacy-form .js-modal-confirm', 'click', 'register', function (e) {
                e.preventDefault();
                let $modalForm = $('#confirm-privacy-form');
                let $loginForm = $('#loginform');
                $modalForm.validate({
                    errorElement: "div",
                    errorClass: "is-invalid",
                    validClass: "is-valid",
                    errorPlacement: function (error, element) {
                        if (element.data("error-placement")) {
                            error.appendTo(element.data("error-placement"));
                        } else if (element.data("error-placement-before")) {
                            error.insertBefore(element.data("error-placement-before"));
                        } else {
                            error.insertAfter(element);
                        }
                    },
                    submitHandler: function (form) {
                        if ($($modalForm.data('trigger')).length > 0) {
                            $($modalForm.data('trigger')).attr("disabled", "disabled");
                        }
                        let serializedLoginData = SURPLEX.Register.validator[$loginForm.data('action')]['serialized'];
                        if (serializedLoginData === undefined) {
                            $loginForm = $('#pageloginform');
                            serializedLoginData = SURPLEX.Register.validator[$loginForm.data('action')]['serialized'];
                        }
                        SURPLEX.Ajax.post(
                            {
                                url: SURPLEX.Utils.decodeBase($modalForm.data('action')),
                                data: serializedLoginData + "&" + $modalForm.serialize(),
                                callbackStart : {},
                                callbackEnd : {},
                                loadingTarget : $($modalForm.data('trigger')),
                                callbackOnSuccess : $.extend(
                                    SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                                    {
                                        'onSuccessHandler': function (targetObject, result, loadingTarget) {
                                            if (result.hasOwnProperty('success') && result.success === true) {
                                                window.location.reload(true);
                                            } else {
                                                if (result.hasOwnProperty('html')) {
                                                    $modalForm.find('#confirm-privacy-form-status').removeClass('is-hidden').html(result.html);
                                                }
                                                if ($($modalForm.data('trigger')).length > 0) {
                                                    $($modalForm.data('trigger')).removeAttr("disabled");
                                                }
                                            }
                                        }
                                    }
                                ),
                                callbackOnFail : function (jqXHR, textStatus) {
                                    if ($($modalForm.data('trigger')).length > 0) {
                                        $($modalForm.data('trigger')).removeAttr("disabled");
                                    }
                                }
                            }
                        )
                    }
                });
                $modalForm.trigger('submit', { cancelable: true});
            });

            SURPLEX.App.uniqueBindEvent('#modal-submit-deregister-confirm', 'click', 'register', function (e) {
                e.preventDefault();
                const cc = $(this).data('cc');
                const cc_url = $(this).data('cc-url');

                SURPLEX.Ajax.fetch(
                    {
                        url: cc_url,
                        data: {id: cc},
                        callbackStart : $.extend(
                            SURPLEX.Ajax.getDefaultStartCallbacks(),
                            {
                                'onStartHandler': function (targetObject, result, loadingTarget) {
                                    SURPLEX.Ajax.addLoadingToTarget($('#deregister-confirm .modal-content'));
                                }
                            }
                        ),
                        callbackOnSuccess : $.extend(
                            SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                            {
                                'onSuccessHandler': function (targetObject, result, loadingTarget) {
                                    if (result.hasOwnProperty('success') && result.success === true && result.hasOwnProperty('redirect')) {
                                        window.location.assign(result.redirect);
                                    }
                                }
                            }
                        ),
                        callbackOnFail : function (jqXHR) {
                            let res = jqXHR.hasOwnProperty('responseJSON') ? jqXHR.responseJSON :
                                jqXHR.hasOwnProperty('responseText') ? SURPLEX.Utils.isJSON(jqXHR.responseText) : jqXHR;
                            if (res.hasOwnProperty('message')) {
                                $('#deregister-confirm .modal-body').html(res.message);
                                $('#delete-cc-btn').remove();
                            }
                            SURPLEX.Ajax.removeLoadingToTarget($('#deregister-confirm .modal-content'));
                        }
                    }
                )
            });

            SURPLEX.App.uniqueBindEvent('.js-verify-mobile-number', 'click', 'register', function (e) {
                e.preventDefault();
                SURPLEX.Ajax.fetch(
                    {
                        url: $(this).data('url'),
                        callbackEnd : $.extend(
                            {
                                'onSuccessHandler': function (targetObject, returnedData, loadingTarget) {
                                    if (returnedData.hasOwnProperty('success') && returnedData.success === true) {
                                        if (returnedData.hasOwnProperty('html')) {
                                            if (returnedData.hasOwnProperty('lightbox')) {
                                                let $newElem = $($.trim(returnedData.html));
                                                $newElem.appendTo("body");
                                                new Modal($newElem.get(0)).show();
                                                $newElem.get(0).addEventListener('hidden.bs.modal', function () {
                                                    $newElem.remove();
                                                });
                                                SURPLEX.Register.initialize();
                                                SURPLEX.Track.trackGA('formTracking', $('meta[name=srxGooglePageType]').attr('content'),'modal_openedBy_submit');
                                            }
                                        }
                                    } else {
                                        if (returnedData.hasOwnProperty('toast') && returnedData.toast.hasOwnProperty('title')) {
                                            $.fn.surplexToast({
                                                title: returnedData.toast.title,
                                                content: returnedData.toast.content,
                                                time: returnedData.toast.time
                                            });
                                        }
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
            });
        },

        renderInquiry: function (element) {
            let articleId = $(element).closest('.js-article-watch--item').data('article-id');
            let url = 'inquiry/view';
            let method = 'fetch';
            let additionalData = {};
            if(!SURPLEX.App.getIsGuest()){
                method = 'post';
                url = 'inquiry/create';
                additionalData['User[_si]'] = $(element).data('si');
            }
            SURPLEX.Ajax[method](
                {
                    url: '/?r='+ url +'&articleId=' + articleId+'&language=' + SURPLEX.App.pageLanguage+'&pageType=' + $('meta[name=srxGooglePageType]').attr('content') ,
                    data: additionalData ,
                    callbackStart : {
                        'loading' : function (){
                            $(element).addClass('is-loading');
                        }
                    },
                    callbackOnSuccess : {},
                    callbackEnd : {
                        'renderModal': function (target, res) {
                            if (res.hasOwnProperty('success') && res.hasOwnProperty('lightbox')) {
                                let $retHtml = $($.trim(res.html));
                                $retHtml.appendTo("body");
                                new Modal($retHtml.get(0), { backdrop: 'static' }).show();
                                $retHtml.get(0).addEventListener('hidden.bs.modal', function(e){
                                    $retHtml.remove();
                                });
                                $retHtml.find('select').on('click', function(e){
                                    e.stopPropagation();
                                });
                                if($retHtml.find('.user-form').length > 0){
                                    SURPLEX.Register.initializeFormValidator($retHtml.find('.user-form'));
                                }
                                if (res.hasOwnProperty('tracking')) {
                                    try {
                                        SURPLEX.Track.trackGA(
                                            res.tracking.category ? res.tracking.category : '',
                                            res.tracking.action ? res.tracking.action : '',
                                            res.tracking.label ? res.tracking.label : '',
                                            res.tracking.value ? res.tracking.value : ''
                                        );
                                    } catch (e) {
                                    }
                                }
                                SURPLEX.App.enableDataRedirect();
                                if(res.success === true && !SURPLEX.App.getIsGuest()){
                                    $(element)
                                        .prop('disabled', true);
                                }
                            }
                            $(element).removeClass('is-loading');
                        }
                    },
                    loadingTarget : null
                }
            )
        },

        renderModalForm: function (element) {
            let addressId = $(element).data('address-id');
            let action = $(element).data('action');
            SURPLEX.Ajax.fetch(
                {
                    url: '/',
                    data: {'r': 'Page/address/' + action, 'addressId':addressId,'language':SURPLEX.App.pageLanguage},
                    callbackStart : {},
                    callbackEnd : {
                        'renderModalAddressForm': function (target, res) {
                            if (res.hasOwnProperty('success') && res.success === true && res.hasOwnProperty('lightbox')) {
                                let $retHtml = $(res.html);
                                $retHtml.appendTo("body");
                                new Modal($retHtml.get(0),{ backdrop: 'static' }).show();
                                $retHtml.get(0).addEventListener('hide.bs.modal', function(){
                                    $retHtml.remove();
                                });

                                if($retHtml.find('.user-form').length > 0){
                                    SURPLEX.Register.initializeFormValidator($retHtml.find('.user-form'));
                                }
                            }
                        }
                    },
                    loadingTarget : $(element)
                }
            )
        },

        appendBlueConicInterests: function ($form) {
            if (
                typeof window.blueConicClient !== 'undefined' &&
                typeof window.blueConicClient.event !== 'undefined' &&
                typeof window.blueConicClient.event.subscribe !== 'undefined')
            {
                let profile = blueConicClient.profile.getProfile();
                let cats = profile.getValues('category');
                if (cats.toString().indexOf("38_") > -1) {
                    $form.append($('<input type="hidden" name="' + $form.attr('name') + '[bc_interest_8]" value="1">'));
                }
                if (cats.toString().indexOf("353_") > -1) {
                    $form.append($('<input type="hidden" name="' + $form.attr('name') + '[bc_interest_9]" value="1">'));
                }
                if (cats.toString().indexOf("351_") > -1) {
                    $form.append($('<input type="hidden" name="' + $form.attr('name') + '[bc_interest_4]" value="1">'));
                }
            }
        },

        formSubmited: function(targetObject, returnedData) {
            const $form = targetObject;
            if ($form.find('.js-form-status').length > 0) {
                $form.find('.js-form-status').addClass('is-hidden').html('');
            }

            if (returnedData.hasOwnProperty('success') && returnedData.success === true) {
                SURPLEX.FormValidationErrorTracking.trackSuccess(
                    $form[0]
                );

                if($('#user-register-form[data-formtype=signupStep2]').length > 0){
                    if(SURPLEX.Utils.getCookie('signupStep2Changes') === 'stored'){
                        SURPLEX.Utils.setCookie('signupStep2Changes', 'submitted', -1);
                    }
                }

                if (returnedData.hasOwnProperty('tracking')) {
                    try {
                        SURPLEX.Track.trackGA(
                            returnedData.tracking.category ? returnedData.tracking.category : '',
                            returnedData.tracking.action ? returnedData.tracking.action : '',
                            returnedData.tracking.label ? returnedData.tracking.label : '',
                            returnedData.tracking.value ? returnedData.tracking.value : ''
                        );
                    } catch (e) {
                    }
                }

                if (returnedData.hasOwnProperty('showHint')) {
                    $('#' + returnedData.showHint.disable).addClass('disabled');
                    $('#' + returnedData.showHint.show).removeClass('d-none');
                }

                if (returnedData.hasOwnProperty('vpv')) {
                    SURPLEX.Track.trackVPV(
                        returnedData.vpv.type,
                        returnedData.vpv.url
                    );
                }
                if (returnedData.hasOwnProperty('redirect')) {
                    return window.setTimeout(function () {
                        window.location.href = returnedData.redirect;
                    }, 50);
                }
                if (returnedData.hasOwnProperty('html')) {
                    if (returnedData.hasOwnProperty('replace')) {
                        let $result = $('<div>').html(returnedData.html);
                        if (returnedData.hasOwnProperty('element')) {
                            $(returnedData.element).html(returnedData.html);
                            if(returnedData.element === '#wizard-element-wrapper'){
                                window.scroll({top: 0, left: 0, behavior: 'smooth'});
                            }
                        } else {
                            $form.hide().after($result);
                        }
                        let $modal = $form.closest('.modal');
                        if ($modal.length > 0) {
                            $modal.on('hidden.bs.modal', function () {
                                let $modal = $(this);
                                $result.remove();
                                $form.trigger("reset").show();
                                $form.data('serializedArray').forEach(function (el) {
                                    $form.find('*[name="' + el.name + '"]')
                                        .removeClass('is-invalid is-valid')
                                        .val(el.value);
                                });
                                $modal.off('hidden.bs.modal');
                            });
                        }
                    }
                    else if (returnedData.hasOwnProperty('lightbox')) {
                        let $retHtml = $($.trim(returnedData.html));
                        if (returnedData.hasOwnProperty('autoSubmit')) {
                            $retHtml.appendTo("body");
                            $('#modal-submit-ideal-form')[0].click();
                        } else {
                            let $modal = $form.closest('.modal');
                            if ($modal.length > 0) {
                                let shownModal = Modal.getInstance($modal.get(0));
                                if(shownModal !== null){
                                    shownModal.hide()
                                }
                            }
                            $retHtml.appendTo("body");
                            new Modal($retHtml.get(0)).show();
                            $retHtml.get(0).addEventListener('hide.bs.modal', function(){
                                $retHtml.remove();
                                if ($($form.data('trigger')).length > 0) {
                                    $($form.data('trigger')).removeAttr("disabled");
                                }
                            });
                            if($retHtml.find('.user-form').length > 0){
                                SURPLEX.Register.initializeFormValidator($retHtml.find('.user-form'));
                            }
                        }
                    }
                    else {
                        $form.find('.js-form-status').removeClass('is-hidden').html(returnedData.html);
                    }
                    if ($($form.data('trigger')).length > 0) {
                        $($form.data('trigger')).removeAttr("disabled");
                    }
                }
                if (returnedData.hasOwnProperty('reload') && returnedData.reload !== null) {
                    window.setTimeout( function () {window.location.reload(true)}, parseInt(returnedData.reload, 10));
                }

                if ($form.is('#user-seller-form')) {
                    $form.find('#seller-concern').val('');
                    $form.find('#upload_widget_images').html('');
                    $form.find('#upload_widget').removeAttr('disabled');
                }
            } else {
                if ($($form.data('trigger')).length > 0) {
                    $($form.data('trigger')).removeAttr("disabled");
                }
                if (returnedData.hasOwnProperty('status') && returnedData.status >= 500) {
                    SURPLEX.Register.validator[$form.data('action')].resetForm();
                }
                if (returnedData.hasOwnProperty('html')) {
                    $form.find('.js-form-status').removeClass('is-hidden').html(returnedData.html);

                    if (returnedData.hasOwnProperty('directTrackMessage') && returnedData.directTrackMessage !== null) {
                        let directTrackElement;
                        if (returnedData.hasOwnProperty('directTrackElement') && returnedData.directTrackElement !== null) {
                            directTrackElement = returnedData.directTrackElement;
                        } else {
                            directTrackElement = 'global';
                        }
                        if (returnedData.hasOwnProperty('source') && returnedData.source !== null){
                            SURPLEX.FormValidationErrorTracking.errorMapping.newsletterFooter.msgUniqueEmail['*'] =  'alreadyRegisteredEmail_' + returnedData.source;
                            SURPLEX.FormValidationErrorTracking.errorMapping.signupStep1.msgRemote['User[email]'] =  'alreadyRegisteredEmail_' + returnedData.source;
                        }
                        SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                            $form.data('formtype'),
                            directTrackElement,
                            returnedData.directTrackMessage
                        );
                    }
                } else {
                    const resultState = returnedData.hasOwnProperty('responseJSON') ? returnedData.responseJSON :
                        returnedData.hasOwnProperty('responseText') ? SURPLEX.Utils.isJSON(returnedData.responseText) : returnedData;
                    try {
                        if (resultState.hasOwnProperty('errors') && !$.isEmptyObject(resultState['errors'])) {
                            $.each(resultState.errors, function (field, fieldmessage) {
                                let errObj = {};
                                errObj[$form.attr('name') + '[' + field + ']'] = fieldmessage;
                                SURPLEX.Register.validator[$form.data('action')].showErrors(errObj);
                            });
                            SURPLEX.Register.validator[$form.data('action')].focusInvalid();
                        } else {
                            if (resultState.hasOwnProperty('message')) {
                                if ($form.find('.js-form-status').length > 0) {
                                    $form.find('.js-form-status')
                                        .removeClass('is-hidden alert-success')
                                        .addClass('alert-danger')
                                        .html(
                                            '<div class="icon__info"></div><div>' + resultState.message + '</div>'
                                        );
                                } else {
                                    $form.append($('<div>')
                                        .attr('class','js-form-status form-group alert alert-danger text-danger login--hint font-weight-bold')
                                        .html(
                                            '<div class="icon__info"></div><div>' + resultState.message + '</div>'
                                        )
                                    );
                                }
                                if($form.is('#smstan')){
                                    SURPLEX.Register.validator[$form.data('action')].resetForm();
                                    $form.find('input, button').not('.btn-close').attr("disabled", "disabled");
                                }
                            }
                            SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                                $form.data('formtype'),
                                'global',
                                '',
                                (resultState.hasOwnProperty('type') ? resultState.type : 'Exception')
                            );
                        }

                    } catch (e) {
                        if ($form.find('.js-form-status').length > 0) {
                            $form.find('.js-form-status').removeClass('is-hidden').addClass('alert alert-primary').html(
                                '<div class="icon__info"></div><div>' + e.toString() + '</div>'
                            );
                        } else {
                            $form.append($('<div>')
                                .attr('class', 'js-form-status alert alert-primary')
                                .html(
                                    '<div class="icon__info"></div><div>' + e.toString() + '</div>'
                                )
                            );
                        }
                        SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                            $form.data('formtype'),
                            'global',
                            '',
                            e.toString()
                        );
                    }
                }
            }
        }
    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.Register);
    }
})($, SURPLEX);
