import $ from "./cashwrapper";
;(function ($, SURPLEX, undefined) {
    SURPLEX.Filter = $.extend({}, {
        filterId: '#srx-filter',
        seoElem: '.categorie_werbetext',
        autocomplete: null,
        initialize: function () {
            if (!this.isInitialized()) {
                this.enableListeners();
            }
        },
        isInitialized: function () {
            const mainElement = $(this.filterId);
            if (typeof mainElement !== 'undefined') {
                const isInitialized = mainElement.data('initialized');
                if (typeof isInitialized !== 'undefined') {
                    return isInitialized === 'true'
                }
            }
            return false;
        },
        setInitialized: function () {
            const mainElement = $(SURPLEX.Filter.filterId);
            mainElement.on('keyup keypress', function(e) {
                let keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                    return false;
                }
            });

            mainElement.find('input[indeterminate]')
                .removeAttr('indeterminate')
                .prop({indeterminate : true});

            if (typeof mainElement !== 'undefined') {
                mainElement.data('initialized', 'true');
            }
        },

        fillInAddress: function () {
            let place = SURPLEX.Filter.autocomplete.getPlace();
            let address = {city: "", state: "", country: "", zipcode: ""};

            place.address_components.forEach(comp => {
                let types = comp.types;
                if (types.includes("administrative_area_level_1")) address.state = comp.long_name;
                if (types.includes("locality")) address.city = comp.long_name;
                if (types.includes("postal_code")) address.zipcode = comp.long_name;
                if (types.includes("country")) address.country = comp.long_name;
            });

            document.getElementById("radius-place").value = `${address.city}, ${address.country}`;
            document.getElementById("radius-from").value = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
            if (document.getElementById("radius-to").selectedIndex === 0) {
                document.getElementById("radius-to").selectedIndex = 1;
            }
        },

        getUsersLocation: function () {
            let $gInputField = $('#radius-place');
            if (!$gInputField.length) return;

            try {
                if (!SURPLEX.Filter.autocomplete) {
                    SURPLEX.Filter.autocomplete = new google.maps.places.Autocomplete($gInputField[0], {types: ['geocode']});
                }
                SURPLEX.Filter.autocomplete.addListener('place_changed', SURPLEX.Filter.fillInAddress);
                $gInputField.on('keydown', e => {
                    if (e.which === 13) return false;
                });
            } catch (e) {
                console.log(e);
            }
        },

        loadGoogleMapsApi: function (callback) {
            if (typeof google !== 'object' || typeof google.maps !== 'object') {
                let $elmRadiusFilter = $('#radius-place');
                let gmapParam = [
                    `key=${$elmRadiusFilter.data("gpk")}`,
                    `language=${$elmRadiusFilter.data("language")}`,
                    'libraries=places',
                    `callback=${callback}`
                ];
                $.getScript(`https://maps.googleapis.com/maps/api/js?${gmapParam.join("&")}`);
            } else if (callback) {
                callback();
            }
        },

        enableListeners: function () {
            SURPLEX.App.uniqueBindEvent('#filterCollapse', 'show.bs.collapse', 'filter', function (e) {
                if ($(this).is(e.target)) {
                    document.body.classList.add('mm-body--open');
                }
            });
            SURPLEX.App.uniqueBindEvent('#filterCollapse', 'hide.bs.collapse', 'filter', function (e) {
                if ($(this).is(e.target)) {
                    document.body.classList.remove('mm-body--open');
                }
            });

            SURPLEX.App.uniqueBindEvent('#radius-place', 'click', 'filter', function (e) {
                if(typeof google !== 'object' || typeof google.maps !== 'object') {
                    SURPLEX.Filter.loadGoogleMapsApi('SURPLEX.Filter.getUsersLocation');
                } else {
                    SURPLEX.Filter.getUsersLocation();
                }
            });

            if (navigator.geolocation) {
                try {
                    navigator.permissions.query({ name: "geolocation" }).then((result) => {
                        result.addEventListener("change", () => {
                            if(result.state === "granted"){
                                $('#geolocation').get(0).click();
                            }
                        });
                    });
                } catch (e) {}

                SURPLEX.App.uniqueBindEvent('#geolocation', 'click', 'filter', function (e) {
                    let $locationBtn = $(this).closest('.filter__dropdownInner').addClass('is-loading');
                    navigator.geolocation.getCurrentPosition(
                        position => updateLocation(position.coords.latitude, position.coords.longitude),
                        showError
                    );

                    function updateLocation(latitude, longitude) {
                        document.getElementById("radius-from").value = `${latitude},${longitude}`;
                        const latlng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

                        function getNameForGeo() {
                            new google.maps.Geocoder().geocode({ location: latlng })
                                .then(response => {
                                    if (response.results[0]) {
                                        let components = response.results[0].address_components;
                                        let address = {
                                            city: components.find(c => c.types.includes("locality"))?.long_name || "",
                                            country: components.find(c => c.types.includes("country"))?.long_name || ""
                                        };
                                        document.getElementById("radius-place").value = `${address.city}, ${address.country}`;
                                    }
                                    finalize();
                                }).catch(finalize);
                        }

                        function finalize() {
                            $locationBtn.removeClass('is-loading');
                            let radiusTo = document.getElementById("radius-to");
                            if (radiusTo.selectedIndex === 0) radiusTo.selectedIndex = 1;
                        }

                        if (typeof google !== 'object' || typeof google.maps !== 'object') {
                            let $elmRadiusFilter = $('#radius-place');
                            let gmapParam = [
                                `key=${$elmRadiusFilter.data("gpk")}`,
                                `language=${$elmRadiusFilter.data("language")}`,
                                'libraries=places'
                            ];
                            $.getScript(`https://maps.googleapis.com/maps/api/js?${gmapParam.join("&")}`, getNameForGeo);
                        } else {
                            getNameForGeo();
                        }
                    }

                    function showError(error) {
                        $locationBtn.removeClass('is-loading');
                        $.fn.surplexToast({
                            title: "Ooo-oops",
                            content: $('#geolocation').data('geolocation-error')[error.code] || "An error has occurred. Please try again later.",
                            status: 'error'
                        });
                    }
                });
            } else {
                $('#geolocation').remove();
            }


            $(SURPLEX.Filter.filterId + ' .js-clear-filter').off('click').on('click', function (e) {
                let $dropDown = $(this).closest('.filter__dropdown');
                if($dropDown.length < 1){
                    $dropDown = $(this).closest('.btn__filter').next('.filter__dropdown');
                }
                let frm_elements = $dropDown.find('input, select');
                for (let i = 0; i < frm_elements.length; i++) {
                    let field_type = frm_elements[i].type.toLowerCase();
                    switch (field_type) {
                        case "radio":
                        case "checkbox":
                            if (frm_elements[i].checked || frm_elements[i].indeterminate) {
                                frm_elements[i].checked = false;
                                frm_elements[i].indeterminate = false;
                            }
                            break;
                        case "select-one":
                        case "select-multi":
                            frm_elements[i].selectedIndex = 0;
                            break;
                        default:
                            frm_elements[i].value = "";
                            break;
                    }
                }
                let $dropDownOppener = $('#' + $dropDown.attr('aria-labelledby'));
                if($dropDownOppener.length > 0){
                    $dropDownOppener.removeClass('btn__filter--active')
                        .contents().first()
                        .get(0).textContent = $dropDownOppener.attr('title');
                }
                SURPLEX.Filter.fireSearch();
            });


            $(SURPLEX.Filter.filterId + ' input[type="checkbox"]').off('click').on('click', function (e) {
                SURPLEX.Filter.triggerOptionChecked(e, $(this));
            });
            SURPLEX.App.uniqueBindEvent('.interval-filter-btn', 'click', 'filter', function (e) {
                let filterName = $(this).data('filter-name');
                collectRangeFilter(filterName);
            });
            SURPLEX.App.uniqueBindEvent('.filter__section input[type="tel"]', 'keyup', 'filter', function (e) {
                let $inputField = $(this);
                $inputField.val($inputField.val().replace(/\D/g, ''));
                let keyCode = e.keyCode || e.which;
                if (keyCode !== 13) {
                    e.preventDefault();
                    return false;
                }
                let filterName = $inputField.attr('data-filter-label');
                collectRangeFilter(filterName);

            });
            SURPLEX.App.uniqueBindEvent('.js-filter-wrap .tagbar .tag .icon-cancel', 'click', 'filter', function (e) {
                if ($(this).data('mode') === 'interval') {
                    SURPLEX.Filter.resetIntervalFilter($(this).data('name'), $(this).data('option'));
                } else {
                    SURPLEX.Filter.removeTag($(this).data('name'), $(this).data('option'));
                }
            });

            SURPLEX.App.uniqueBindEvent('.js-refresh-filter', 'click', 'filter', function (e) {
                $(SURPLEX.Filter.filterId).find("input, select, textarea, button").filter(":not([value=''])")
                    .attr('value', '').val('').attr('value', '')
                    .attr('checked', null).attr('selected', null);
                $(SURPLEX.Filter.filterId).find('.btn__filter').each(function () {
                    $(this).removeClass('btn__filter--active')
                        .contents().first()
                        .get(0).textContent = $(this).attr('title');
                });
                SURPLEX.Filter.collectFilter(false, '');
            });

            if(!SURPLEX.Utils.isTouchDevice()){
                SURPLEX.App.uniqueBindEvent('.filterDd-inputs','shown.bs.dropdown', 'filter', function (e) {
                    let $inputField = $(this).find('input[type="number"]').first();
                    if($inputField.length === 1){
                        $inputField.get(0).click();
                        $inputField.get(0).focus();
                    }
                });
            }

            this.setInitialized();

            function collectRangeFilter(filterName){
                let sendEvent = false;
                if (filterName.length > 0) {
                    let $inputFrom = $('[name="filter[' + filterName + '][from]"]');
                    let $inputTo = $('[name="filter[' + filterName + '][to]"]');
                    if($inputFrom.length < 1 || $inputTo.length < 1){
                        return;
                    }
                    $inputFrom.attr('value', $inputFrom.val());
                    $inputTo.attr('value', $inputTo.val());

                    let $dropDown = $inputFrom.closest('.filter__dropdown');
                    let $dropDownOppener = $('#' + $dropDown.attr('aria-labelledby'));

                    if ($inputFrom.val() !== '' || $inputTo.val() !== '') {
                        sendEvent = true;
                        filterName += '_' + ($inputFrom.val() !== '' ? $inputFrom.val().toString() : 'null')
                            + '_' + ($inputTo.val() !== '' ? $inputTo.val().toString() : 'null');

                        let label = '' ;
                        if($inputFrom.val() !== ''){
                            label += $inputFrom.attr('data-button-label') + ' ' + ( $($inputFrom.data('labelledby')).val() || $inputFrom.val()) + ' ' + $inputFrom.attr('data-unit') + ' ';
                        }
                        if($inputTo.val() !== ''){
                            label += $inputTo.attr('data-button-label') + ($inputTo.is('#radius-to') ? ' ' : ' ') + ( $($inputTo.data('labelledby')).val() || $inputTo.val()) + ' ' + $inputTo.attr('data-unit') + ($inputTo.is('#radius-to') ? '' : '') ;
                        }
                        $dropDownOppener
                            .addClass('btn__filter--active')
                            .contents().first()
                            .get(0).textContent = label;
                    } else {
                        $dropDownOppener
                            .removeClass('btn__filter--active')
                            .contents().first()
                            .get(0).textContent = $dropDownOppener.attr('title');
                    }
                }
                SURPLEX.Filter.collectFilter(sendEvent, filterName);
            }

            if (this.getCurrentFilter() !== '') {
                this.removeSeo();
            }
        },

        collectFilter: function (trackEvent, eventLabel) {
            if (trackEvent) {
                SURPLEX.Filter.trackFilter('selection_' + eventLabel);
            }
            SURPLEX.Filter.fireSearch();
        },

        trackFilter: function (eventLabel) {
            SURPLEX.Track.trackGA(
                'filterTracking',
                $('meta[name=srxGooglePageType]').attr('content'),
                eventLabel
            );
            if (typeof hj === 'function') {
                let userId = SURPLEX.Track.getDataFromLayer('dataLayer', 'userId');
                if (typeof userId !== 'number' || (userId % 1) !== 0) {
                    userId = null;
                }
                hj('identify', userId, {
                    ['filter-used']: eventLabel
                });
            }
        },

        triggerOptionChecked: function (e, $option) {
            let checked = $option.is(':checked');
            if ($option.data('select-once') === true) {
                $option.closest('form').find('input[name="' + $option.attr('name') + '"]').prop('checked', false);
                if (checked) {
                    $option.prop('checked', true);
                }
            }
            if ($option.is('.js-ignoreClick') || $option.is('.js-subSelect')) {
                if ($option.is('.js-subSelect')) {
                    let $controlOption = $option.closest('.js-filter__listItem').find('.js-ignoreClick');
                    $controlOption.prop('checked', checked);
                    e.stopPropagation();
                } else if ($option.is('.js-ignoreClick')) {
                    let $controlOption = $option.closest('.js-filter__listItem').find('.js-subSelect');
                    let $uncheckedSubitems = $option.closest('.filter__list').find('input[type="checkbox"]:not(:checked)');
                    let $checkedSubitems = $option.closest('.filter__list').find('input[type="checkbox"]:checked');
                    if (checked && (!$controlOption.is(':checked') || $controlOption.prop('indeterminate') === false)) {
                        if ($uncheckedSubitems.length === 0) {
                            $controlOption.prop({
                                checked : true,
                                indeterminate : false
                            });
                        } else {
                            $controlOption.prop({
                                checked : false,
                                indeterminate : true
                            });
                        }
                    } else if ($controlOption.is(':checked') || $controlOption.prop('indeterminate') === true) {
                        if ($uncheckedSubitems.length === 0) {
                            $controlOption.prop({
                                checked : false,
                                indeterminate : false
                            });
                        } else {
                            $controlOption.prop({
                                checked : false,
                                indeterminate : ($checkedSubitems.length > 0)
                            });
                        }
                    }
                }
            }
            let $dropDown = $option.closest('.filter__dropdown');
            let $dropDownOppener = $('#' + $dropDown.attr('aria-labelledby'));
            if ($dropDownOppener.length > 0) {
                let $checkedElm = $dropDown.find('input:checked, input:indeterminate').not('.js-ignoreClick');
                let elmCnt = $checkedElm.length;
                if (elmCnt > 0) {
                    $dropDownOppener.addClass('btn__filter--active').contents().first()
                        .get(0).textContent = $checkedElm.attr('data-button-label') + (elmCnt > 1 ? ' +' + (elmCnt - 1) : '');
                } else {
                    $dropDownOppener.removeClass('btn__filter--active').contents().first()
                        .get(0).textContent = $dropDownOppener.attr('title');
                }
            }
            SURPLEX.Filter.collectFilter($option.is(':checked'), $option.attr('data-filter-label'));
        },

        getCurrentFilter: function () {
            let notEmptyFields = $(SURPLEX.Filter.filterId).find('input, select').not('input[data-value-mode=list]').serializeArray().filter(function (elm) {
                return elm.value !== '';
            });
            let filterVal = ''
            if (notEmptyFields.length > 0) {
                for (let i = 0; i < notEmptyFields.length; i++) {
                    /*filterVal += '&' + notEmptyFields[i].name + '=' + notEmptyFields[i].value; // @TODO check what is nicer */
                    filterVal += '&' + notEmptyFields[i].name + '=' + encodeURIComponent(notEmptyFields[i].value).replace(/%20/g,'+');
                }
                filterVal = filterVal.substring(1);
            }

            let notEmptyListValueFields = $(SURPLEX.Filter.filterId).find('input[data-value-mode=list]').serializeArray().filter(function (elm) {
                return elm.value !== '';
            });

            let filters = {};
            if (notEmptyListValueFields.length > 0) {
                for (let i = 0; i < notEmptyListValueFields.length; i++) {
                    if (filters.hasOwnProperty(notEmptyListValueFields[i].name) === false) {
                        filters[notEmptyListValueFields[i].name] = [];
                    }
                    if (filters[notEmptyListValueFields[i].name].includes('*') === false) {
                        filters[notEmptyListValueFields[i].name].push(notEmptyListValueFields[i].value);
                    }
                }
                let values = '';
                if (Object.keys(filters).length !== 0) {
                    for (const key in filters) {
                        values += '&' + key + '=' + filters[key].join(',');
                    }
                }
                if (filterVal === '') {
                    filterVal = values.substring(1);
                } else {
                    filterVal += values;
                }
            }

            return filterVal;
        },

        fireSearch: function () {
            let $filterForm = $(SURPLEX.Filter.filterId);
            let filterVal =  SURPLEX.Filter.getCurrentFilter();
            let $sorter = $('.js-sorter' + $filterForm.attr('data-sort-param') + '[data-initialized]').first();

            if (typeof (window.history.pushState) === 'function') {
                if ($sorter.length && $sorter.attr('data-current-sort') !== undefined && $sorter.attr('data-current-sort') !== '') {
                    filterVal = $filterForm.data('sort-param') + '=' + $sorter.attr('data-current-sort') + (filterVal !== '' ? '&' + filterVal : '');
                }
                let filterParams = SURPLEX.Utils.rtrim(filterVal, '&');
                let $refreshBtn = $('.js-refresh-filter');
                if (filterParams === '') {
                    if (!$refreshBtn.hasClass('d-none')) {
                        $refreshBtn.addClass('d-none');
                    }
                } else {
                    if ($refreshBtn.hasClass('d-none')) {
                        $refreshBtn.removeClass('d-none');
                    }
                }

                SURPLEX.Ajax.fetch(
                    {
                        url: SURPLEX.Utils.decodeBase($filterForm.data('filter-url')),
                        data: filterParams,
                        callbackStart : $.extend({'lockFilter': this.lockFilter}, SURPLEX.Ajax.getDefaultStartCallbacks()),
                        callbackEnd : $.extend({
                                'unlockFilter': this.unlockFilter,
                                'wrapResult': function (target, data) {
                                    if (data.hasOwnProperty('filter')) {
                                        $.each(data.filter, function (key, filter) {
                                            let $filterElement = $('#filter-'+key);
                                            let $filterDropdown = $filterElement.closest('.filter__dropdown');
                                            if (!$filterDropdown.hasClass('show') || $filterDropdown.hasClass('js-filter-combined')) {
                                                $filterElement.html(filter);
                                                $filterElement.find('input[indeterminate]').removeAttr('indeterminate').prop({indeterminate : true});
                                            }
                                        });
                                        $(SURPLEX.Filter.filterId).data('initialized', 'undefined');
                                    }
                                    if (data.hasOwnProperty('count')) {
                                        let countSelector = '.js-counter--digit';
                                        if (data.hasOwnProperty('countSelector')) {
                                            countSelector = data.countSelector;
                                        }

                                        $(countSelector).text(data.count);
                                        if (parseInt(data.count) === 0) {
                                            SURPLEX.Track.trackGA(
                                                'filterTracking',
                                                $('meta[name=srxGooglePageType]').attr('content'),
                                                'selection_noResult'
                                            );
                                        }
                                    }
                                    if (data.hasOwnProperty('html') && typeof data.html === 'object') {
                                        $.each(data.html, function (key, element) {
                                            let $elementToReplace = $(key);
                                            if ($elementToReplace.length) {
                                                $elementToReplace.replaceWith(element);
                                            }
                                        });
                                    }
                                    if (data.hasOwnProperty('js') && typeof data.js === 'object') {
                                        $.each(data.js, function (key, val) {
                                            window[key] = SURPLEX.Utils.isJSON(val);
                                            if (key === 'dataLayerArticleList' && typeof dataLayer !== "undefined") {
                                                dataLayer.push(window[key]);
                                            }
                                        });
                                    }
                                    SURPLEX.Filter.removeSeo();
                                    if(SURPLEX.Utils.isMobileViewport()){
                                        window.scroll({top: 0, left: 0, behavior: 'smooth'});
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()),
                        loadingTarget : $('.js-article-list, #filterCollapse .js-counter--digit')
                    }
                );
                history.replaceState(null, null, window.location.pathname + (filterVal !== '' ? '?' : '') + filterVal);
            } else {
                window.location.href = window.location.pathname + (filterVal !== '' ? '?' : '') + filterVal;
            }
        },
        lockFilter: function () {
            $(SURPLEX.Filter.filterId).find('input, select, textarea, button').not('.disabled').each(function () {
                $(this).data('locked', 'true').attr('disabled', 'true');
            });
        },
        unlockFilter: function () {
            $(SURPLEX.Filter.filterId).find('input, select, textarea, button').each(function () {
                if ($(this).data('locked') == 'true') {
                    $(this).attr('disabled', null).data('locked', 'false');
                }
            });
            $.each(['moreLocations', 'moreManufacturer', 'moreCategories'], function (k, v) {
                if(v in window && window[v] !== null){
                    window[v] = null;
                }
            });
        },
        resetIntervalFilter: function (name, option) {
            let $option = $('#' + name + '-' + option);
            $option.attr('value', '').val('');
            SURPLEX.Filter.collectFilter(false, '');
        },
        removeTag: function (name, option) {
            if (name === 'location') {
                $('#dropdownlocation-l').closest('.dropdown').trigger('show.bs.dropdown');
            }
            let $option = $('#' + name + '-' + option);
            if ($option.is(':disabled')) {
                $option.attr( "disabled", null );
            }
            $option.get(0).click();
        },
        removeSeo: function () {
            $(this.seoElem).remove();
        }
    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.Filter);
    }
})($, SURPLEX, undefined);
