import $ from "./cashwrapper";
;(function ($, SURPLEX) {
    SURPLEX.WebSocket = $.extend({}, {
        socket: null,
        message_types: {
            ACK: 0,
            REGISTER_FOR_ARTICLE_UPDATES: 10,
            TOAST: 100,
            ALERT: 200,
            DIALOG: 300,
            FUNC: 400
        },
        func_types: {
            ARTICLE_CHANGE: 'article_change',
        },
        initializeLoadEvents: function () {
            if (!SURPLEX.App.isGuest || SURPLEX.App.getArticleUpdates) {
                this._startWebSockets();
            }
        },

        enableListeners: function () {
            SURPLEX.App.getArticleUpdates = SURPLEX.App.registerForArticleUpdates();
            if (SURPLEX.WebSocket.socket === null) {
                SURPLEX.WebSocket._startWebSockets();
            } else {
                SURPLEX.WebSocket.setArticleUpdateTrigger();
            }
        },

        setArticleUpdateTrigger: function () {
            let socket = SURPLEX.WebSocket.socket;
            if (socket.readyState === socket.OPEN && SURPLEX.App.getArticleUpdates) {
                let articleIds = [];
                let $article = $('.js-machine--details.js-article--item').first();
                if($article.length > 0){
                    articleIds.push($article.data('article-id'));
                }
                $(".js-article-watch--item[data-article-id] .js-end-date[data-initialized]").each(function () {
                    let articleId = $(this).closest('.js-article-watch--item[data-article-id]').data('article-id');
                    if (articleIds.indexOf(articleId) === -1) {
                        articleIds.push(articleId);
                    }
                });
                if (articleIds.length > 0) {
                    socket.send(JSON.stringify({
                        "type": SURPLEX.WebSocket.message_types.REGISTER_FOR_ARTICLE_UPDATES,
                        "data": {
                            "article_ids": articleIds
                        }
                    }));
                }
            }
        },



        getWebSocket: function (host) {
            try {
                let socket = null;
                if ('WebSocket' in window) {
                    socket = new WebSocket(host)
                } else if ('MozWebSocket' in window) {
                    socket = new MozWebSocket(host)
                }
                return socket
            } catch (e) {
                return null;
            }

        },

        _startWebSockets: function () {
            if (SURPLEX.WebSocket.socket === null) {
                SURPLEX.WebSocket.socket = this.getWebSocket('wss://' + window.location.host + '/websocket/');
            }
            let socket = SURPLEX.WebSocket.socket;
            if (socket == null) {
                return;
            }

            try {
                socket.onopen = function (e) {
                   SURPLEX.WebSocket.setArticleUpdateTrigger();
                };
                try {
                    socket.onmessage = function (msg) {
                        let message = SURPLEX.Utils.isJSON(msg.data);
                        if (message === false) {
                            SURPLEX.Track.trackGA("websocket", 'onmessage', msg.stringify());
                            return;
                        }
                        let socketMsg = message.data;
                        switch (message.type) {
                            case SURPLEX.WebSocket.message_types.TOAST:
                                $.fn.surplexToast({
                                    title: socketMsg.title,
                                    time: socketMsg.time,
                                    content: socketMsg.content,
                                    status: socketMsg.status,
                                    id: socketMsg.foreign_uid + '-' + SURPLEX.WebSocket.message_types.TOAST
                                });
                                SURPLEX.Track.trackGA("push notification", "shown", 'toast');
                                break;
                            case SURPLEX.WebSocket.message_types.DIALOG:
                                $.fn.surplexModal({
                                    title: socketMsg.title,
                                    content: socketMsg.content
                                });
                                break;
                            case SURPLEX.WebSocket.message_types.ALERT:
                                $.fn.surplexAlert(socketMsg.content);
                                break;
                            case SURPLEX.WebSocket.message_types.FUNC:
                                if(socketMsg.type === SURPLEX.WebSocket.func_types.ARTICLE_CHANGE && SURPLEX.App.getArticleUpdates) {
                                    if(SURPLEX.Bid.lastBidResult === null){
                                        let articleId = $('.js-article--item').first().data('article-id');
                                        const $articleList = $('.js-article-watch--item[data-article-id="' + socketMsg.articleId + '"]:not(.js-machine--details)');
                                        if (parseInt(articleId) === parseInt(socketMsg.articleId)){
                                            SURPLEX.Bid.updateBidBox();
                                        }
                                        if($articleList.length > 0){
                                            $articleList.trigger('refresh');
                                        }
                                    }
                                    SURPLEX.Bid.lastBidResult = null;
                                }
                                break;
                            default:
                                break;
                        }

                        socket.send(JSON.stringify({
                            "type": SURPLEX.WebSocket.message_types.ACK,
                            "data": {
                                "message_id": message.id,
                                "hash": message.hash
                            }
                        }));
                    };
                } catch (e) {
                    SURPLEX.Track.trackGA("websocket", 'onmessage', e.message);
                }
                socket.onerror = function (e) {
                    SURPLEX.Track.trackGA("websocket", 'onerror', e.code);
                };
                socket.onclose = function (e) {
                    SURPLEX.Track.trackGA("websocket", 'onclose', e.code + e.reason);
                };
            } catch (e) {
                SURPLEX.Track.trackGA("websocket", 'onopen', e.message);
            }
        },
    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.WebSocket);
    }
})($, SURPLEX);