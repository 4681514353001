import $ from "./cashwrapper";
;(function ($, SURPLEX) {
    SURPLEX.FormValidationErrorTracking = $.extend({}, {
        formErrorHasActivated: {},
        formErrors: {},
        errorMapping: {
            newsletterFooter: {
                msgRequired: {
                    'footer-newsletter[consent]': 'privacyPolicyNotSelected',
                    '*': 'requiredFieldForgotten'
                },
                msgEmail: {
                    '*': 'invalidEmail'
                },
                msgUniqueEmail: {
                    '*': 'alreadyRegisteredEmail'
                }
            },
            bcNewsletterCategoryModal : {
                msgRequired: {
                    'User[email]': 'requiredFieldForgotten',
                    '*': 'requiredFieldForgotten'
                },
                msgEmail: {
                    '*': 'invalidEmail'
                }
            },
            newsletterCategoryModal: {
                msgRequired: {
                    'User[privacyCheck]': 'privacyPolicyNotSelected',
                    'User[email]': 'requiredFieldForgotten',
                    '*': 'requiredFieldForgotten'
                },
                msgEmailnewsletter: {
                    '*': 'invalidEmail'
                },
                msgUniqueEmail: {
                    '*': 'alreadyRegisteredEmail'
                }
            },
            signupStep1: {
                msgRequired: {
                    'User[branche][]': 'interestNotSelected',
                    '*': 'requiredFieldForgotten'
                },
                msgMinonechar: {
                    '*': 'nonAlphaNumericEntered'
                },
                msgRemote: {
                    'User[first_name]': 'pleaseCheckTransliteration',
                    'User[last_name]': 'pleaseCheckTransliteration',
                    'User[email]': 'alreadyRegisteredEmail'
                },
                msgMaxlength: {
                    '*': 'maxCharactersExceeded'
                },
                msgEmail: {
                    '*': 'invalidEmail'
                }
            },
            editProfile: {
                msgRequired: {
                    'User[branche][]': 'interestNotSelected',
                    'User[password]': 'passwordTooShort',
                    'User[password_repeat]': 'passwordNotMatching',
                    'User[country]': 'selectionForgotten',
                    'User[country_ustid]': 'emptyVatCountryCodeSelection',
                    '*': 'requiredFieldForgotten'
                },
                msgEqualto: {
                    'User[password_repeat]': 'passwordNotMatching'
                },
                msgMinonechar: {
                    '*': 'nonAlphaNumericEntered'
                },
                msgMinonedigit: {
                    '*': 'withoutDigitsEntered'
                },
                msgRemote: {
                    'User[first_name]': 'pleaseCheckTransliteration',
                    'User[last_name]': 'pleaseCheckTransliteration',
                    'User[email]': 'alreadyRegisteredEmail',
                    'User[company]': 'pleaseCheckTransliteration',
                    'User[address]': 'pleaseCheckTransliteration',
                    'User[zip]': 'pleaseCheckTransliteration',
                    'User[city]': 'pleaseCheckTransliteration'
                },
                msgMinlength: {
                    'User[password]': 'passwordTooShort'
                },
                msgMaxlength: {
                    '*': 'maxCharactersExceeded'
                },
                msgEmail: {
                    '*': 'invalidEmail'
                },
                msgPhone: {
                    '*': 'nonNumericEntered'
                },
                msgRequireboth: {
                    '*': 'requiredFieldForgotten'
                },
                msgVaterror: {
                    'User[ustid]': 'vatError'
                }
            },
            signupStep2: {
                msgRequired: {
                    'User[password]': 'passwordTooShort',
                    'User[password_repeat]': 'passwordNotMatching',
                    'User[country]': 'selectionForgotten',
                    'User[country_ustid]': 'emptyVatCountryCodeSelection',
                    '*': 'requiredFieldForgotten'
                },
                msgMinlength: {
                    'User[password]': 'passwordTooShort'
                },
                msgMaxlength: {
                    '*': 'maxCharactersExceeded'
                },
                msgEqualto: {
                    'User[password_repeat]': 'passwordNotMatching'
                },
                msgMinonechar: {
                    '*': 'nonAlphaNumericEntered'
                },
                msgMinonedigit: {
                    '*': 'withoutDigitsEntered'
                },
                msgRemote: {
                    'User[company]': 'pleaseCheckTransliteration',
                    'User[address]': 'pleaseCheckTransliteration',
                    'User[zip]': 'pleaseCheckTransliteration',
                    'User[city]': 'pleaseCheckTransliteration'
                },
                msgVaterror: {
                    'User[ustid]': 'vatError'
                },
                msgPhone: {
                    '*': 'nonNumericEntered'
                },
                msgRequireboth: {
                    '*': 'requiredFieldForgotten'
                },
                msgRecaptcha: {
                    'hidden-grecaptcha': 'noRobotVerification'
                }
            },
            smsModal: {
                msgRequired: {
                    '*': 'requiredFieldForgotten'
                },
                msgNumber: {
                    '*': 'nonNumericEntered'
                },
                msgInvalid: {
                    '*': 'smsNotValid'
                },
                msgExpired: {
                    '*': 'smsExpired'
                },
                smsResent: {
                    'smsResent': 'smsResent'
                }
            },
            loginModal: {
                msgRequired: {
                    '*': 'requiredFieldForgotten'
                },
            },
            ssaRegister: {
                msgRequired: {
                    '*': 'requiredFieldForgotten'
                },
                msgMinonechar: {
                    '*': 'nonAlphaNumericEntered'
                },
                msgRemote: {
                    'User[email]': 'alreadyRegisteredEmail',
                    '*': 'pleaseCheckTransliteration'
                },
                msgMaxlength: {
                    '*': 'maxCharactersExceeded'
                },
                msgEmail: {
                    '*': 'invalidEmail'
                },
                msgMinonedigit: {
                    '*': 'withoutDigitsEntered'
                },
                msgPhone: {
                    '*': 'nonNumericEntered'
                },
                msgRequireboth: {
                    '*': 'requiredFieldForgotten'
                },
            },
            ssaStep1: {
                msgRequired: {
                    'Ssa[preisvorstellung]' : 'noPriceSet',
                    '*': 'requiredFieldForgotten'
                },
                msgMinonechar: {
                    '*': 'nonAlphaNumericEntered'
                },
                msgRemote: {
                    '*': 'pleaseCheckTransliteration'
                },
                msgMaxlength: {
                    '*': 'maxCharactersExceeded'
                },
                msgMinonedigit: {
                    '*': 'withoutDigitsEntered'
                },
                msgPhone: {
                    '*': 'nonNumericEntered'
                }
            },
            ssaStep2: {
                msgRequired: {
                    '*': 'requiredFieldForgotten'
                },
                msgMinonechar: {
                    '*': 'nonAlphaNumericEntered'
                },
                msgRemote: {
                    '*': 'pleaseCheckTransliteration'
                },
                msgMaxlength: {
                    '*': 'maxCharactersExceeded'
                },
                msgMinonedigit: {
                    '*': 'withoutDigitsEntered'
                }
            },
            ssaStep3: {
                msgRequired: {
                    '*': 'requiredFieldForgotten'
                },
                msgMinonechar: {
                    '*': 'nonAlphaNumericEntered'
                },
                msgRemote: {
                    '*': 'pleaseCheckTransliteration'
                },
                msgMaxlength: {
                    '*': 'maxCharactersExceeded'
                },
                msgMinonedigit: {
                    '*': 'withoutDigitsEntered'
                }
            },
            ssaStep4: {
                msgRequired: {
                    'Ssa[bilder][]': 'noImageAttached',
                    '*': 'requiredFieldForgotten'
                },
                msgMinonechar: {
                    '*': 'nonAlphaNumericEntered'
                },
                msgRemote: {
                    '*': 'pleaseCheckTransliteration'
                },
                msgMaxlength: {
                    '*': 'maxCharactersExceeded'
                },
                msgMinonedigit: {
                    '*': 'withoutDigitsEntered'
                }
            }
        },

        parseShowErrorList: function (errorList) {
            $.each(errorList, function (idx, error) {
                let errorName;
                $.each($(error.element).data(), function (key, value) {
                    if (value === error.message) {
                        errorName = key;
                    }
                });

                if (typeof error.element.name !== "undefined" && typeof errorName !== "undefined") {
                    let formType = SURPLEX.FormValidationErrorTracking.getFormType(error.element.form);
                    if (
                        typeof SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType] !== 'undefined'
                        && typeof SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType][error.element.name] !== 'undefined'
                    ) {
                        // Error did not exist previously?
                        if (SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType][error.element.name][errorName] !== 1) {
                            SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                                formType,
                                error.element.name,
                                errorName
                            );
                        }
                    } else {
                        SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                            formType,
                            error.element.name,
                            errorName
                        );
                    }
                    // Set error state to "error exists in this test round"
                    if (typeof SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType] === 'undefined') {
                        SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType] = {};
                    }

                    if (typeof SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType][error.element.name] === 'undefined') {
                        SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType][error.element.name] = {};
                    }

                    SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType][error.element.name][errorName] = 1;
                }
            });
        },

        parseHighlight: function ($element, formStep) {
        },

        parseUnhighlight: function ($element) {
            let formType;
            if (typeof $element.form !== "undefined") {
                formType = SURPLEX.FormValidationErrorTracking.getFormType($element.form);
            }
            if (typeof formType !== "undefined") {
                if (typeof SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType] === 'undefined') {
                    SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType] = {};
                }
                if (typeof $element.name !== "undefined") {
                    SURPLEX.FormValidationErrorTracking.formErrorHasActivated[formType][$element.name] = {};
                }
            }
        },

        trackFormErrorMap: function (formType, elementName, errorName, trackErrorName) {
            let formTypeErrorMap;
            let errorNameMap;
            if (typeof formType !== 'undefined' && formType !== '') {
                if (typeof SURPLEX.FormValidationErrorTracking.errorMapping[formType] !== 'undefined') {
                    formTypeErrorMap = SURPLEX.FormValidationErrorTracking.errorMapping[formType];
                }

                if (typeof formTypeErrorMap !== 'undefined' && typeof errorName !== 'undefined' && errorName !== '') {
                    if (typeof formTypeErrorMap[errorName] !== 'undefined') {
                        errorNameMap = formTypeErrorMap[errorName];
                    } else {
                        trackErrorName = 'Error ' + errorName + ' is not yet included in tracking!';
                    }
                }

                if (typeof errorNameMap !== 'undefined' && typeof elementName !== 'undefined' && elementName !== '') {
                    if (typeof errorNameMap[elementName] !== 'undefined') {
                        trackErrorName = errorNameMap[elementName];
                    } else if (typeof errorNameMap['*'] !== 'undefined') {
                        trackErrorName = errorNameMap['*'];
                    } else {
                        if (typeof trackErrorName === 'undefined') {
                            trackErrorName = 'Element ' + elementName + ' with error ' + errorName + ' is not yet included in tracking!';
                        }
                    }
                }
                let $vatStart = $('#vatstart');
                if(trackErrorName === 'vatError' && $vatStart.length > 0){
                    trackErrorName = trackErrorName + '_' + $($vatStart.get(0).options[$vatStart.get(0).selectedIndex]).data('country-iso').toString();
                }
                SURPLEX.Track.trackFormError(formType, elementName, trackErrorName);
                SURPLEX.FormValidationErrorTracking.incrementErrors(formType);
            }
        },

        trackSuccess: function (form) {
            let formType = SURPLEX.FormValidationErrorTracking.getFormType(form);
            if (typeof formType !== "undefined" && formType !== "") {
                SURPLEX.Track.trackFormSuccess(
                    formType,
                    SURPLEX.FormValidationErrorTracking.getTotalErrors(formType)
                );
                SURPLEX.FormValidationErrorTracking.resetTotalErrors(formType);
            }
        },

        getFormType: function (form) {
            return $(form).data('formtype') || '';
        },

        incrementErrors: function (formType) {
            if (typeof SURPLEX.FormValidationErrorTracking.formErrors[formType] === "undefined") {
                SURPLEX.FormValidationErrorTracking.formErrors[formType] = 0;
            }

            SURPLEX.FormValidationErrorTracking.formErrors[formType]++;
        },

        getTotalErrors: function (formType) {
            return SURPLEX.FormValidationErrorTracking.formErrors[formType] || 0;
        },

        resetTotalErrors: function (formType) {
            SURPLEX.FormValidationErrorTracking.formErrors[formType] = 0;
        }

    });
})($, SURPLEX);