import $ from "./cashwrapper";
;(function ($, SURPLEX) {
    SURPLEX.TrafficSource = $.extend({}, {
        cookieName: 'surplex_source',
        knownOrganicSources: {
            'www.google': {'source': 'google'},
            'daum.net/': {'source': 'daum'},
            'eniro.se/': {'source': 'eniro'},
            'naver.com/': {'source': 'naver'},
            'yahoo.com/': {'source': 'yahoo'},
            'msn.com/': {'source': 'msn'},
            'bing.com/': {'source': 'bing'},
            'aol.com/': {'source': 'aol'},
            'lycos.com/': {'source': 'lycos'},
            'ask.com/': {'source': 'ask'},
            'altavista.com/': {'source': 'altavista'},
            'search.netscape.com/': {'source': 'search.netscape'},
            'cnn.com/SEARCH/': {'source': 'cnn'},
            'about.com/': {'source': 'about'},
            'mamma.com/': {'source': 'mamma'},
            'alltheweb.com/': {'source': 'alltheweb'},
            'voila.fr/': {'source': 'voila'},
            'search.virgilio.it/': {'source': 'search.virgilio'},
            'baidu.com/': {'source': 'baidu'},
            'alice.com/': {'source': 'alice'},
            'yandex.com/': {'source': 'yandex'},
            'najdi.org.mk/': {'source': 'najdi.org'},
            'seznam.cz/': {'source': 'seznam'},
            'search.com/': {'source': 'search'},
            'wp.pl/': {'source': 'wp'},
            'online.onetcenter.org/': {'source': 'online.onetcenter'},
            'szukacz.pl/': {'source': 'szukacz'},
            'yam.com/': {'source': 'yam'},
            'pchome.com/': {'source': 'pchome'},
            'kvasir.no/': {'source': 'kvasir'},
            'sesam.no/': {'source': 'sesam'},
            'ozu.es/': {'source': 'ozu'},
            'terra.com/': {'source': 'terra'},
            'mynet.com/': {'source': 'mynet'},
            'ekolay.net/': {'source': 'ekolay'},
            'rambler.ru/': {'source': 'rambler'}
        },

        initializeLoadEvents: function () {
            this.setSurplexSourceCookie();
        },

        getReferralSource: function (referrer) {
            if (referrer.indexOf(document.location.hostname) === -1) {
                let url = new URL(referrer);
                return url.hostname;
            }
            return null;
        },

        isNotCookieSet: function () {
            return SURPLEX.Utils.getCookie(this.cookieName) === null;
        },

        getOrganicSource: function (referrer) {
            for (var key in this.knownOrganicSources) {
                // skip loop if the property is from prototype
                if (!this.knownOrganicSources.hasOwnProperty(key)) continue;

                if (referrer.indexOf(key) !== -1) {
                    return this.knownOrganicSources[key].source;
                }
            }
            return null;
        },

        setSurplexSourceCookie: function () {
            let source = '';
            let medium = '';
            let campaign = '';
            let url = new URL(location.href);

            if (url.searchParams.get("gclid") || url.searchParams.get("gclsrc")) {
                source = 'google';
                medium = 'cpc';
                campaign = 'adwords';
            } else if (url.searchParams.get("utm_source")) {
                source = url.searchParams.get("utm_source");
                medium = url.searchParams.get("utm_medium");
                campaign = url.searchParams.get("utm_campaign");
            } else {
                let referrer = document.referrer;
                if (referrer && referrer.length > 0) {
                    let organicSource = this.getOrganicSource(referrer);
                    if (organicSource !== null) {
                        source = organicSource;
                        medium = 'organic';
                    } else {
                        let referralSource = this.getReferralSource(referrer);
                        if (referralSource !== null) {
                            source = referralSource;
                            medium = 'referral';
                        }
                    }
                }
            }
            if (source.length === 0) {
                if (SURPLEX.Utils.getCookie(this.cookieName) === null) {
                    source = '(direct)';
                    medium = '(none)';
                    campaign = 'direct';
                }
            }
            if (source.length > 0) {
                let value = {
                    'source': source,
                    'medium': medium,
                    'campaign': campaign
                };
                value = JSON.stringify(value);
                let expires = new Date();
                expires.setTime(expires.getTime() + 31536000000); //180 days
                document.cookie = this.cookieName + '=' + value + ';path=/;expires=' + expires.toUTCString();
            }
        }
    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.TrafficSource);
    }
})($, SURPLEX);