import Popover from 'bootstrap/js/dist/popover';
import Modal from "bootstrap/js/dist/modal";
import Toast from "bootstrap/js/dist/toast";
import Splide from '../library/splide';
import $ from "./cashwrapper";
import Dropdown from "./surplex.Dropdown";
;(function ($, SURPLEX) {
    "use strict";

    $.fn.cloudinaryUpload = function (options) {
        let $el = $(this);
        if ($el.hasClass('initialized')) {
            return;
        }
        $el.addClass('initialized');
        let settings = $.extend({}, {
            uploadPreset: "",
            language: "en",
            tags: ['local'],
            text: {
                "en": {
                    "or": "Or",
                    "menu": {"files": "My Files"},
                    "local": {"browse": "Browse", "dd_title_multi": "Drag and Drop assets here"},
                    "queue": {"title": "My Files", "done": "Done"},
                }
            },
        }, options);

        $.getScript("//upload-widget.cloudinary.com/latest/global/all.js", function () {
            let upload_widget_images = document.createElement('div');
            upload_widget_images.setAttribute('id', 'upload_widget_images');
            $el.before($(upload_widget_images));
            let cloudinaryWidget = cloudinary.createUploadWidget({
                    preBatch: (callback, data) => {
                        let uploadedFiles = $(upload_widget_images).find('.uploaded_file');
                        if(uploadedFiles.length > 0 ){
                            if(uploadedFiles.length >= 10){
                                callback({cancel: true});
                                window.setTimeout(function () {
                                    cloudinaryWidget.close({quiet: true});
                                }, 100);
                                $el.attr('disabled', 'disabled');
                            } else {
                                callback({maxFiles: (10 - uploadedFiles.length)});
                                cloudinaryWidget.update({maxFiles: (10 - uploadedFiles.length)});
                            }
                        } else {
                            callback();
                        }
                    },
                    prepareUploadParams: (callback, params) => {
                        params = [].concat(params);
                        Promise.all(
                            params.map((req) =>
                                getPermission(req).then(function (response) {
                                        return Object.assign(
                                            {
                                                uploadPreset: response.uploadPreset,
                                                success: response.success,
                                            },
                                            response.upload_params
                                        );
                                    }
                                )
                            )
                        ).then(function (results) {
                            if(results.length === 1 && results[0].success !== true){
                                return callback({cancel: true});
                            }
                            return callback(results.length === 1 ? results[0] : results);
                        }).catch(function(err) {
                            let res = err.hasOwnProperty('responseJSON') ? err.responseJSON :
                                err.hasOwnProperty('responseText') ? SURPLEX.Utils.isJSON(err.responseText) : err;
                            if(res.hasOwnProperty('code') && res.code === 1001) {
                                callback({cancel: true});
                                window.setTimeout(function () {
                                    cloudinaryWidget.close({quiet: true});
                                }, 100);
                                $el.closest('form').find('button[type="submit"]').attr('disabled', 'disabled');
                                $el.remove();
                            }
                        });
                    },
                    cloudName: 'surplex',
                    uploadPreset: settings.uploadPreset,
                    sources: ['local'],
                    maxFiles: 10,
                    clientAllowedFormats: 'image',
                    maxFileSize: 10000000,
                    minImageWidth: 200,
                    minImageHeight: 200,
                    maxImageHeight: 4000,
                    maxImageWidth: 4000,
                    theme: 'minimal',
                    showPoweredBy: false,
                    tags: settings.tags,
                    thumbnailTransformation: 'c_fill,d_no_image.png,f_auto,fl_progressive,h_88,q_auto,w_88',
                    text: settings.text,
                    showUploadMoreButton: false,
                    styles: {
                        palette: {
                            window: "#FFF",
                            windowBorder: "#3b5468",
                            tabIcon: "#0E2F5A",
                            menuIcons: "#5A616A",
                            textDark: "#3b5468",
                            textLight: "#FFF",
                            link: "#203c53",
                            action: "#d1130f",
                            inactiveTabIcon: "#0E2F5A",
                            error: "#F44235",
                            inProgress: "#0078FF",
                            complete: "#20B832",
                            sourceBg: "#FFF"
                        },
                    },
                }, (error, result) => {
                    if(!error && result && result.event === "batch-canceled" && result.info.reason === "MAX_EXCEEDED"){
                        $.fn.surplexToast({
                            title : 'Ooops something went wrong',
                            content: $el.data('max-files'),
                            status: 'error ajax-error-toast'
                        });
                    }
                     if(!error && result && result.event === "queues-end"){
                         let uploadedFiles = $(upload_widget_images).find('.uploaded_file');
                         if(uploadedFiles.length > 0 ){
                             cloudinaryWidget.update({maxFiles: (10 - uploadedFiles.length)});
                             if(uploadedFiles.length >= 10 ){
                                 $el.attr('disabled', 'disabled');
                             }
                         }
                         window.setTimeout(function () {
                             cloudinaryWidget.close({quiet: true});
                         }, 100);
                     }
                    if (!error && result && result.event === "success") {
                        let element = document.createElement('span');
                        element.setAttribute('class', 'pe-2 uploaded_file');
                        let image = document.createElement('img');
                        image.setAttribute('src', result.info.thumbnail_url);
                        image.setAttribute('class', 'mb-2 borderRadius--large');
                        let deleteBtn = document.createElement('span');
                        deleteBtn.setAttribute('class', 'delete__btn icon__delete');
                        deleteBtn.onclick = function () {
                            this.parentNode.remove();
                            let uploadedFiles = $(upload_widget_images).find('.uploaded_file');
                            if(uploadedFiles.length < 10 ){
                                $el.removeAttr('disabled');
                                cloudinaryWidget.update({maxFiles: (10 - uploadedFiles.length)});
                            }
                        };
                        let input = document.createElement('input');
                        input.setAttribute('type', 'hidden');
                        input.setAttribute('name', 'Seller[images][]');
                        input.setAttribute('value', result.info.public_id);
                        element.appendChild(image);
                        element.appendChild(deleteBtn);
                        element.appendChild(input);
                        upload_widget_images.appendChild(element);
                    }
                }
            )
            $el.get(0).addEventListener("click", function () {
                cloudinaryWidget.open();
            }, false);
        });

        function getPermission(req) {
            return $.post({
                url: '/?r=Page/sell/upload&language=' + SURPLEX.App.pageLanguage,
                data: req,
                type: "POST",
                dataType: 'json',
            }).fail(function (jqXHR, textStatus, errorThrown) {
                SURPLEX.App.showAjaxException(jqXHR, textStatus, errorThrown);
            });
        }
    }

    $.fn.surplexNavigationSlider = function (options) {
        let $el = $(this);
        if ($el.hasClass('initialized')) {
            return;
        }
        $el.addClass('initialized');
        const quickNav = $el.get(0);
        let isDown = false;
        let startX, scrollLeft;

        quickNav.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX - quickNav.offsetLeft;
            scrollLeft = quickNav.scrollLeft;
        });

        quickNav.addEventListener('mouseleave', () => {
            isDown = false;
        });

        quickNav.addEventListener('mouseup', () => {
            isDown = false;
        });

        quickNav.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - quickNav.offsetLeft;
            const walk = x - startX;
            quickNav.scrollLeft = scrollLeft - walk;
        });
    }

    $.fn.surplexMainmenu = function (options) {
        let $el = $(this);
        if ($el.hasClass('initialized')) {
            return;
        }
        $el.addClass('initialized');
        let settings = $.extend(true, {}, {
            ddElms : '[data-bs-toggle="dropdown"]',
            modalBackdrop : '.modal-backdrop.show.d-none.d-lg-block'
        }, options);

        let openDropdownCount = 0;
        $el.find(settings.ddElms).each(function () {
            let ddElm = $(this).get(0);
            ddElm.addEventListener('shown.bs.dropdown', function () {
                openDropdownCount++;
                if (openDropdownCount === 1) {
                    let backdrop = document.createElement('div');
                    backdrop.classList.add('modal-backdrop', 'show', 'd-none', 'd-lg-block');
                    document.body.appendChild(backdrop);
                    document.body.classList.add('modal-open', 'menu-backdrop');
                }
            });
            ddElm.addEventListener('hide.bs.dropdown', function () {
                openDropdownCount--;
                if (openDropdownCount <1 ) {
                    document.body.removeChild(document.querySelector('.modal-backdrop.show.d-none.d-lg-block'));
                    document.body.classList.remove('modal-open', 'menu-backdrop');
                }
            });
        });
    }

    $.fn.surplexDashboardFilter = function (options) {
        let $el = $(this);
        if ($el.hasClass('initialized')) {
            return;
        }
        $el.addClass('initialized');
        let settings = $.extend(true, {}, {
            url: '',
            targetElem : ''
        }, options);


        $el.find('.dropdown-item').off('click').on('click', function (){
            let $elClicked = $(this);
            let $dropDown = $elClicked.closest('.dropdown');
            $dropDown.find('span.sorting__text').html($($elClicked).text());
            $dropDown.find('.dropdown-item').removeClass('active');
            $elClicked.addClass('active');

            SURPLEX.Ajax.fetch(
                {
                    url: SURPLEX.Utils.decodeBase(settings.url),
                    data: {'filter' : $elClicked.data('filter')},
                    callbackEnd: $.extend(
                        {
                            'wrapResult': function (target, data) {
                                if (data.hasOwnProperty('success') && data.success === true) {
                                    if (data.hasOwnProperty('html') && typeof data.html === 'object') {
                                        $.each(data.html, function (key, element) {
                                            let $elementToReplace = $(settings.targetElem).find(key);
                                            if ($elementToReplace.length) {
                                                $elementToReplace.replaceWith(element);
                                            }
                                        });
                                    } else {
                                        $(settings.targetElem).html(data.html);
                                    }
                                }
                            },
                        },
                        SURPLEX.Ajax.getDefaultEndCallbacks()
                    ),
                    loadingTarget: $el,
                }
            );
        });
    }

    $.fn.surplexInvoices = function (options) {
        let $el = $(this);
        if ($el.hasClass('initialized')) {
            return;
        }
        $el.addClass('initialized');
        let settings = $.extend(true, {}, {
            url: '',
        }, options);

        SURPLEX.Ajax.fetch(
            {
                url: SURPLEX.Utils.decodeBase(settings.url),
                data: {},
                callbackEnd: $.extend(
                    {
                        'wrapResult': function (target, data) {
                            if (data.hasOwnProperty('success')) {
                                if (data.hasOwnProperty('html') && typeof data.html === 'object') {
                                    $.each(data.html, function (key, element) {
                                        let $elementToReplace = $el.find(key);
                                        if ($elementToReplace.length) {
                                            $elementToReplace.replaceWith(element);
                                        }
                                    });
                                } else {
                                    $el.replaceWith(data.html);
                                }
                                const $invTab = $('#nav-tabContent');
                                const $form = $invTab.find('.user-form');
                                if($form.length > 0){
                                    SURPLEX.Register.initializeFormValidator($form);
                                }
                                if ($invTab.find('.invoice__itemBodyTopImage a').length > 0
                                    && typeof SURPLEX.DetailView === 'object') {
                                    SURPLEX.DetailView.invGallery();
                                }
                            }
                        },
                    },
                    SURPLEX.Ajax.getDefaultEndCallbacks()
                )
            }
        );
    }

    $.fn.surplexDashboard = function (options) {
        let $el = $(this);
        if ($el.hasClass('initialized')) {
            return;
        }
        $el.addClass('initialized');
        let settings = $.extend(true, {}, {
            url: '',
        }, options);

        SURPLEX.Ajax.fetch(
            {
                url: SURPLEX.Utils.decodeBase(settings.url),
                data: {},
                callbackEnd: $.extend(
                    {
                        'wrapResult': function (target, data) {
                            if (data.hasOwnProperty('success') && data.success === true) {
                                if (data.hasOwnProperty('html') && typeof data.html === 'object') {
                                    $.each(data.html, function (key, element) {
                                        let $elementToReplace = $el.find(key);
                                        if ($elementToReplace.length) {
                                            $elementToReplace.replaceWith(element);
                                        }
                                    });
                                } else {
                                    $el.replaceWith(data.html);
                                }
                            }
                        },
                    },
                    SURPLEX.Ajax.getDefaultEndCallbacks()
                )
            }
        );
    }

    $.fn.surplexDeposit = function (options) {
        let $el = $(this);
        if ($el.hasClass('initialized')) {
            return;
        }
        $el.addClass('initialized');
        let settings = $.extend(true, {}, {
            url: '',
            triggerOnClose : ''
        }, options);

        if (settings.url !== '') {
            SURPLEX.Ajax.fetch(
                {
                    url: SURPLEX.Utils.decodeBase(settings.url),
                    data: { triggerOnClose: settings.triggerOnClose},
                    callbackEnd: $.extend(
                        {
                            'infoLoaded': function (target, data) {
                                if (data.hasOwnProperty('success') && data.success === true) {
                                    $el.replaceWith(data.html);
                                }
                            }
                        },
                        SURPLEX.Ajax.getDefaultEndCallbacks()
                    )
                }
            );
        }
    }

    $.fn.surplexRating = function (options) {
        let $ratingEl = $(this);
        let consentAccepted = SURPLEX.Utils.getCookie('bcConsent');
        if ($ratingEl.hasClass('initialized') || consentAccepted === null || consentAccepted.indexOf(",") < 0) {
            return;
        }
        let defaults = { yes: '', no: '', q: '', qid: ''};
        let settings = $.extend(true, defaults, options);
        let trackingAction = $('meta[name=srxGooglePageType]').attr('content') + '_' + SURPLEX.App.pageLanguage;
        let $yesBtn = $('<button/>')
            .attr({'class': 'faq__ratingItem btn btn--tertiary btn--iconLeft', 'title': settings.yes})
            .one('click.rating', function () {
                $(this).addClass('faq__ratingActive faq__ratingDisabled');
                SURPLEX.Track.trackGA('customerCareTracking', trackingAction, 'thumbUp_' + settings.qid);
                $noBtn.off('click.rating').addClass('faq__ratingDisabled');
            })
            .html('<span class="icon__thumbUp" aria-hidden="true"></span><span>' + settings.yes + '</span>');
        let $noBtn = $('<button/>')
            .attr({'class': 'faq__ratingItem btn btn--tertiary btn--iconLeft', 'title': settings.no})
            .one('click.rating', function () {
                $(this).addClass('faq__ratingActive faq__ratingDisabled');
                SURPLEX.Track.trackGA('customerCareTracking', trackingAction, 'thumbDown_' + settings.qid);
                $yesBtn.off('click.rating').addClass('faq__ratingDisabled');
            })
            .html('<span class="icon__thumbDown" aria-hidden="true"></span><span>' + settings.no + '</span>');
        let $question = $('<span/>')
            .attr({'class': 'faq__ratingLabel'})
            .text(settings.q);
        $ratingEl.append($question, $yesBtn, $noBtn)
            .attr({'class': 'faq__rating initialized'});
    }

    $.fn.surplexSlider = function(options){
        let $sliderWrapper = $(this);
        let sliderEl = $sliderWrapper.get(0);

        if(sliderEl.classList.contains('loaded')){
            return;
        }
        let defaults = {
            easing: 'ease',
            gap: 10,
            type   : 'loop',
            updateOnMove: true,
            dragMinThreshold: {
                mouse: 0,
                touch: 0,
            },
            pagination: false,
            arrows: false,
            mediaQuery : 'min',
            pager: true,
            perMove: 2,
            hidePagination: false
        };
        let settings = $.extend(true, defaults, options);
        let slider = new Splide( sliderEl , settings);
        let $pageNav, $nextBtn, $prevBtn, $placeHolder;

        slider.on('mounted updated move', function (e) {
            if (this._event === 'mounted') {
                sliderEl.classList.add('loaded');
            }
            if (settings.pager) {
                createOrUpdatePager();
            }
        });
        slider.on('overflow', function (isOverflow) {
            slider.go(0);
            slider.options = {
                drag: isOverflow
            };
        });
        slider.mount();

        function createOrUpdatePager() {
            let slides = slider.Components.Slides;
            if (slides.isEnough()) {
                let $section = $sliderWrapper.closest('section');
                if($section.length === 0 ){
                    $section = $sliderWrapper.closest('.mainSection');
                }
                if ($section && $section.find('.toplot-slider-nav').length === 0) {
                    createPager();
                }
                let perPage = slider.options.perPage;
                let length = slider.length;
                let maxPages = Math.ceil(length / perPage) || 1;
                let currentPage = Math.ceil(slider.index / perPage) + 1;
                if ($pageNav) {
                    $pageNav.text((currentPage > maxPages ? maxPages : currentPage)  + ' / ' + maxPages);
                    $prevBtn.prop('disabled', currentPage === 1);
                    $nextBtn.prop('disabled', currentPage >= maxPages);
                }
            }

            function createPager() {
                let $section = $sliderWrapper.closest('section');
                if($section.length === 0 ){
                    $section = $sliderWrapper.closest('.mainSection');
                }
                if ($section.length > 0) {
                    let  $container = $sliderWrapper.next().is('button.btn--tertiary') ? $section.find('.container') : $section.find('header');
                    if ($container.length > 0) {
                        let $div = $('<div />').attr({'class': 'toplot-slider-nav d-none d-md-inline-flex text-nowrap'});
                        if($container.is('.container')){
                            $div.addClass('mt-3 float-end');
                        }
                        $prevBtn = $('<button />').attr({
                            'class': 'btn btn--tertiary btn--prev',
                            'type': 'button',
                            'aria-label': 'previous',
                        }).click(function () {slider.go('<');});
                        $nextBtn = $('<button />').attr({
                            'class': 'btn btn--tertiary btn--next',
                            'type': 'button',
                            'aria-label': 'next',
                        }).click(function () { slider.go('>');});

                        let $prevIcon = $('<span />').attr({
                            'class': 'icon__arrow-left'
                        });
                        let $nextIcon = $('<span />').attr({
                            'class': 'icon__arrow-right'
                        });
                        if(settings.hidePagination){
                            $placeHolder = $('<span />').attr({'class': 'p-2' });
                        } else {
                            $pageNav =  $('<span />').attr({'class': 'toplot-slider-pager p-2'});
                        }
                        $container.append($div.append($prevBtn.append($prevIcon))
                            .append($pageNav)
                            .append($placeHolder)
                            .append($nextBtn.append($nextIcon)));
                    }
                }
            }
        }
    }

    $.fn.surplexBCrecomm = function (options) {
        let $bcEl = $(this);
        if ($bcEl.data('initialized') === true) {
            return;
        }
        if (typeof window.blueConicClient !== 'undefined' &&
            typeof window.blueConicClient.event !== 'undefined' &&
            typeof window.blueConicClient.event.subscribe !== 'undefined') {
            loadBCRecomm();
        } else {
            window.addEventListener('onBlueConicLoaded', function () {
                loadBCRecomm();
            }, false);
        }

        function loadBCRecomm(){
            $bcEl.data('initialized', true);
            let settings = $.extend(true, {
                profileId: blueConicClient.profile.getProfile().getId(),
                storeId: "e05b9b4a-24f2-4613-b83e-ba13ab6421d0",
                request: [{
                    id: "first",
                    boosts: [{
                        value: 5,
                        algorithm: "INTEREST",
                    }],
                    filters: ["VIEWED"],
                    count: 5
                }, {
                    id: "second",
                    boosts: [{
                        value: 3,
                        algorithm: "COLLABORATIVE_FILTERING"
                    }],
                    filters: ["VIEWED"],
                    count: 5
                }, {
                    id: "filler",
                    boosts: [{
                        value: 1,
                        algorithm: "RECENTLY_VIEWED"
                    }]
                }],
                frequencyCap: 1000
            }, options);
            blueConicClient.recommendation.getRecommendations(settings, function (response) {
                let ids = response.getItems().map( item => item.id );
                if(ids.length > 4){
                    SURPLEX.Ajax.fetch(
                        {
                            url: '/',
                            data: {
                                articleIds: ids,
                                r: "cross-selling/blueconic-articles",
                                language: SURPLEX.App.pageLanguage
                            },
                            callbackEnd : $.extend(
                                {
                                    'renderResult': function (target, data) {
                                        if (data.hasOwnProperty('html')) {
                                            $bcEl.html(data.html);
                                            SURPLEX.Utils.enablePlugInsForSection($bcEl);
                                            SURPLEX.App.enableImageLazyLoad();
                                        }
                                    }
                                },
                                SURPLEX.Ajax.getDefaultEndCallbacks()
                            ),
                            loadingTarget : $bcEl
                        }
                    );
                }
            });
        }
    };

    $.fn.surplexLastSeen = function () {
        const $lastSeenContainer = $(this);
        if ($lastSeenContainer.data('initialized') === true) {
            return;
        }

        const lastSeenItems = SURPLEX.Utils.isJSON(SURPLEX.Utils.getCookie('surplex_lastwatched')) || [];
        if (SURPLEX.App.isGuest && (lastSeenItems instanceof Array) && (lastSeenItems.length === 0)) {
            return;
        }
        if(!SURPLEX.Utils.isInViewport($lastSeenContainer)){
            window.addEventListener('scroll', addListener, true);
        } else {
            loadLastSeenItems();
        }

        function addListener() {
            if (SURPLEX.Utils.isInViewport($lastSeenContainer) && $lastSeenContainer.data('initialized') !== true) {
                window.removeEventListener('scroll', addListener, true);
                loadLastSeenItems();
            }
        }

        function loadLastSeenItems() {
            $lastSeenContainer.data('initialized', true);
            const $lastSeenWidget = $lastSeenContainer.find('.js-last-seen-list');
            SURPLEX.Ajax.fetch(
                {
                    url: SURPLEX.Utils.decodeBase($lastSeenWidget.data('lastseen-url')),
                    data: {
                        lastSeenIds: JSON.stringify(lastSeenItems)
                    },
                    callbackEnd : {'renderResult': function (target, result){
                            const ajaxResult = SURPLEX.Utils.isJSON(result);
                            if (ajaxResult.hasOwnProperty('html') && ajaxResult.hasOwnProperty('articleIds') && ajaxResult.articleIds.length > 0) {
                                $lastSeenWidget.html(ajaxResult.html);
                                const cookieStoreLastSeenItems = SURPLEX.Utils.isJSON(SURPLEX.Utils.getCookie('surplex_lastwatched')) || [];
                                const returnedLastSeenItems = ajaxResult.articleIds;
                                if (cookieStoreLastSeenItems.sort().toString() !== returnedLastSeenItems.sort().toString()) {
                                    SURPLEX.Utils.setCookie('surplex_lastwatched', JSON.stringify(returnedLastSeenItems));
                                }
                                $lastSeenContainer.show();
                                SURPLEX.Utils.enablePlugInsForSection($lastSeenWidget);
                                SURPLEX.App.enableImageLazyLoad();
                            }
                        }},
                    loadingTarget : $lastSeenWidget
                }
            );
        }

    };

    $.fn.surplexBidHints = function (options) {
        let popOverDef = {
            html: true,
            placement: "top",
            title: "",
            content: "",
            trigger: "focus",
        };

        let defaults = {
            html: true,
            placement: "top",
            trigger: "focus",
            errors: {
                minimum: {
                    title: "",
                    content: ""
                },
                invalid: {
                    title: "",
                    content: ""
                },
                bidagent: {
                    title: "",
                    content: ""
                }
            }
        };
        let settings = $.extend(true, {}, defaults, options);
        SURPLEX.App.uniqueBindEvent(this, 'keyup focus', 'popover', function (e) {
            let $el = $(this);
            let $articleItem = $el.closest('.js-article--item');
            if ($.trim($el.val()) === '') {
                hideHint()
                return;
            }
            let validatedInput = SURPLEX.Bid.validateInput($el.val());
            let userIsHighestBidder = (parseInt($articleItem.attr('data-users-max-bid')) > 0 && parseInt($articleItem.attr('data-users-max-bid')) >= parseInt($articleItem.attr('data-machine-max-bid')));

            let show = false;
            let title = '';
            let content = '';

            if (!validatedInput) {
                show = true;
                title = settings.errors.invalid.title;
            } else if (userIsHighestBidder){
                if(validatedInput <= parseInt($articleItem.attr('data-users-max-bid'))) {
                    show = true;
                    title = settings.errors.bidagent.title;
                }
            } else if (validatedInput < parseInt($articleItem.attr('data-minimum-bid'))) {
                show = true;
                title = settings.errors.minimum.title;
            }

            if (show) {
                if (userIsHighestBidder) {
                    content = settings.errors.bidagent.content + ' ' + $articleItem.data('users-max-bid-formatted');
                } else {
                    content = settings.errors.minimum.content + ' ' + $articleItem.data('minimum-bid-formatted');
                }
                showHint({
                    title: title,
                    content: content
                });
            } else {
                hideHint();
                enableBidButton();
            }

            function showHint(options) {
                let settings = $.extend(true, {}, popOverDef, options);
                let popOver = Popover.getInstance($el.get(0)) || new Popover($el.get(0), settings);
                popOver.show();
                disableBidButton();
            }

            function hideHint() {
                let popOver = Popover.getInstance($el.get(0));
                if(popOver !== null){
                    popOver.dispose();
                }
            }

            function enableBidButton() {
                $articleItem.find('.js-bid-button').removeClass('disabled');
            }

            function disableBidButton() {
                $articleItem.find('.js-bid-button').addClass('disabled');
            }
        });

    };

    $.fn.surplexPopoverImages = function(options){
        let $el = $(this);
        let settings = $.extend(true, {}, {
            html: true,
            placement: "top",
            trigger: "hover",
        }, options);
        new Popover($el.get(0), settings);
    }

    $.fn.surplexDownloadFile = function (options) {
        let defaults = {
            type: 'GET',
            url: '',
            contentType: false,
            processData: false,
            xhrFields: {
                responseType: 'blob'
            },
            success: function (response, status, xhr) {
                let filename = "";
                let disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches !== null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                try {
                    let blob = new Blob([response], {type: 'application/octet-stream'});
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        let URL = window.URL || window.webkitURL;
                        let downloadUrl = URL.createObjectURL(blob);
                        if (filename) {
                            let a = document.createElement("a");
                            if (typeof a.download === 'undefined') {
                                window.location.href = downloadUrl;
                            } else {
                                if (SURPLEX.Utils.isIos() && (navigator.userAgent.match("FxiOS") || navigator.userAgent.match("CriOS"))) {
                                    let reader = new FileReader();
                                    let out = new Blob([response], {type: 'application/pdf'});
                                    let file = new File([out], filename, {type: out.type});
                                    reader.onloadend = function(){
                                        window.location.href = reader.result;
                                    }
                                    reader.readAsDataURL(file);
                                } else {
                                    a.href = downloadUrl;
                                    a.download = filename;
                                    document.body.appendChild(a);
                                    a.target = "_blank";
                                    a.click();
                                }
                            }
                        } else {
                            window.location.href = downloadUrl;
                        }
                    }
                    $('.is-loading').removeClass('is-loading');
                } catch (e) {
                    $('.is-loading').removeClass('is-loading');
                }
            },
            error : function (res) {
                $('.is-loading').removeClass('is-loading');
            }
        };
        let settings = $.extend(true, {}, defaults, options);
        return $.ajax(settings);
    };
    $.fn.surplexToast = function (options) {
        let defaults = {
            title: '',
            time: '',
            content: '',
            id: SURPLEX.Utils.createUUID(),
            classname: 'toast animated fadeInUp ' + options.status,
            track: 'push notification',
            hide_in_ms: 0
        };
        let settings = $.extend(true, {}, defaults, options);
        let $toastWrapper = $('#notifications');
        if ($toastWrapper.length === 0) {
            $toastWrapper = $('<div />')
                .attr('id', 'notifications')
                .attr('class', 'toastWrap right-bottom')
                .attr('aria-live', 'polite')
                .attr('aria-atomic', 'true')
                .appendTo("body");
        }
        let $existendToast = $('#' + settings.id);
        if ($existendToast.length > 0) {
            let shownToast = Toast.getInstance(document.getElementById(settings.id));
            if(shownToast !== null){
                shownToast.dispose();
            }
            $existendToast.remove();
        }
        let $toast = $('<div />')
            .attr('id', settings.id)
            .attr('class', settings.classname)
            .attr('role', 'alert')
            .attr('aria-live', 'assertive')
            .attr('aria-atomic', 'true')
            .attr('data-bs-autohide', (settings.hide_in_ms > 0))
            .attr('data-bs-delay', settings.hide_in_ms)
            .attr('data-track-category', settings.track)
            .append(
            $('<div />').attr('class', 'toast-header')
                .append($('<div />').attr('class', 'me-auto').html(settings.title),
                    $('<small />').html(settings.time),
                    $('<button />').attr('type', 'button')
                        .attr('class', 'btn btn--close btn--small')
                        .attr('data-bs-dismiss', 'toast')
                        .attr('aria-label', 'close')
                )
        ).append(
            $('<div />')
                .attr('class', 'toast-body')
                .html(settings.content)
        );
        if(settings.classname.indexOf('error') !== -1){
            $toast.find(".toast-header").prepend($('<div />').attr('class', 'icon__alert me-2'));
        }
        $toast.appendTo($toastWrapper);
        $toast.get(0).addEventListener('hidden.bs.toast', function () {
            $toast.remove();
        });
        return new Toast($toast.get(0)).show();
    };


    $.fn.surplexAlert = function (message, button) {
        return $.fn.surplexModal({
            title: "Ooo-oops",
            content: $("<div />").attr('class', 'col-sm-12').html(message),
            closeIcon: true,
            buttons: [
                {
                    text: (typeof button !== "undefined" ? button : "OK"),
                    id: SURPLEX.Utils.createUUID(),
                    classname: 'btn-light',
                    click: function ($modal) {
                        $modal.dismiss();
                    }
                }
            ]
        });
    };

    $.fn.surplexModal = function (options) {
        let defaults = {
            title: "", content: $("<div />").attr('class', 'col-sm-12'),
            closeIcon: false, id: SURPLEX.Utils.createUUID(), buttons: []
        };
        let settings = $.extend(true, {}, defaults, options);
        let $modal = $("<div />").attr("id", settings.id).attr("role", "dialog").addClass("modal fade")
            .append($("<div />").addClass("modal-dialog").addClass("modal-dialog-centered")
                .append($("<div />").addClass("modal-content")
                    .append($("<div />").addClass("modal-header")
                        .append($("<div />").addClass("modal-title").text(settings.title)))
                    .append($("<div />").addClass("modal-body")
                        .append(settings.content))
                    .append($("<div />").addClass("modal-footer")
                    )
                )
            );
        $modal.shown = false;
        $modal.dismiss = function () {
            if (!$modal.shown) {
                window.setTimeout(function () {
                    $modal.dismiss();
                }, 50);
                return;
            }
            let modal = Modal.getInstance($modal.get(0));
            if(modal !== null){
                modal.hide();
            }
            $modal.prev().remove();
            $modal.empty().remove();
            $("body").removeClass("modal-open");
        };
        if (settings.closeIcon) {
            $modal.find(".modal-header").append($("<button />").attr("type", "button").addClass("btn-close").click(function () {
                $modal.dismiss()
            }));
        }
        let $footer = $modal.find(".modal-footer");
        for (let i = 0; i < settings.buttons.length; i++) {
            (function (btn) {
                $footer.append($("<button />").addClass("btn " + btn.classname || 'btn-light')
                    .attr("id", btn.id)
                    .attr("type", "button")
                    .text(btn.text)
                    .click(function () {
                        btn.click($modal)
                    }))
            })(settings.buttons[i]);
        }
        let modalElm = $modal.get(0);
        modalElm.addEventListener('shown.bs.modal', function(){
            $modal.shown = true;
        });
        return new Modal(modalElm).show();
    };

    $.fn.surplexStaticpagesmenu = function (options) {
        if ($(this).data('initialized') === true) {
            return;
        }
        let settings = $.extend({}, {
            "selected": "active",
            "title": " ",
            "targetElem" : "#category-menu"
        }, options);
        let menu;
        $(this)[0]
            .addEventListener('click', () => {
                if (menu instanceof MmenuLight) {
                    menu.open();
                } else {
                    menu = new MmenuLight($(settings.targetElem)[0], settings);
                    menu.enable("(max-width: 1023.98px)");
                    menu.offcanvas();
                    window.setTimeout(function () {
                        menu.open();
                    }, 10);
                }
            });
        $(this).data('initialized', true);
    };

    $.fn.surplexMenu = function (options) {
        if ($(this).data('initialized') === true) {
            return;
        }
        let settings = $.extend({}, {
            "language" : 'en',
            "selected": "active",
            "categoryId":"0",
            "title": " ",
            "targetElem" : "#category-menu",
            "treeType" : "categories"
        }, options);
        let menu;
        $(this)[0]
            .addEventListener('click', () => {
                if (menu instanceof MmenuLight) {
                    menu.open();
                } else {
                    SURPLEX.Ajax.fetch(
                        {
                            loadingTarget : $(this),
                            url: "/?r=/Page/category/category-menu",
                            data: {'language':settings.language, 'categoryId' : settings.categoryId , 'treeType' : settings.treeType },
                            callbackEnd : $.extend(
                                {
                                    'categoriesLoaded': function (target, data) {
                                        $(settings.targetElem).append(data);
                                        menu = new MmenuLight($(settings.targetElem)[0], settings);
                                        menu.enable("(max-width: 1023.98px)");
                                        menu.offcanvas();
                                        menu.open();
                                    }
                                },
                                SURPLEX.Ajax.getDefaultEndCallbacks()
                            )
                        }
                    )
                }
            });
        $(this).data('initialized', true);
    };

    $.fn.surplexFilterDeep = function (options) {
        let settings = $.extend({}, {
            "name": null,
            "more": null
        }, options);



        $('#' + $(this).attr('aria-labelledby')).get(0).addEventListener('show.bs.dropdown', function(){
            if (settings.more in window && window[settings.more] !== null) {
                addMore();
            }
        });
        function addMore() {
            if (settings.more in window && window[settings.more] !== null) {
                $.each(window[settings.more], function (country, cities) {
                    let nthList = [];
                    let loop = 1;
                    let $countryList = $('#collapse-'+settings.name+'-'+country);
                    if($countryList.length > 0){
                        loop = $countryList.length;
                        $.each(cities, function (index, value) {
                            let $li = $('<li>').attr({
                                'class':  value[2] === 1 ? 'filter__listItem active' : 'filter__listItem'
                            });
                            let $div = $('<label>').attr({'for' : settings.name + '-' + country + '-' + value[5], 'class': 'filter__checkbox'});
                            let $checkBox = $('<input>').attr({
                                'class': 'js-ignoreClick',
                                'type': 'checkbox',
                                'data-select-once': 'false',
                                'data-value-mode': 'list',
                                'name': 'filter[' + settings.name + ']['+country+']',
                                'id': settings.name + '-' + country + '-' + value[5],
                                'value': decodeURI(value[0]),
                                'aria-checked': value[2] === 1 ? 'true' : 'false',
                                'data-button-label' : value[1],
                                'data-filter-label': value[3]}
                                ).click(function (e) {
                                SURPLEX.Filter.triggerOptionChecked(e, $(this));
                            });
                            if (value[2] === 1) {
                                $checkBox.attr('checked', 'checked')
                            }
                            let $label = $('<span>')
                                .html('<span>' + value[1] + '<span class="filter__count">(' + value[4] +')</span></span>');
                            nthList.push($li.append($div.append($checkBox, $label)));
                            loop++;
                        });
                        $countryList.append(...nthList);
                    }
                });
                window[settings.more] = null;
            }
        }
    };

    $.fn.surplexFilter = function (options) {
        let settings = $.extend({}, {
            "name": null,
            "more": null,
            "searchField": $(this).find('.js-type-in').find('input'),
            "searchResult": $(this).find('.js-type-in-results')
        }, options);
        settings.searchField.on('keyup', function (e) {
            let valThis = $(this).val().toLowerCase();
            if (valThis === "") {
                settings.searchResult.children('li').show();
            } else {
                settings.searchResult.children('li').each(function () {
                    let text = $(this).text().toLowerCase();
                    (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
                });
            }
        });
        if(!SURPLEX.Utils.isTouchDevice()){
            settings.searchField.closest('.dropdown').on('shown.bs.dropdown', function (e) {
                settings.searchField.get(0).click().focus();
            });
        }
        if (settings.more !== null) {
            settings.searchField.on('focus', function (e) {
                addMore();
                settings.searchField.off('focus');
            });
            settings.searchResult.closest('.filter__dropdownBody').on('scroll', function () {
                if ($(this).get(0).scrollTop + $(this).get(0).clientHeight >= $(this).get(0).scrollHeight) {
                    addMore();
                    settings.searchResult.closest('.filter__dropdownBody').off('scroll');
                }
            });
        }

        function addMore() {
            if (settings.more in window && window[settings.more] !== null) {
                let nthList = [];
                $.each(window[settings.more], function (index, value) {
                    let $li = $('<li>').attr('class', value[2] === 1 ? 'filter__listItem active' : 'filter__listItem');
                    let $div = $('<label>').attr({'for' : settings.name + '-' + value[0], 'class':'filter__checkbox'});
                    let $checkBox = $('<input>')
                        .attr('type', 'checkbox')
                        .attr('data-select-once', 'false')
                        .attr('data-value-mode', 'list')
                        .attr('name', 'filter[' + settings.name + '][]')
                        .attr('id', settings.name + '-' + value[0])
                        .attr('value', value[0])
                        .attr('aria-checked', value[2] === 1 ? 'true' : 'false')
                        .attr('data-filter-label', value[3])
                        .attr('data-button-label', value[1])
                        .click(function (e) {
                            SURPLEX.Filter.triggerOptionChecked(e, $(this));
                        });
                    if (value[2] === 1) {
                        $checkBox.attr('checked', 'checked')
                    }
                    let $label = $('<span>')
                        .html('<span>' + value[1] + '<span class="filter__count">(' + value[4] +')</span></span>');
                    nthList.push($li.append($div.append($checkBox, $label)));
                });
                settings.searchResult.append(...nthList);
                window[settings.more] = null;
            }
        }
    };

    $.fn.surplexAutoComplete = function (options) {
        let settings = $.extend({}, {
            searchurl: "/?r=Page/search/manufacturer&language=en",
            target: '#manufacturer-dd'
        }, options);

        let $searchField = $(this);
        $searchField.off('keyup').on('keyup', function (e) {
            const partialInput = $(this).val();
            if (partialInput.length === 0) {
                $(settings.target).html('');
                if ($(settings.target).hasClass('show')) {
                    let manDD = document.getElementById('man-search-dropdown');
                    let manFilter = Dropdown.getInstance(manDD);
                    if(manFilter !== null){
                        manFilter.hide();
                    }
                }
                return;
            }

            $.ajax({
                url: settings.searchurl,
                data: {search: partialInput},
                type: "GET",
                success: function (res) {
                    buildDropdown(
                        res,
                        $(settings.target)
                    );
                }
            });
        });

        $searchField.on('focusin', function (e) {
            const partialInput = $(this).val();
            if (partialInput.length === 0) {
                $(settings.target).html('');
                if ($(settings.target).hasClass('show')) {
                    let manDD = document.getElementById('man-search-dropdown');
                    let manFilter = Dropdown.getInstance(manDD);
                    if(manFilter !== null){
                        manFilter.hide();
                    }
                }
            }
        });

        $searchField.closest('.dropdown').get(0).addEventListener('show.bs.dropdown', function(e){
            let $resutlList = $(settings.target).find('ul');
            if ($resutlList.length === 0 || ($resutlList.length && $resutlList.html().length <= 0 || $searchField.val().length < 1)) {
                e.preventDefault();
            }
        });

        function buildDropdown(result, dropdown) {
            dropdown.html('');
            if (result !== '') {
                let $list = $('<ul>').attr("class", "w-100");
                $.each(result, function (k, v) {
                    let $itemTrigger = $('<a>').html ( v.name )
                        .attr("href", "javascript://");
                    $itemTrigger.on('click', function () {
                        $searchField.val($(this).text());
                        let manDD = document.getElementById('man-search-dropdown');
                        let manFilter = Dropdown.getInstance(manDD);
                        if(manFilter !== null){
                            manFilter.hide();
                        }
                    });
                    let $item = $('<li>').attr('class', 'dd-s-menu--item');
                    $item.append($itemTrigger);
                    $list.append($item);
                });
                dropdown.append($list);
                if (!$(settings.target).hasClass('show')) {
                    let manDD = document.getElementById('man-search-dropdown');
                    let manFilter = Dropdown.getOrCreateInstance(manDD);
                    if(manFilter !== null){
                        manFilter.show();
                    }
                }
            } else {
                if ($(settings.target).hasClass('show')) {
                    let manDD = document.getElementById('man-search-dropdown');
                    let manFilter = Dropdown.getInstance(manDD);
                    if(manFilter !== null){
                        manFilter.hide();
                    }
                }
            }
        }
    };
    $.fn.surplexWizzard = function () {
        let $wizard = $(this);
        if ($wizard.attr('data-unique-bind-paymentloaded') !== 1) {
            let $wizardContent = $($wizard.data('target'));
            $wizard.find('.progressNav--item[data-bs-toggle="tab"]').on('show.bs.tab', function (e) {
                let $target = $(e.target);
                let $current = $(e.relatedTarget);
                if ($target.parent().hasClass('disabled')) {
                    return false;
                }
            });
            $wizardContent.find(".prev-step").click(function (e) {
                let $active = $wizard.find('.nav-item.active');
                prevTab($active);
            });
            $wizard.attr('data-unique-bind-paymentloaded', 1);
        }
        let prevTab = function (elem) {
            elem.prev('[data-bs-toggle="tab"]').get(0).click();
        }
    };

    $.fn.surplexFileinput = function () {

        function checkUploads($input) {
            let $resultDiv = $($input.data('result-target'));
            let $uploadedList = $resultDiv.find('ul');
            let $hiddenField = $('input[name="' + $input.attr('name') + '"]').filter(SURPLEX.Utils.jqueryHiddenSelector).not($input);
            if ($uploadedList.length && $hiddenField.length) {
                let data_array = [];
                $uploadedList.find('li').each(function () {
                    let $listItem = $(this);
                    $listItem.find('.js-delete-item').off('click').on('click', function (e) {
                        $resultDiv.addClass('is-loading');
                        SURPLEX.Ajax.post(
                            {
                                url: $input.data('delete-url'),
                                data: {'Ssa': {'file': $listItem.data('path')}},
                                callbackStart : {},
                                callbackEnd : $.extend(SURPLEX.Ajax.getDefaultEndCallbacks(),
                                    {
                                        'onUploadEnd': function (targetObject, result) {
                                            $resultDiv.removeClass('is-loading');
                                        }
                                    }
                                ),
                                callbackOnSuccess : $.extend(SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                                    {
                                        'onUploadSuccess': function (targetObject, result) {
                                            $resultDiv.html(result.html);
                                            checkUploads($input);
                                        }
                                    }
                                )
                            }
                        );
                    });
                    data_array.push($listItem.data('path'));
                });
                $hiddenField.val(JSON.stringify(data_array));
                if ($input.attr('required')) {
                    $input.rules('add', {
                        required: false
                    });
                }
            } else {
                if($hiddenField.length){
                    $hiddenField.val('');
                }
                if ($input.attr('required')) {
                    $input.rules('add', {
                        required: true
                    });
                }
            }
        }

        checkUploads($(this));
        if (window.FormData) {
            this.each(function (index, file_field) {
                SURPLEX.App.uniqueBindEvent(file_field, 'change', 'plugins', function (e) {
                    let $fileEl = $(this);
                    if ($fileEl.get(0).files && $fileEl.get(0).files.length > parseInt($fileEl.data('rule-maxfiles'))) {
                        $($fileEl.data('error-placement')).html(
                            '<div id="' + $fileEl.data('error-placement').replace('-placement', '') + '" class="is-invalid">' + $fileEl.data('msg-maxfiles') + '</div>'
                        );
                        this.value = null;
                        return false;
                    } else {
                        $($fileEl.data('error-placement')).html('');
                    }
                    let formdata = new FormData($(file_field).closest('form')[0]);
                    if (formdata) {
                        let $resultDiv = $($(file_field).data('result-target'));
                        $resultDiv.addClass('is-loading');

                        SURPLEX.Ajax.setPostSettings({
                            processData: false,
                            contentType: false
                        });
                        SURPLEX.Ajax.post(
                            {
                                url: $(file_field).data('upload-url'),
                                data: formdata,
                                callbackStart : {},
                                callbackEnd : $.extend(SURPLEX.Ajax.getDefaultEndCallbacks(),
                                    {
                                        'onUploadEnd': function (targetObject, result) {
                                            $resultDiv.removeClass('is-loading');
                                        }
                                    }
                                ),
                                callbackOnSuccess : $.extend(SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                                    {
                                        'onUploadSuccess': function (targetObject, result) {
                                            $resultDiv.html(result.html);
                                            checkUploads($(file_field));
                                        }
                                    }
                                ),
                                callbackOnFail : function (jqXHR, textStatus, errorThrown) {
                                    if (jqXHR.status === 413) {
                                        $($fileEl.data('error-placement')).html(
                                            '<div id="' + $fileEl.data('error-placement').replace('-placement', '') + '" class="is-invalid">' + $fileEl.data('msg-toolarge') + '</div>'
                                        );
                                    } else {
                                        SURPLEX.Ajax.showAjaxException(jqXHR, textStatus, errorThrown);
                                    }
                                }
                            }
                        );
                    }
                    this.value = null;
                });
            });
        } else {
            $(this).hide();
        }
    };

    $.fn.surplexPayment = function (options) {
        options = options || {
            frameId: "payment-credit-card",
            formId: "payment-credit-card-form"
        };
        if ($(this).attr('data-unique-bind-paymentloaded') !== 1) {
            const receiveMessage = function (e) {
                if (e.stopPropagation) {
                    e.stopPropagation();
                }
                if (e.preventDefault) {
                    e.preventDefault();
                } else {
                    e.returnValue = false;
                }

                if (e.origin !== targetOrigin) {
                    return;
                }
                SURPLEX.Ajax.removeLoadingToTarget($(paymentFrameForm));
                clearTimeout(loadingTimeoutHandler);
            };

            const getDomainFromUrl = function (url) {
                let arr = url.split("/");
                return arr[0] + "//" + arr[2];
            };

            const sendMessage = function (e) {
                if (e.stopPropagation) {
                    e.stopPropagation();
                }
                if (e.preventDefault) {
                    e.preventDefault();
                } else {
                    e.returnValue = false;
                }
                SURPLEX.Ajax.addLoadingToTarget($(paymentFrameForm));

                loadingTimeoutHandler = setTimeout(function () {
                    SURPLEX.Ajax.removeLoadingToTarget($(paymentFrameForm));
                    $.fn.surplexToast({
                        title : $(paymentFrameForm).data('server-error-title'),
                        content: $(paymentFrameForm).data('server-error-message'),
                        status: 'error ajax-error-toast'
                    });

                    let userId = SURPLEX.Track.getDataFromLayer('dataLayer', 'userId');
                    SURPLEX.Track.trackGA(
                        'creditCardError',
                        'user_' + userId,
                        'connectionTimeout'
                    );
                }, 30000);

                let data = {};
                let i = 0, len = paymentFrameForm.length;
                for (; i < len; ++i) {
                    const input = paymentFrameForm[i];
                    if (input.name) {
                        data[input.name] = input.value;
                    }
                }
                paymentFrameIframe.contentWindow.postMessage(JSON.stringify(data), targetOrigin);
            };

            let loadingTimeoutHandler = undefined;
            let paymentFrameIframe = document.getElementById(options.frameId);
            let targetOrigin = getDomainFromUrl(paymentFrameIframe.src);
            let paymentFrameForm = document.getElementById(options.formId);

            if (paymentFrameForm.addEventListener) {// W3C DOM
                paymentFrameForm.addEventListener('submit', sendMessage);
            } else if (paymentFrameForm.attachEvent) { // IE DOM
                paymentFrameForm.attachEvent('onsubmit', sendMessage);
            }

            if (window.addEventListener) { // W3C DOM
                window.addEventListener('message', receiveMessage);
            } else if (window.attachEvent) { // IE DOM
                window.attachEvent('onmessage', receiveMessage);
            }

            $(this).attr('data-unique-bind-paymentloaded', 1);
        }
        return this;
    };

    $.fn.surplexGoogleAddress = function (options) {
        if (typeof google === 'object' && typeof google.maps === 'object') {
            SURPLEX.Register.initializeGoogleMaps();
        } else {
            let settings = $.extend({}, {
                gpk: '',
                language: 'en'
            }, options);

            if (settings.gpk === '') {
                return;
            }
            let gmapParam = [
                'key=' + settings.gpk,
                'language=' + settings.language,
                'signed_in=true',
                'libraries=places',
                'callback=SURPLEX.Register.initializeGoogleMaps'
            ];
            $.getScript("https://maps.googleapis.com/maps/api/js?" + gmapParam.join("&"));
        }
    };

    $.fn.surplexGoogleCaptcha = function (options) {
        if (typeof grecaptcha === 'undefined') {
            let settings = $.extend({}, {
                sitekey: $(this).data('sitekey'),
                hl: SURPLEX.App.pageLanguage
            }, options);

            let captchaParam = [
                'render=' + settings.sitekey,
                'hl=' + settings.hl
            ];
            $.getScript("https://www.google.com/recaptcha/api.js?" + captchaParam.join("&"));
        }
    };

    $.fn.surplexToolbar = function (options) {
        let self = this;
        if(self.data('initialized') === 'true'){
            return;
        }
        self.pagingElements = $('.pager' + options.pagerName).not('[data-initialized]');
        self.sortingElements = $('.js-sorter' + options.sorterName).not('[data-initialized]');
        self.finalElement = '';
        self.settings = $.extend({}, {
            wrapper: '<div class="dropdown pagination__dropdownContainer"><button class="btn btn--small btn--tertiary" type="button" data-bs-toggle="dropdown" aria-haspopup="true" ' + (options.totalPages < 2 ? ' disabled="disabled"' : '') +
                '><span class="pagination__text">{{pageStr}} {{page}}/{{totalPages}}</span>' +
                (options.totalPages > 1 ? ' <span class="icon__chevron-down" />' : '') +
                '</button>' +
                (options.totalPages > 1 ? ' <div class="pagination__dropdown dropdown-menu dropdown-menu-right">{{pagesDropdown}}</div>' : '') +
                '</div>',
            prev: '<div class="prev btn btn--small btn--tertiary"><div class="icon__chevron-left"></div></div>',
            next: '<div class="next btn btn--small btn--tertiary"><div class="icon__chevron-right"></div></div>',
            page: '<span class="pagination__dropdownItem js-link">{{pageStr}} {{page}}/{{totalPages}}</span>',
            pageString: '',
            totalPages: 0,
            currentPage: 1,
            currentSort: '',
            defaultSorting: '',
            disableClass: 'invisible',
            activeClass: 'active',
            pagerName: '',
            sorterName: '',
            toolbarUrl: '',
            toolbarClass: '',
            targetElem: '',
            seoElem: '.categorie_werbetext',
            onPageChange: function (index, type) {
                let params = {};
                let $sorter = $('.js-sorter' + options.sorterName + '[data-initialized]').first();
                if (type === 'pager') {
                    if (index > 1) {
                        params[options.pagerName] = index;
                    }
                    if ($sorter.length && $sorter.attr('data-current-sort') !== '') {
                        params[options.sorterName] = $sorter.attr('data-current-sort');
                    }
                } else if (type === 'sorter' && index !== '') {
                    params[options.sorterName] = index;
                    let $toggleButton = self.sortingElements.find('span[role=button]');
                    $toggleButton.find('span.sorting__text').html($('.dropdown-item[data-sorting-column=' + index + ']', self.sortingElements).text());
                }
                SURPLEX.Ajax.fetch({
                    url: SURPLEX.Utils.decodeBase(options.toolbarUrl),
                    data: params,
                    callbackEnd: $.extend({
                            'wrapResult': function (target, data) {
                                if (data.hasOwnProperty('html') && typeof data.html === 'object') {
                                    $.each(data.html, function (key, element) {
                                        let $elementToReplace = $(options.targetElem).find(key);
                                        if ($elementToReplace.length) {
                                            $elementToReplace.replaceWith(element);
                                        }
                                    });
                                }
                            },
                            'trackAndredirect': function (target, data) {
                                if (data.hasOwnProperty('js') && typeof data.js === 'object') {
                                    $.each(data.js, function (key, val) {
                                        window[key] = SURPLEX.Utils.isJSON(val);
                                        if (key === 'dataLayerArticleList' && typeof dataLayer !== "undefined") {
                                            dataLayer.push(window[key]);
                                        }
                                    });
                                }
                                self.pagingElements.attr('data-current-page', type === 'pager' ? index+'' : '1');
                                if (type === 'sorter') {
                                    self.sortingElements.attr('data-current-sort', type === 'sorter' ? index : $sorter.attr('data-current-sort'));
                                    $('.dropdown-item', self.sortingElements).removeClass(self.settings.activeClass);
                                    $('.dropdown-item[data-sorting-column=' + self.settings.currentSort + ']', self.sortingElements).addClass(self.settings.activeClass);
                                }
                                const additionalParams = SURPLEX.Filter.getCurrentFilter();
                                let newUrl = $.param(params) === '' && additionalParams === '' ? '' : '?' + $.param(params) + (additionalParams !== '' ? ($.param(params) !== '' ? '&' : '') + additionalParams : '');
                                SURPLEX.Track.trackVPV(
                                    type,
                                    window.location.pathname + newUrl
                                );

                                if (typeof (window.history.pushState) === 'function') {
                                    history.replaceState(null, null, window.location.pathname + newUrl);
                                } else {
                                    window.location.href = window.location.pathname + newUrl;
                                }
                            }
                        },
                        SURPLEX.Ajax.getDefaultEndCallbacks()),
                    loadingTarget: $(options.targetElem)
                })
            }
        }, options);

        self.off('refresh').on('refresh', function () {
            let pageIndex = self.find('.pagination--dropdown .active').length > 0 ? self.find('.pagination--dropdown .active').attr('data-srplx-data') : 1;
            self.fireEvent(pageIndex, 'pager');
        });

        self.init = function () {
            let currentSort =  $('.js-sorter' + options.sorterName).first()
                .find('.js-link.active')
                .attr('data-sorting-column');
            if (currentSort !== options.defaultSorting) {
                self.settings.currentSort = currentSort;
            }
            self.render();
            self.data('initialized', 'true');
        };

        self.render = function () {
            self.renderHtml();
            self.setStatus();
            self.bindEvents();
        };

        self.renderHtml = function () {
            let html = [];
            let htmlPages = [];
            let pages = self.getPages();
            for (let item in pages) {
                if (pages.hasOwnProperty(item)) {
                    htmlPages.push(self.buildItem('page', pages[item]));
                }
            }
            html.push(self.settings.wrapper.replace(/{{page}}/g, self.settings.currentPage)
                .replace(/{{totalPages}}/g, self.settings.totalPages)
                .replace(/{{pageStr}}/g, self.settings.pageString).replace(/{{pagesDropdown}}/g, htmlPages.join('')));
            if (self.settings.totalPages > 1) {
                html.unshift(self.buildItem('prev', self.settings.currentPage - 1));
                html.push(self.buildItem('next', self.settings.currentPage + 1));
            }
            self.finalElement = html.join('');
            self.sortingElements.each(function () {
                $(this).attr('data-initialized', 'true');
                $(this).attr('data-current-sort', self.settings.currentSort);
            });
            self.pagingElements.each(function () {
                $(this).html(self.finalElement);
                $(this).attr('data-initialized', 'true');
                $(this).attr('data-current-page', self.settings.currentPage);
            });

            if (self.find('.viewswitch__item--active .js-mapView--trigger').length > 0 && $(self.settings.targetElem + '.is-map-view').length === 0) {
                self.showMap();
            }

            if (self.settings.currentPage > 1 || self.settings.currentSort !== '') {
                self.removeSeo();
            }
        };

        self.getPages = function () {
            let pages = [],
                jumps = 1,
                shortRange = 3,
                currentPage = self.settings.currentPage,
                totalPages = self.settings.totalPages;

            if (totalPages > 100) {
                jumps = 20;
            } else if (totalPages > 50) {
                jumps = 10;
            } else if (totalPages > 20) {
                jumps = 5;
            }

            let i = jumps;
            for (i; i < totalPages; i += jumps) {
                pages[i] = i;
            }

            let j = currentPage - shortRange;
            for (j; j <= (currentPage + shortRange); ++j) {
                if (j > 0 && j < totalPages) {
                    pages[j] = j;
                }
            }
            pages[1] = 1;
            pages[totalPages] = totalPages;
            return pages;
        };


        self.buildItem = function (type, pageData) {
            let html = self.settings[type]
                .replace(/{{page}}/g, pageData)
                .replace(/{{totalPages}}/g, self.settings.totalPages)
                .replace(/{{pageStr}}/g, self.settings.pageString);

            return $(html).attr({
                'data-srplx-role': type,
                'data-srplx-data': pageData
            }).prop('outerHTML');
        };

        self.setStatus = function () {
            if (self.settings.currentPage < 2) {
                self.pagingElements.find('[data-srplx-role=prev]').addClass(self.settings.disableClass);
            }
            if (self.settings.currentPage >= self.settings.totalPages) {
                self.pagingElements.find('[data-srplx-role=next]').addClass(self.settings.disableClass);
            }
            self.pagingElements.find('[data-srplx-role=page]').removeClass(self.settings.activeClass);
            self.pagingElements.find('[data-srplx-role=page][data-srplx-data="' + self.settings.currentPage + '"]').addClass(self.settings.activeClass);
        };

        self.bindEvents = function () {
            let opts = self.settings;
            self.pagingElements.off().on('click', '[data-srplx-role]', function () {
                let $el = $(this);
                let position = 'top';
                if ($el.hasClass(opts.disableClass) || $el.hasClass(opts.activeClass)) {
                    return;
                }

                if ($el.closest('.toolbar').hasClass('toolbar__bottom')) {
                    position = 'bottom';
                    window.scroll({top: $(self.settings.targetElem).offset().top - 200, left: 0, behavior: 'smooth'});
                }
                let pageIndex = +$el.attr('data-srplx-data');
                if (self.fireEvent(pageIndex, 'pager')) {
                    self.switchPage(pageIndex);
                }
                self.trackEvent('selection_' + $el.attr('data-srplx-role') + '_' + position);
            });

            self.sortingElements.off().on('click', '.dropdown-item', function () {
                let $el = $(this);
                if ($el.hasClass(opts.activeClass)) {
                    return;
                }
                let sortIndex = $el.attr('data-sorting-column');
                if (self.fireEvent(sortIndex, 'sorter')) {
                    self.switchSort(sortIndex);
                    self.switchPage(1);
                }
                let sortingType = sortIndex[0] === '-' ? 'desc' : 'asc';
                let index = sortIndex.replace(new RegExp("^-"), "");
                self.trackEvent('sorting_' + index + '_' + sortingType);
            });


            self.find('.js-toggle').off().on('click', function () {
                let $viewSwitcher = $(this).closest('.viewswitch');
                $viewSwitcher.find('.viewswitch__item').removeClass('viewswitch__item--active');
                $(this).closest('.viewswitch__item').addClass('viewswitch__item--active');
                let $target = $($(this).data('target'));
                let viewMode = $(this).data('view-mode');
                let pageType = $('meta[name=srxGooglePageType]').attr('content');
                SURPLEX.Utils.setCookie('viewMode', viewMode);
                if (viewMode === 'map') {
                    self.showMap();
                } else if (viewMode === 'list') {
                    $target.addClass('listView');
                    $target.removeClass('gridView');
                    self.toggleListMapView(viewMode);
                } else {
                    $target.removeClass('listView');
                    $target.addClass('gridView');
                    self.toggleListMapView(viewMode);
                }
                SURPLEX.Track.trackGA("listViewToggle", pageType, (viewMode === 'grid' ? 'tile': viewMode), 0);
            });
        };


        self.showMap = function () {
            if($(self.settings.targetElem).find('.js-maps-integration').length > 0){
                self.toggleListMapView('map');
            } else{
                let filterVal = SURPLEX.Filter.getCurrentFilter();
                let filterParams = SURPLEX.Utils.rtrim(filterVal, '&');
                if(typeof window.MarkerClusterer === 'undefined'){
                    let mcScript = document.createElement('script');
                    mcScript.onload = function () {
                        loadMap()
                    };
                    mcScript.defer = true;
                    mcScript.src = "/assets/fixed/maps/markerclustererplus.min.js";
                    document.head.appendChild(mcScript);
                } else {
                    setTimeout(function () {
                        loadMap()
                    }, 500);
                }

                function loadMap(){
                    SURPLEX.Ajax.fetch(
                        {
                            url: SURPLEX.Utils.decodeBase($(self).find('.js-mapView--trigger').data('url')) + (filterParams.length > 0 ? '&' + filterParams : ''),
                            callbackEnd : $.extend(
                                {
                                    'googleMapsLoaded': function (target, data) {
                                        $(self.settings.targetElem).append(data);
                                        self.toggleListMapView('map');
                                    }
                                },
                                SURPLEX.Ajax.getDefaultEndCallbacks()
                            ),
                            loadingTarget : $(self.settings.targetElem)
                        }
                    );
                }
            }
        };

        self.toggleListMapView = function (viewMode) {
            if(viewMode === 'map'){
                $(self.settings.targetElem).addClass('is-map-view');
                self.find('.js-sorting').addClass('is-disabled');
                self.parent().find('.js-pagination').addClass('is-disabled');
            } else{
                $(self.settings.targetElem).removeClass('is-map-view');
                self.find('.js-sorting').removeClass('is-disabled');
                self.parent().find('.js-pagination').removeClass('is-disabled');
            }
        };

        self.switchPage = function (pageIndex) {
            self.settings.currentPage = pageIndex < 1 ? 1 : pageIndex;
            self.render();
        };
        self.switchSort = function (sortIndex) {
            self.settings.currentSort = sortIndex;
            self.render();
        };
        self.trackEvent = function (label) {
            SURPLEX.Track.trackGA(
                'toolbarTracking',
                $('meta[name=srxGooglePageType]').attr('content'),
                label
            );
        };
        self.fireEvent = function (index, type) {
            return (typeof self.settings.onPageChange !== 'function') || (self.settings.onPageChange(index, type) !== false);
        };
        self.removeSeo = function () {
            $(self.settings.seoElem).remove();
        }
        self.init();
        return self.pagingElements;
    };
})($, SURPLEX);
